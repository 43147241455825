import { makeStyles, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IRowBrandSupportTheme } from '../entities/row.brand.support.entity';

export const useStyleTheme = () => {
  const theme = useTheme() as ThemeOptions & IRowBrandSupportTheme;
  const useThemeStyle = makeStyles(theme.rowBrandSupport);
  return useThemeStyle();
};

export const useStyle = makeStyles({
  defaultBrandImage: {
    width: '200px',
  },
  defaultBrandItemContainer: {},
});
