import React from 'react';
import { Grid, Box } from '@material-ui/core';
import clsx from 'clsx';
import { useStyle, useStyleTheme } from '../styles/description.box.style';

interface IProps {
  title?: string;
  subTitle?: string;
  text?: string;
}

export const DescriptionBox: React.FC<IProps> = ({ title, subTitle, text }) => {
  const classNames = useStyle();
  const themeClassNames = useStyleTheme();

  return (
    <Grid
      className={clsx(classNames.defaultContainer, themeClassNames.container)}
    >
      <Box className={clsx(classNames.defaultTitle, themeClassNames.title)}>
        {title}
      </Box>
      <Box
        className={clsx(classNames.defaultSubTitle, themeClassNames.subTitle)}
      >
        {subTitle}
      </Box>
      <Box className={clsx(classNames.defaultText, themeClassNames.text)}>
        {text}
      </Box>
    </Grid>
  );
};
