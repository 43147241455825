import {
  Button,
  CircularProgress,
  Grid,
  InputAdornment,
  TextField,
} from '@material-ui/core';
import { CreditCard } from '@material-ui/icons';
import { Alert } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ILemonwayResponse } from 'sherl-react-sdk';
import { useStyles } from '../styles/credit-card.style';

interface IProps {
  value: string;
  card_result: string;
  requestCredentials: () => Promise<any>;
  lemonwayConfig?: ILemonwayResponse;
}

export const CreditCardComponent: React.FC<IProps> = ({
  value,
  card_result,
  requestCredentials,
  lemonwayConfig,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [loading, setLoading] = useState<boolean>(false);

  const handleRequestCredential = async () => {
    setLoading(true);
    await requestCredentials();
    setLoading(false);
  };

  useEffect(() => {
    if (lemonwayConfig && lemonwayConfig.CardRegistrationURL != null) {
      window.location.href = lemonwayConfig.CardRegistrationURL;
    }
  }, [lemonwayConfig]);

  return (
    <Grid container spacing={2}>
      {card_result && (
        <Alert
          severity={(card_result as string) === 'SUCCESS' ? 'success' : 'error'}
        >
          {t(`credit-card-fields.card-result.${card_result}`)}
        </Alert>
      )}
      <Grid item xs={12} sm={8}>
        <TextField
          name="card"
          classes={{ root: styles.input }}
          disabled
          fullWidth
          InputProps={{
            readOnly: true,
            startAdornment: (
              <InputAdornment position="start">
                <CreditCard />
              </InputAdornment>
            ),
          }}
          margin="none"
          value={value}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container alignItems="center">
          <Button
            onClick={handleRequestCredential}
            variant="contained"
            color="primary"
            disabled={loading}
          >
            {loading && <CircularProgress />}
            {!loading && t('billing-method.add-card')}
          </Button>
        </Grid>
      </Grid>
    </Grid>
  );
};
