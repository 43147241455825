import { Grid, Typography } from '@material-ui/core';
import { ImageOutlined } from '@material-ui/icons';
import clsx from 'clsx';
import React from 'react';
import Dropzone from 'react-dropzone';
import { FieldInputProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles/dropzone.style';

interface IProps {
  picture?: string;
  input: FieldInputProps<any, HTMLElement>;
  accepteType?: string | string[];
}

export const DropzoneForm: React.FC<IProps> = ({
  input,
  picture = '/assets/images/default-avatar.png',
  accepteType,
}) => {
  const { t } = useTranslation();
  const style = useStyles();
  let includeFileType = false;
  if (Array.isArray(accepteType)) {
    includeFileType = accepteType.some((item) => item.includes('application'));
  } else if (!!accepteType) {
    includeFileType = accepteType.includes('application');
  }
  return (
    <Dropzone
      onDrop={(acceptedFiles) => {
        Object.assign(acceptedFiles[0], {
          preview: URL.createObjectURL(acceptedFiles[0]),
        });
        input.onChange(acceptedFiles[0]);
      }}
      accept={accepteType ? accepteType : 'image/*'}
      multiple={false}
    >
      {({ getRootProps, getInputProps }) => (
        <Grid container className={style.draganddrop}>
          <Grid item className={style.fillParent} {...getRootProps()}>
            <div
              className={style.imgContainer}
              key={(input.value as File).name}
            >
              <img
                alt="preview"
                src={
                  input.value.preview !== undefined
                    ? (input.value as File & {
                        preview: string;
                      }).preview
                    : picture
                }
                className={style.img}
              />
            </div>
            <input {...getInputProps()} className={style.uploadButton} />
            <div className={`${style.fillParent} ${style.dropZone}`}>
              <Typography
                className={clsx({
                  [style.imgUploadTextBg]: input.value != null,
                  [style.imgUploadText]: true,
                })}
              >
                {includeFileType ? t('common.dropFile') : t('common.dropImage')}
                <ImageOutlined />
              </Typography>
            </div>
          </Grid>
        </Grid>
      )}
    </Dropzone>
  );
};
