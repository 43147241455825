import { Theme } from '@material-ui/core';
import { IMenuStyle } from 'common/components';

export const menu = (theme: Theme): IMenuStyle => ({
  container: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.aux.spacing.md,
  },
  containerSmallScreen: {
    backgroundColor: theme.palette.common.white,
    paddingTop: theme.aux.spacing.md,
  },
  itemsContainer: {
    marginLeft: '15%',
  },
  menuItemContainer: {},
  menuItemRightBlockContainer: {
    marginRight: theme.aux.spacing.l,
    marginLeft: theme.aux.spacing.xl,
  },
  menuItemText: {
    fontWeight: theme.typography.fontWeightBlack,
    fontSize: theme.typography.small.fontSize,
    letterSpacing: '3px',
  },
  menuItemRightBlockContainerMobile: {},
  modalSubCaterogiesContainer: {
    minWidth: 300,
    minHeight: 150,
    maxHeight: 300,
    maxWidth: 450,
    marginLeft: '5%',
    marginTop: '3%',
  },
  modalSubCaterogiesContainerSmallScreen: {},
  modalSubMenuItemText: {},
  smallScreenReturnBtnContainer: {
    width: '100%',
    fontWeight: theme.typography.fontWeightBold,
  },
});
