import { Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import { color } from './theme/color';

export const GlobalCss = withStyles((theme: Theme) => {
  return {
    '@global': {
      '.MuiRating-icon': {
        textShadow: theme.shadows[1],
      },

      '.react-images__frame, .react-images__track': {
        height: '100%',
      },
      '#nprogress': {
        pointerEvents: 'none',
        '& .bar': {
          background: color.cyan,
          position: 'fixed',
          zIndex: 1300,
          top: 0,
          left: 0,
          width: '100%',
          height: '3px',
        },
        '& .spinner': {
          display: 'none',
        },
        '& .spinner-icon': {
          display: 'none',
        },
      },
      '.nprogress-custom-parent': {
        overflow: 'hidden',
        position: 'relative',
      },
      '.nprogress-custom-parent #nprogress .spinner, .nprogress-custom-parent #nprogress .bar': {
        position: 'absolute',
      },
      '.DraftEditor-root': {
        border: '1px solid #171A2327',
        borderRadius: theme.spacing(0.5),
      },
      '.public-DraftStyleDefault-ltr': {
        paddingLeft: '14px',
      },
      '.rdw-editor-main': {
        maxHeight: '200px !important',
        backgroundColor: theme.palette.common.white,
      },
      '.public-DraftEditorPlaceholder-inner': {
        padding: '20px 14px',
      },
      '.Toastify__toast--error': {
        backgroundColor: theme.palette.error.main,
      },
      '.Toastify__toast--warning': {},
      '.Toastify__toast--success': {
        backgroundColor: theme.palette.quinary.dark,
      },
      '.Toastify__toast--info': {
        backgroundColor: color.info,
      },
      '.fa-xs': {
        fontSize: '0.75em !important ',
      },
      '.fa-sm': {
        fontSize: '0.875em !important ',
      },
      '.VictoryContainer': {
        '& svg': {
          maxWidth: '350px',
          marginLeft: 'auto',
          marginRight: 'auto',
        },
      },
    },
  };
})(() => null);
