import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyle = makeStyles((theme: Theme) => ({
  activeIcon: {
    color: theme.palette.primary.main,
    filter: `drop-shadow(2px 4px 6px ${theme.palette.primary.main});`,
  },
  activePublic: {
    color: theme.palette.grey['500'],
  },
  mobileFormat: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  mobileItemLabel: {
    paddingRight: theme.aux.spacing.xs,
  },
  globalItemStyle: {
    fontSize: theme.typography.small.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  itemContainer: {
    paddingTop: theme.aux.spacing.xs,
    paddingBottom: theme.aux.spacing.xs,
  },
  iconsPosition: {
    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },
}));
