import { FORM_ERROR, SubmissionErrors } from 'final-form';

export const getErrors = (
  errors: any,
  submitFailed: boolean,
  submitErrors: any,
  dirtySinceLastSubmit: boolean,
): { [key: string]: any } => {
  let errorsToShow: any = {};

  if (errors && errors[FORM_ERROR] && submitFailed) {
    errorsToShow = errors;
  } else if (
    submitErrors &&
    submitErrors[FORM_ERROR] &&
    submitFailed &&
    !dirtySinceLastSubmit
  ) {
    errorsToShow = submitErrors;
  }
  return errorsToShow;
};

export const validateLatLng = (coordinate: string): boolean => {
  // eslint-disable-next-line no-useless-escape
  const regex = /^\d+(([\.|\,]?)(\d+?)?)$/;

  return regex.test(coordinate);
};

export const validatePhoneNumber = (phoneNumber: string) => {
  const re = /^((\+)33|0)[1-9](\d{2}){4}$/;
  return re.test(String(phoneNumber).toLowerCase());
};

export const validatePostalCodeFR = (postalCode: string) => {
  const re = /^(([0-9][0-9])|(9[0-5]))[0-9]{3}$/;
  return re.test(postalCode);
};
