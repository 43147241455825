import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles({
  card: { padding: 15, minHeight: 150 },
  cardHeader: { height: '100%', flex: 1 },
  icon: { width: 32, height: 32 },
  title: {
    fontWeight: 'bold',
    fontSize: 24,
    letterSpacing: 2,
  },
  count: { fontWeight: 900, fontSize: 40 },
  countWrapper: { flex: 1, height: '100%' },
});
