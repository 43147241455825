import { Grid } from '@material-ui/core';
import { Logo } from '../../logo';
import clsx from 'clsx';
import React from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';

import { ForgottenPassword } from '../forgotten-password/components/forgotten.password.component';
import { LoginContainer as Login } from '../login/containers/login.container';
import { useStyles } from '../login/styles/login.style';
import { ResetPassword } from '../reset-password/components/reset.password.component';
import { IRouterProps } from './router.entity';
import { InitPassword } from '../init-password';

const AuthRouter: React.FC<IRouterProps> = ({ children, initialRoute }) => {
  const style = useStyles();

  const location: any = useLocation();
  const matchHome = location.pathname.includes('app');
  let background;
  if (!matchHome && location.state && location.state?.background) {
    background = location.state.background;
  }
  return (
    <React.Fragment>
      <Grid
        className={clsx({
          [style.container]: true,
        })}
      >
        {matchHome === false && (
          <Grid
            container
            justify="center"
            alignItems="center"
            className={style.logo}
          >
            <Grid item xs={10}>
              <img
                alt="logo"
                style={{ width: 'auto', height: 80 }}
                srcSet={Logo()}
              />
            </Grid>
          </Grid>
        )}
        <Grid
          container
          item
          xs={12}
          style={{ height: '100%' }}
          justify={!matchHome ? 'center' : undefined}
          alignItems={!matchHome ? 'center' : undefined}
        >
          <Switch location={background || location}>
            <Route exact path="/login">
              <Login initialRoute={initialRoute} />
            </Route>
            <Route exact path="/reset-password/:token">
              <ResetPassword />
            </Route>
            <Route exact path="/init-password/:token">
              <InitPassword />
            </Route>
            <Route path="/app">{children}</Route>
            {/* <Route path="/app/client-list">{children}</Route> */}
            <Redirect to="/login" />
          </Switch>
          {background && (
            <Route
              path={'/login/forgotten-password'}
              children={<ForgottenPassword />}
            />
          )}
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export { AuthRouter };
