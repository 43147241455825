import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export const drawerWidth = 240;
export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      flex: 1,
      height: '100%',
      backgroundColor: '#F6F6F8',
    },
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    drawerHeaderFake: {
      ...theme.mixins.toolbar,
      backgroundColor: 'transparent',
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    hide: {
      display: 'none',
    },
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    selected: {
      backgroundColor: theme.palette.secondary.main,
    },
    drawerPaper: {
      width: drawerWidth,
      height: 'calc(100% - 50px)',
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
      backgroundColor: '#F6F6F8',
      color: theme.palette.primary.main,
      justifyContent: 'flex-end',
    },
    content: {
      height: 'calc(100% - 50px)',
      marginBottom: 50,
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
    expansion: {
      boxShadow: 'initial',

      margin: `0 !important`,
      '&::before': {
        height: 0,
      },
    },
    expansionSummaryContent: {
      margin: `0 !important`,
    },
    expansionSummary: {
      padding: `0px ${theme.aux.spacing.s}px 0px 0px`,
      minHeight: `0 !important`,
    },
    AccordionDetails: {
      padding: `0 !important`,
    },
    menuItem: {
      padding: 0,
    },
    menuItemButton: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      width: '100%',
      justifyContent: 'flex-start',
    },
    item: {
      display: 'block',
      paddingTop: 0,
      paddingBottom: 0,
    },
    itemLeaf: {
      display: 'flex',
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
    },
    buttonLeaf: {
      color: theme.palette.text.secondary,
      padding: '10px 8px',
      justifyContent: 'flex-start',
      textTransform: 'none',
      letterSpacing: 0,
      width: '100%',
      fontWeight: theme.typography.fontWeightRegular,
      '&.depth-0': {
        '& $title': {
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    icon: {
      display: 'flex',
      alignItems: 'center',
      marginRight: theme.spacing(1),
    },
    title: {
      marginRight: 'auto',
    },
    active: {
      color: theme.palette.secondary.main,
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium,
      },
      '& $icon': {
        color: theme.palette.secondary.main,
      },
    },
  }),
);
