import { Grid } from '@material-ui/core';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { ReviewsCheck } from '../components/review.check.component';
import { ReviewRejection } from '../components/review.rejection.component';
import { Reviews } from '../components/reviews.component';

export const ReviewsRouter = () => {
  const location: any = useLocation();
  const { path } = useRouteMatch();
  const background = location.state && location.state.background;

  return (
    <Grid item xs={12}>
      <Switch location={background || location}>
        <Route exact path={`${path}/`}>
          <Reviews />
        </Route>
        <Redirect to={path} />
      </Switch>
      {background && (
        <Route path={`${path}/:id/check`} children={<ReviewsCheck />} />
      )}
      {background && (
        <Route path={`${path}/:id/reject`} children={<ReviewRejection />} />
      )}
    </Grid>
  );
};
