import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { BirthdateDatePicker } from 'common/components/birthdate-datepicker';
import { getErrors } from 'common/helpers';
import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Person } from 'sherl-react-sdk';
import * as Yup from 'yup';
import { IFormData } from '../container/clients.list.container';

export const NewClientForm: React.FC = () => {
  const { t } = useTranslation();
  const { goBack } = useHistory();
  const { addPerson } = Person.usePersons();

  const onSubmitNewClient = async (values: IFormData): Promise<any> => {
    const { lastName, firstName, email, birthDate, enabled = true } = values;
    try {
      await addPerson({
        lastName,
        firstName,
        email,
        birthDate: birthDate.toISOString(),
        enabled,
      });
      toast.success(t('common.notification.add-success'));
      setTimeout(() => {
        goBack();
      }, 1000);
      return {};
    } catch (err) {
      return { [FORM_ERROR]: t('common.form.update-failed') };
    }
  };

  const schema = Yup.object().shape({
    firstName: Yup.string().required().label(t('common.form.firstName')),
    lastName: Yup.string().required().label(t('common.form.lastName')),
    email: Yup.string().required().email().label(t('common.form.email')),
    birthDate: Yup.string().required().label(t('common.form.birthDate')),
  });
  const validateNewClientForm = makeValidate(schema);

  return (
    <Dialog open onClose={() => goBack()}>
      <Form
        onSubmit={onSubmitNewClient}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogTitle>{t('clients-list.add-client')}</DialogTitle>
            <DialogContent>
              <Form
                onSubmit={onSubmitNewClient}
                validate={validateNewClientForm as any}
                render={({
                  errors,
                  submitFailed,
                  submitErrors,
                  handleSubmit,
                  dirtySinceLastSubmit,
                  submitting,
                }) => {
                  const errorsToShow = getErrors(
                    errors,
                    submitFailed,
                    submitErrors,
                    dirtySinceLastSubmit,
                  );
                  return (
                    <form onSubmit={handleSubmit}>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <TextField
                            name="lastName"
                            required
                            size="small"
                            label={t('common.form.lastName')}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="firstName"
                            size="small"
                            required
                            variant="outlined"
                            label={t('common.form.firstName')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            name="email"
                            size="small"
                            required
                            type="email"
                            variant="outlined"
                            label={t('common.form.email')}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <BirthdateDatePicker name="birthDate" />
                        </Grid>
                        <Grid item xs={12}>
                          <Typography>
                            {t('clients-list.new-client-form.explanations')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Box display="flex" justifyContent="space-around">
                            <Button color="primary" onClick={() => goBack()}>
                              {t('common.return')}
                            </Button>
                            <Button
                              color="primary"
                              variant="contained"
                              type="submit"
                              disabled={submitting}
                            >
                              {t('common.send')}
                            </Button>
                          </Box>
                          <Grid item xs={12} style={{ marginTop: 10 }}>
                            {errorsToShow[FORM_ERROR] && (
                              <Alert severity="error">
                                {errorsToShow[FORM_ERROR]}
                              </Alert>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    </form>
                  );
                }}
              />
            </DialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};
