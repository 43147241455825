import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Organization, Person } from 'sherl-react-sdk';

interface FormValues {
  legalName: string;
  siret: string;
  streetAddress: string;
  complementaryStreetAddress: string;
  postalCode: string;
  locality: string;
  country: string;
}

export const AccountCompany = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { me } = Person.usePerson();

  const {
    updateOrganization,
    updateAddress,
    organization,
    getOrganization,
  } = Organization.useOrganization();

  const onSubmit = async (values: FormValues) => {
    try {
      await updateOrganization({
        id: me?.affiliation.id,
        legalName: values.legalName,
        siret: parseInt(values.siret),
      });
      await updateAddress(organization?.id, organization?.location.id, {
        locality: values.locality,
        streetAddress: values.streetAddress,
        complementaryStreetAddress: values.complementaryStreetAddress,
        postalCode: values.postalCode,
        name: 'default',
        type: 'PROFESSIONAL',
        country: values.country,
      });
      toast.success(t('common.form.succeeded'));
    } catch (err) {
      toast.error(t('common.form.failed'));
      return { [FORM_ERROR]: t('common.form.failed') };
    }
  };

  useEffect(() => {
    getOrganization(me.affiliation?.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      initialValues={{
        legalName: organization?.legalName,
        siret: (organization?.siret || '').toString(),
        streetAddress: organization?.location?.streetAddress,
        complementaryStreetAddress:
          organization?.location?.complementaryStreetAddress,
        postalCode: organization?.location?.postalCode,
        locality: organization?.location?.locality,
        country: organization?.location?.country,
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Card style={{ padding: theme.spacing(2) }}>
            <Grid container spacing={3}>
              <Grid container style={{ margin: theme.spacing(2) }}>
                <Grid item xs={12}>
                  <Typography variant="h4">{t('account.company')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {t('account.company-description')}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="legalName"
                  label={t('common.form.legalName')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="siret"
                  label={t('common.form.siret')}
                />
              </Grid>
              <Grid item xs={12}>
                <Typography variant="h5">
                  {t('common.form.company-address')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t('address-form-simple.form.streetAddress')}
                  name="streetAddress"
                  type="text"
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  label={t(
                    'address-form-simple.form.complementaryStreetAddress',
                  )}
                  name="complementaryStreetAddress"
                  type="text"
                  size="small"
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  label={t('address-form-simple.form.postalCode')}
                  name="postalCode"
                  type="text"
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('address-form-simple.form.locality')}
                  name="locality"
                  type="text"
                  size="small"
                  required
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  label={t('address-form-simple.form.country')}
                  name="country"
                  type="text"
                  size="small"
                  required
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              style={{ marginTop: theme.spacing(2) }}
            >
              <Button type="submit" variant="contained" color="primary">
                {t('common.save')}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    />
  );
};
