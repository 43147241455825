import { library } from '@fortawesome/fontawesome-svg-core';
import { faPassport } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Card, CardContent, List } from '@material-ui/core';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { CardHeader } from 'common/components/card-header';
import { PictureModal } from 'common/components/picture-modal';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  IDocumentResponse,
  KycDocumentType,
  Organization,
  Person,
} from 'sherl-react-sdk';
import { DocumentListItem } from './document-list-item.component';

library.add(faPassport);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      backgroundColor: theme.palette.background.paper,
    },
  }),
);

export const OrganizationDocumentUpload: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [open, setOpen] = useState<KycDocumentType>();
  const { me } = Person.usePerson();
  const { addKyc, getKyc, kycArray } = Organization.useOrganization();

  const onSubmit = async (values: { image: File }) => {
    let response: any;
    try {
      if (!open) {
        throw new Error();
      }
      response = await addKyc(values.image, open, me.affiliation?.id);
      if (!Object.values(KycDocumentType).includes(response)) {
        throw new Error();
      }
      toast.success(t('common.notification.add-success'));
      setOpen(undefined);
    } catch (err) {
      toast.error(t('common.notification.add-failed', { err }));
      setOpen(undefined);
    }
  };

  useEffect(() => {
    if (me) {
      getKyc(me.affiliation?.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  const kycs: IDocumentResponse[] = [...kycArray];

  return (
    <Card>
      <CardHeader
        title={t('organization-document-upload.title')}
        icon={<FontAwesomeIcon icon={faPassport} />}
      />
      <CardContent>
        <List dense className={styles.root}>
          {Object.keys(KycDocumentType).map((documentType) => {
            const doc = (kycs as IDocumentResponse[]).find(
              (item) => item.type === documentType,
            );
            return (
              <React.Fragment key={documentType}>
                <DocumentListItem
                  document={doc ?? doc}
                  documentType={
                    doc
                      ? (doc.type as KycDocumentType)
                      : (documentType as KycDocumentType)
                  }
                  setUpload={setOpen}
                />
              </React.Fragment>
            );
          })}
        </List>
        <PictureModal
          onSubmit={onSubmit}
          isOpen={!!open}
          title={t('organization-document-upload.upload-modal-title')}
          onClose={() => setOpen(undefined)}
        />
      </CardContent>
    </Card>
  );
};
