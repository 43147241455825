import { makeStyles, Theme } from '@material-ui/core';
import { StatusType } from '../entities/document.entity';

const colorGenerator = (
  status: StatusType | undefined,
  background: boolean,
  theme: Theme,
) => {
  switch (status) {
    case StatusType.CREATED:
      return background
        ? theme.palette.info.main
        : theme.palette.info.contrastText;

    case StatusType.VALIDATION_ASKED:
      return background
        ? theme.palette.warning.main
        : theme.palette.warning.contrastText;

    case StatusType.VALIDATED:
      return background
        ? theme.palette.success.main
        : theme.palette.success.contrastText;

    case StatusType.REFUSED:
      return background
        ? theme.palette.error.main
        : theme.palette.error.contrastText;

    default:
      return background
        ? theme.palette.primary.main
        : theme.palette.common.white;
  }
};

export const useStyles = makeStyles((theme) => ({
  helpContainer: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing(1),
  },
  status: ({ status }: { status: StatusType | undefined }) => ({
    backgroundColor: colorGenerator(status, true, theme),
    color: colorGenerator(status, false, theme),
    textTransform: 'uppercase',
    padding: theme.spacing(0.5),
    borderRadius: theme.shape.borderRadius,
    fontSize: theme.typography.caption.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    display: 'flex',
    alignItems: 'center',
  }),
}));
