import React from 'react';
import ReactDOM from 'react-dom';
import { toast } from 'react-toastify';
import { EventsStatusListener } from 'sherl-react-sdk';
import App from './App';
import './index.css';
import * as serviceWorker from './serviceWorker';

EventsStatusListener.getInstance().addOnSuccessListener((msg) => {
  toast.success(msg.title);
});

EventsStatusListener.getInstance().addOnErrorListener((msg) => {
  toast.error(msg.title);
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
