import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getErrors } from 'common/helpers';
import { FORM_ERROR, FormApi, SubmissionErrors } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';

export interface IFormData {
  firstName: string;
  lastName: string;
  email: string;
  enabled?: boolean;
}

interface IProps {
  isOpen: boolean;
  isMobile: boolean;
  handleAddAdministratorDialog: () => void;
  onSubmit: (
    values: IFormData,
    form: FormApi<any>,
    callback?: ((errors?: SubmissionErrors | undefined) => void) | undefined,
  ) => any;
  /**
   * Validate callback
   */
  validate: (values: IFormData) => any;
}

export const NewAdministratorForm: React.FC<IProps> = (props) => {
  const { onSubmit, isOpen, handleAddAdministratorDialog, validate } = props;
  const { t } = useTranslation();

  return (
    <Dialog open={isOpen} onClose={handleAddAdministratorDialog}>
      <DialogTitle>{t('administrators-list.add-admin')}</DialogTitle>
      <DialogContent>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            errors,
            submitFailed,
            submitErrors,
            handleSubmit,
            dirtySinceLastSubmit,
            valid,
            submitting,
            submitSucceeded,
          }) => {
            const errorsToShow = getErrors(
              errors,
              submitFailed,
              submitErrors,
              dirtySinceLastSubmit,
            );
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="lastName"
                      required
                      size="small"
                      label={t('common.form.lastName')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="firstName"
                      size="small"
                      required
                      variant="outlined"
                      label={t('common.form.firstName')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="email"
                      size="small"
                      required
                      type="email"
                      variant="outlined"
                      label={t('common.form.email')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {t('administrators-list.admin-form-explanations')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-around">
                      <Button
                        color="primary"
                        onClick={handleAddAdministratorDialog}
                      >
                        {t('common.return')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={
                          !valid ||
                          submitting ||
                          (submitSucceeded && !dirtySinceLastSubmit)
                        }
                      >
                        {t('common.send')}
                      </Button>
                    </Box>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      {errorsToShow[FORM_ERROR] && (
                        <Alert severity="error">
                          {errorsToShow[FORM_ERROR]}
                        </Alert>
                      )}
                    </Grid>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      {submitSucceeded && !dirtySinceLastSubmit && (
                        <Alert severity="success">
                          {t('common.form.success.add')}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
