import { createMuiTheme, ThemeOptions } from '@material-ui/core';
import './types/createMuiTheme.d';
import './types/createPalette.d';
import './types/createTypography.d';
import './types/overrides.d';
import '@material-ui/core/styles/createPalette';

const theme = createMuiTheme({});

// tslint:disable-next-line: no-empty-interface
export type IDefaultExtendedTheme = ThemeOptions;

export const defaultTheme: IDefaultExtendedTheme = createMuiTheme({
  aux: {
    spacing: {
      xs: theme.spacing(1),
      s: theme.spacing(2),
      sm: theme.spacing(4),
      m: theme.spacing(5),
      md: theme.spacing(8),
      l: theme.spacing(16),
      large: theme.spacing(25),
      xl: theme.spacing(32),
    },
  },
  palette: {
    tertiary: theme.palette.primary,
    quaternary: theme.palette.primary,
    quinary: theme.palette.primary,
  },
});
