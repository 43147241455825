import { Box, Chip, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ICategoryNested, Shop } from 'sherl-react-sdk';

interface IProps {
  categoryUris?: string[];
  handleChange: (categories: { [p: string]: ICategoryNested }) => void;
  categoryMaxDepth: number;
}

export const SelectCategories: React.FC<IProps> = ({
  categoryUris,
  handleChange,
  categoryMaxDepth,
}) => {
  const { t } = useTranslation();
  const {
    categories,
    parentCategories,
    getPublicCategories,
  } = Shop.useCategory();
  const [selectedCategories, setSelectedCategories] = useState<{
    [p: string]: ICategoryNested;
  }>({});

  const findCategoryByUri = (uri: string): ICategoryNested => {
    return categories[uri.replace('/api/shop/products/categories/', '')];
  };

  const getOptionLabel = (option: ICategoryNested, includeLastLevel = true) => {
    let currentCategory = option;
    const labelArray: string[] = [];

    while (currentCategory) {
      labelArray.push(currentCategory.name);

      if (!currentCategory.parentUri) {
        break;
      }

      currentCategory = findCategoryByUri(currentCategory.parentUri);
    }

    if (!includeLastLevel) {
      labelArray.shift();
    }

    return labelArray.reverse().join(' > ');
  };

  useEffect(() => {
    getPublicCategories({ depth: categoryMaxDepth });
  }, []);

  useEffect(() => {
    if (categoryUris) {
      const cats: {
        [p: string]: ICategoryNested;
      } = {};
      for (const uri of categoryUris) {
        const cat = findCategoryByUri(uri);
        if (cat) {
          cats[`${cat.id}`] = cat;
        }
      }
      setSelectedCategories(cats);
      handleChange(cats);
    }
  }, [categories, categoryUris]);

  const changeSelectedCategories = (
    newSelectedCategories: ICategoryNested[],
  ) => {
    const newCategoriesObject = newSelectedCategories.reduce(
      (cats, category) => {
        cats[category.id] = category;
        return cats;
      },
      {},
    ) as { [key: string]: ICategoryNested };

    setSelectedCategories(newCategoriesObject);
    handleChange(newCategoriesObject);
  };

  const options = useMemo(() => {
    const flattenCategories = (categories: ICategoryNested[]) =>
      categories.reduce((res, category) => {
        res.push(category);

        if (
          category.subCategories &&
          Object.values(category.subCategories).length > 0
        ) {
          res = [
            ...res,
            ...flattenCategories(Object.values(category.subCategories)),
          ];
        }

        return res;
      }, [] as ICategoryNested[]);

    return flattenCategories(parentCategories);
  }, [parentCategories]);

  const selectedCategoriesArray = Object.values(selectedCategories || {});

  return (
    <>
      <Autocomplete
        multiple
        filterSelectedOptions
        options={options}
        getOptionLabel={getOptionLabel}
        value={selectedCategoriesArray}
        style={{ marginTop: 15 }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={t('product-details.product-form.categories')}
            variant="outlined"
          />
        )}
        renderOption={(option, _state) => (
          <Box>
            <Typography variant="body1">{option.name}</Typography>
            <Typography variant="caption" style={{ fontSize: 12 }}>
              {getOptionLabel(option, false)}
            </Typography>
          </Box>
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              key={index}
              label={option.name}
              clickable
              color="primary"
              variant="outlined"
              {...getTagProps({ index })}
            />
          ))
        }
        onChange={(event, value) => {
          changeSelectedCategories(value);
        }}
      />
    </>
  );
};
