import { Box, Typography } from '@material-ui/core';
import { Logo } from '../../common/components/logo';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const NotAuthorize: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Box
      display="flex"
      width="100vw"
      height="100vh"
      justifyContent="center"
      alignItems="center"
    >
      <Box>
        <Box width="200px" justifyContent="center">
          <img
            alt="logo"
            style={{ width: '100px', height: 'auto' }}
            srcSet={Logo()}
          />
        </Box>
        <Box display="flex" justifyContent="center">
          <Typography color="error" align="center">
            {t('authentication.not-authorize')}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
