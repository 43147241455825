import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  Grid,
  IconButton,
  Radio,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { ConfirmDialog, useConfirm } from 'common/components/confirm-dialog';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IMangopayCard, MangoPay } from 'sherl-react-sdk';
import { useStyles } from '../styles/mango-pay-cards-selector.style';

interface IProps {
  cards?: IMangopayCard[];
  initialValue?: string;
  value?: string;
  onChange?: (value: string) => void | Promise<void>;
  onSubmit?: (value: string) => void | Promise<void>;
  onDelete?: (value: string) => void | Promise<void>;
  loading?: boolean;
  handleAddCard?: () => void;
}

export const MangoPayCardsSelector: React.FC<IProps> = ({
  cards,
  value,
  onChange,
  onSubmit,
  onDelete,
  initialValue,
  loading = false,
  handleAddCard,
}) => {
  const styles = useStyles({ loading });
  const { itemToDelete, handleClickOpen, handleClose } = useConfirm();

  const { t } = useTranslation();
  const [localValue, setLocalValue] = useState<string>(initialValue || '');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      return onChange(event.target.value);
    }

    setLocalValue(event.target.value);
  };

  const handleSubmit = () => {
    if (onSubmit) {
      onSubmit(value || localValue);
    }
  };

  const handleDeleteCard = async () => {
    if (itemToDelete && onDelete) {
      try {
        await onDelete(itemToDelete);
        handleClose();
      } catch (err) {
        console.error(err);
      }
    }
  };

  const realValue = value || localValue;

  return (
    <div className={styles.container}>
      <Box py={1}>
        <Grid container>
          {cards &&
            cards.length > 0 &&
            cards.map((card) => (
              <Grid xs={12} item key={card.Id}>
                <Grid
                  container
                  spacing={2}
                  justify="space-between"
                  alignItems="center"
                >
                  <Grid item>
                    <FormControlLabel
                      name="mangopay-card"
                      value={card.Id}
                      label={card.Alias}
                      checked={realValue === card.Id}
                      control={<Radio onChange={handleChange} />}
                    />
                  </Grid>
                  {onDelete && (
                    <Grid item>
                      <IconButton
                        aria-label="delete"
                        onClick={() => handleClickOpen(card.Id)}
                      >
                        <DeleteIcon />
                      </IconButton>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            ))}
        </Grid>
      </Box>
      {onSubmit && (
        <Box>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            disabled={loading}
            onClick={handleSubmit}
          >
            {t('mango-pay-cards-selector.choose-card')}
          </Button>
        </Box>
      )}
      {handleAddCard && (
        <Box py={1}>
          <Button
            color="primary"
            variant="outlined"
            size="small"
            onClick={handleAddCard}
          >
            {t('mango-pay-cards-selector.add-card')}
          </Button>
        </Box>
      )}
      <div className={styles.overlay}>
        <Box className={styles.overlayBox}>
          <CircularProgress />
        </Box>
      </div>
      {onDelete && (
        <ConfirmDialog
          title={t('confirm-dialog.title')}
          message={t('confirm-dialog.description')}
          isOpen={!!itemToDelete}
          onConfirm={handleDeleteCard}
          onClose={handleClose}
        />
      )}
    </div>
  );
};
