import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  background: {
    minHeight: '100vh',
    backgroundColor: 'rgb(238, 239, 243)',
  },
  card: {
    [theme.breakpoints.up('sm')]: {
      width: '600px',
    },
  },
  bold: {
    fontWeight: theme.typography.fontWeightBold,
  },
  textButton: {
    color: theme.palette.common.white,
  },
}));
