import { Theme } from '@material-ui/core';
import { IOpeningTimesStyle } from 'common/components';

export const openingTimes = (theme: Theme): IOpeningTimesStyle => ({
  cardContainer: {
    backgroundColor: 'transparent',
    padding: 0,
    width: '100%',
    boxShadow: 'initial',
  },
  title: {
    display: 'none',
  },
  dayTitle: {},
  day: {
    borderBottomColor: theme.palette.grey[500],
  },
  fullWidth: {},
  pickerBtn: {},
});
