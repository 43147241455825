import { Fade, Grid } from '@material-ui/core';
import { Logo } from '../../../logo';
import React from 'react';
import { splashScreenStyle } from '../styles/splascreen.style';

export const SplashscreenComponent: React.FC = () => {
  const splashScreen = splashScreenStyle();
  return (
    <Grid
      container={true}
      alignItems="center"
      justify="center"
      style={{ position: 'absolute', top: 0, bottom: 0, left: 0, right: 0 }}
      className={splashScreen.image}
    >
      <Fade in>
        <img style={{ width: 500, height: 500 }} srcSet={Logo()} />
      </Fade>
    </Grid>
  );
};
