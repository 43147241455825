import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
} from '@material-ui/core';
import { Cancel } from '@material-ui/icons';
import clsx from 'clsx';
import { CardHeader, DropzoneForm } from 'common/components';
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Config, IAdvertising, Media } from 'sherl-react-sdk';
import { v4 as uuidv4 } from 'uuid';
import { useStyles } from '../styles/advertising.style';

interface IFormData {
  title?: string;
  text?: string;
  buttonText?: string;
  link?: string;
  backgroundImage?: File;
  imageName?: string;
}
const ADVERTISING_CODE = 'WEBSITE_PUB_HEADER';
export const Advertising: React.FC = () => {
  const { t } = useTranslation();
  const styles = useStyles();
  const [isOpen, setOpen] = useState<boolean>(false);
  const { setPublicConfig, getPublicConfig, publicConfig } = Config.useConfig();
  const [initialValues, setInitialValues] = useState<{
    title: string;
    buttonText: string;
    link: string;
    backgroundImage: any;
  }>();

  const onSubmit = async (values: IFormData) => {
    let responseMedia: any;
    try {
      const isNewFile = !Boolean((values.backgroundImage as any).caption);
      if (isNewFile) {
        const mediaId = uuidv4();
        responseMedia = await Media.MediaApi.addMedia(
          mediaId,
          'cms',
          values.backgroundImage as File,
        );
        if (!responseMedia.data || !responseMedia.data.id) {
          throw new Error("Echec de l'envoi de");
        }
      }

      const data: Partial<IAdvertising> = {
        code: ADVERTISING_CODE,
        isPublic: true,
        value: {
          title: values.title ?? '',
          btnText: values.buttonText ?? '',
          btnLink: values.link ?? '',
          backgroundImage: isNewFile
            ? responseMedia.data
            : values.backgroundImage,
        },
      };

      await setPublicConfig(data);

      toast.success(t('common.notification.update-success'));
    } catch (error) {
      toast.error(t('common.notification.update-failed', { message: error }));
    }
    return;
  };

  useEffect(() => {
    const getConfig = async () => {
      await getPublicConfig(ADVERTISING_CODE);
    };
    getConfig();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (publicConfig && publicConfig[ADVERTISING_CODE] != null) {
      setInitialValues({
        title: publicConfig[ADVERTISING_CODE].title,
        buttonText: publicConfig[ADVERTISING_CODE].btnText,
        link: publicConfig[ADVERTISING_CODE].btnLink,
        backgroundImage: publicConfig[ADVERTISING_CODE].backgroundImage,
      });
    }
  }, [publicConfig]);

  return (
    <Card className={styles.card}>
      <CardHeader
        title={t('advertising.title')}
        icon={<FontAwesomeIcon icon={['fas', 'bullhorn']} />}
        className={styles.title}
      />
      <CardContent>
        <Form
          onSubmit={onSubmit}
          initialValues={initialValues}
          render={({ handleSubmit, values, form, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      required
                      name="title"
                      fullWidth
                      variant="outlined"
                      placeholder={t('advertising.placeholder-title')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="buttonText"
                      required
                      fullWidth
                      variant="outlined"
                      placeholder={t('advertising.placeholder-textButton')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="link"
                      required
                      fullWidth
                      variant="outlined"
                      placeholder={t('advertising.placeholder-button-link')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="imageName"
                      onClick={() => setOpen(true)}
                      variant="outlined"
                      required
                      placeholder={
                        values?.backgroundImage
                          ? (values.backgroundImage as any)?.caption?.name ||
                            values.backgroundImage?.name
                          : t('advertising.placeholder-back-image')
                      }
                      InputProps={{ readOnly: true }}
                    />
                    <Grid container alignItems="center">
                      {values.backgroundImage && (
                        <>
                          <Grid item>
                            <img
                              src={
                                (values.backgroundImage as any)?.caption
                                  ?.contentUrl ||
                                (values.backgroundImage as File & {
                                  preview: string;
                                }).preview
                              }
                              alt={
                                (values.backgroundImage as any)?.caption
                                  ?.name || values?.backgroundImage?.name
                              }
                              className={styles.media}
                            />
                          </Grid>
                          <Grid item>
                            <IconButton
                              onClick={() =>
                                form.change('backgroundImage', undefined)
                              }
                            >
                              <Cancel color="primary" />
                            </IconButton>
                          </Grid>
                        </>
                      )}
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid
                      container
                      spacing={2}
                      justify="flex-end"
                      alignItems="center"
                    >
                      <Grid item>
                        <Button
                          variant="contained"
                          className={styles.eraseButton}
                          onClick={() => {
                            form.reset();
                          }}
                        >
                          <span
                            className={clsx(
                              styles.button,
                              styles.eraseButtonText,
                            )}
                          >
                            {t('common.erase')}
                          </span>
                        </Button>
                      </Grid>
                      <Grid item>
                        <Button
                          variant="contained"
                          color="primary"
                          type="submit"
                          disabled={submitting}
                        >
                          {!submitting && (
                            <span className={styles.button}>
                              {t('common.save')}
                            </span>
                          )}
                          {submitting && <CircularProgress color="primary" />}
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Dialog open={isOpen} onClose={() => setOpen(false)}>
                  <DialogTitle>
                    {t('communication.mailing.title-image')}
                  </DialogTitle>
                  <DialogContent>
                    <Field
                      name="backgroundImage"
                      label={t('screen.establishment.image')}
                      render={({ input }) => {
                        return <DropzoneForm input={input} />;
                      }}
                    />
                  </DialogContent>
                  <DialogActions>
                    <Button
                      variant="contained"
                      color="default"
                      onClick={() => setOpen(false)}
                    >
                      {t('common.continue')}
                    </Button>
                  </DialogActions>
                </Dialog>
              </form>
            );
          }}
        />
      </CardContent>
    </Card>
  );
};
