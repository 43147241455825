import { Grid } from '@material-ui/core';
import { PasswordChangeForm, ProfileForm } from 'common/components';
import React from 'react';
import { Person } from 'sherl-react-sdk';

export const Profile: React.FC = () => {
  const { me, updateUser, meLoading: loading } = Person.usePerson();
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6}>
        <ProfileForm user={me} update={updateUser} loading={loading} />
      </Grid>
      <Grid item xs={12} sm={6}>
        <PasswordChangeForm />
      </Grid>
    </Grid>
  );
};
