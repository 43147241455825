/* eslint-disable @typescript-eslint/no-empty-function */
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';

export class MyUploadAdapter {
  public loader: any;

  constructor(loader: any) {
    // The file loader instance to use during the upload.
    this.loader = loader;
  }

  // Starts the upload process.
  upload() {
    return this.loader.file.then((file: any) => this._sendRequest(file));
  }

  // Aborts the upload process
  abort = () => {
    console.log('abort called');
  };

  // Prepares the data and sends the request.
  async _sendRequest(file: any) {
    // Prepare the form data.
    const data = new FormData();

    data.append('upload', file);

    const response = await Axios.post(
      `https://api.connectilib.site/api/medias`,
      data,
      {
        params: {
          id: uuidv4(),
          domain: 'cms',
        },
        headers: { 'Content-Type': 'multipart/form-data' },
        onUploadProgress: (progressEvent) => {
          this.loader.uploadTotal = progressEvent.total;
          this.loader.uploaded = progressEvent.loaded;
        },
      },
    );

    return {
      default: response.data?.caption?.contentUrl,
    };
  }
}

// ...

export function MyCustomUploadAdapterPlugin(editor: any) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader: any) => {
    // Configure the URL to the upload script in your back-end here!
    return new MyUploadAdapter(loader);
  };
}
