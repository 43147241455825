import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CustomRating } from 'common/components';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Opinion } from 'sherl-react-sdk';
import { useStyles } from '../styles/appointment.review.style';

export const ReviewsCheck: React.FC<any> = () => {
  const { t } = useTranslation();
  const classNames = useStyles();
  const { goBack, push } = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const location = useLocation();
  const { loading, updateOpinion, opinion, error } = Opinion.useAdminOpinion(
    params.id,
  );
  const [processed, setProcessed] = useState(false);

  if (!opinion) {
    goBack();
  }

  const onValidate = async () => {
    try {
      await updateOpinion({ status: 'published' });
      setProcessed(true);
      setTimeout(() => {
        push('/app/reviews');
      }, 1500);
    } catch (err) {
      setProcessed(true);
    }
  };

  const goRejection = () => {
    goBack();
    setTimeout(() => {
      push(
        `${(location.state as any).background.pathname}/${params.id}/reject`,
        {
          background: (location.state as any).background,
        },
      );
    }, 250);
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={goBack}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="center">
          <Grid item>
            <Typography paragraph className={classNames.title}>
              {t('review.check.title')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container justify="flex-start" direction="column">
          <Grid item>
            <Typography className={classNames.categories}>
              {t('review.author')}
            </Typography>
            <Typography className={classNames.deliver}>
              {opinion.opinionFrom.firstName} {opinion.opinionFrom.lastName}{' '}
              {opinion.opinionFrom.legalName}
            </Typography>
            <Typography className={classNames.categories}>
              {t('review.receiver')}
            </Typography>
            <Typography className={classNames.deliver}>
              {opinion.opinionTo.firstName} {opinion.opinionTo.lastName}{' '}
              {opinion.opinionTo.legalName}
            </Typography>
          </Grid>

          <Grid item>
            <Typography className={classNames.categories}>
              {t('review.rate')}
            </Typography>
            <CustomRating max={5} value={opinion.score} readOnly name="rate" />
          </Grid>
          <Grid item className={classNames.commentContainer}>
            <Typography paragraph className={classNames.categories}>
              {t('review.comment')}
            </Typography>
            <TextField
              name="comment"
              multiline
              disabled
              fullWidth
              variant="outlined"
              rowsMax={8}
              rows={8}
              value={opinion.comment}
              placeholder={t('review.placeholderComment')}
              inputProps={{
                maxLength: '500',
              }}
            />
          </Grid>
          {!loading && processed && !error && (
            <Alert severity="success">{t('common.form.succeeded')}</Alert>
          )}
          {!loading && processed && error && (
            <Alert severity="error">{t('common.form.failed')}</Alert>
          )}
        </Grid>
        <Grid container justify="center" alignItems="center" direction="row">
          <Grid>
            <Button
              color="primary"
              onClick={goRejection}
              disabled={loading || processed}
            >
              {t('common.refused')}
            </Button>
          </Grid>
          <Grid>
            <Button
              color="primary"
              variant="contained"
              disabled={loading || processed}
              onClick={onValidate}
            >
              {loading ? <CircularProgress /> : t('common.confirm')}
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};
