import { Grid } from '@material-ui/core';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { NewClientForm } from 'screens/clients-list/components/new.client.form.component';
import { Billing } from '../../billing/components/billing.component';
import { OrdersContainer } from '../container/orders.list.container';

export const OrdersRouter = () => {
  const location: any = useLocation();
  const { path } = useRouteMatch();
  const background = location.state && location.state.background;
  console.warn({ path });
  return (
    <Grid item xs={12}>
      <Switch location={background || location}>
        <Route exact path={path}>
          <OrdersContainer />
        </Route>
        <Route path={`${path}/create`}>
          <Billing />
        </Route>
        <Redirect to={path} />
      </Switch>
      {background && (
        <Route
          path={`${path}/create/add-client`}
          children={<NewClientForm />}
        />
      )}
    </Grid>
  );
};
