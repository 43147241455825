import React from 'react';

export const Visa: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="33.233" height="10.075">
      <path
        style={{ fill: '#3c58bf' }}
        d="M12.185 9.812L13.873.317h2.637l-1.635 9.495z"
      />
      <path
        style={{ fill: '#293688' }}
        d="M12.185 9.812L14.348.317h2.163l-1.635 9.495z"
      />
      <path
        style={{ fill: '#3c58bf' }}
        d="M24.424.422A6.691 6.691 0 0021.997 0c-2.638 0-4.536 1.319-4.536 3.218 0 1.424 1.319 2.163 2.374 2.638s1.371.791 1.371 1.213c0 .633-.844.95-1.582.95a5.734 5.734 0 01-2.532-.527l-.369-.158-.37 2.166a8.553 8.553 0 003.007.528c2.8 0 4.642-1.319 4.642-3.323 0-1.108-.686-1.952-2.268-2.637-.95-.475-1.53-.739-1.53-1.213 0-.422.475-.844 1.53-.844a4.838 4.838 0 012 .369l.264.105.422-2.057z"
      />
      <path
        style={{ fill: '#293688' }}
        d="M24.424.422A6.691 6.691 0 0021.997 0c-2.638 0-4.062 1.319-4.062 3.218a2.757 2.757 0 001.9 2.638c1.055.475 1.371.791 1.371 1.213 0 .633-.844.95-1.582.95a5.734 5.734 0 01-2.532-.527l-.369-.158-.37 2.166a8.553 8.553 0 003.007.528c2.8 0 4.642-1.319 4.642-3.323 0-1.108-.686-1.952-2.268-2.637-.95-.475-1.53-.739-1.53-1.213 0-.422.475-.844 1.53-.844a4.838 4.838 0 012 .369l.264.105.422-2.057z"
      />
      <path
        style={{ fill: '#3c58bf' }}
        d="M28.96.317c-.633 0-1.108.053-1.372.686l-3.956 8.809h2.849l.527-1.582h3.376l.316 1.582h2.532L31.017.317zm-1.213 6.33c.158-.475 1.055-2.8 1.055-2.8s.211-.58.369-.949l.158.9s.527 2.374.633 2.9h-2.216z"
      />
      <path
        style={{ fill: '#293688' }}
        d="M29.593.317c-.633 0-1.108.053-1.372.686l-4.589 8.809h2.848l.528-1.582h3.376l.317 1.582h2.531L31.016.317zm-1.846 6.33c.211-.528 1.055-2.8 1.055-2.8s.211-.58.369-.949l.158.9s.527 2.374.633 2.9h-2.216z"
      />
      <path
        style={{ fill: '#3c58bf' }}
        d="M7.332 6.963l-.264-1.371a7.647 7.647 0 00-3.693-4.167l2.374 8.44h2.849l4.273-9.5h-2.848z"
      />
      <path d="M7.332 6.963l-.264-1.371a7.647 7.647 0 00-3.693-4.167l2.374 8.44h2.849l4.273-9.5H10.55z" />
      <path
        d="M0 .317l.475.105c3.376.791 5.7 2.8 6.594 5.169l-.95-4.484C5.961.474 5.486.316 4.906.316z"
        fill="#ffbc00"
      />
      <path
        d="M0 .317c3.376.791 6.172 2.848 7.069 5.222l-.9-3.745a1.288 1.288 0 00-1.266-1z"
        fill="#f7981d"
      />
      <path
        d="M0 .317c3.376.791 6.172 2.848 7.069 5.222l-.633-2.057a1.86 1.86 0 00-1.108-1.53z"
        fill="#ed7c00"
      />
      <path
        style={{ fill: '#051244' }}
        d="M9.97 6.647L8.177 4.853l-.844 2-.211-1.319a7.647 7.647 0 00-3.693-4.162l2.374 8.44h2.848zM14.875 9.812l-2.268-2.321-.422 2.321zM20.942 6.542c.211.211.316.369.264.58 0 .633-.844.95-1.582.95a5.734 5.734 0 01-2.532-.527l-.369-.158-.37 2.162a8.553 8.553 0 003.007.527 5.223 5.223 0 003.851-1.319zM24.002 9.812h2.479l.527-1.583h3.376l.316 1.583h2.532l-.9-3.851-3.165-3.059.158.844s.527 2.374.633 2.9h-2.216c.211-.527 1.055-2.8 1.055-2.8s.211-.58.369-.949"
      />
    </svg>
  );
};
