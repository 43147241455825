import { Grid, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import Equalizer from '@material-ui/icons/Equalizer';
import { KeyboardDatePicker } from '@material-ui/pickers';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

interface Props {
  onDatesChange: (dateRange: { start: Date | null; end: Date | null }) => void;
}

export const StatisticsDatesSelector: React.FC<Props> = ({ onDatesChange }) => {
  const [dateRange, setSelectedDateRange] = React.useState<{
    start: Date | null;
    end: Date | null;
  }>({
    start: new Date(),
    end: new Date(),
  });

  useEffect(() => {
    onDatesChange(dateRange);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange]);

  const { t } = useTranslation();
  const handleDateChange = (type: 'start' | 'end') => (date: Date) => {
    setSelectedDateRange({ ...dateRange, [type]: date });
  };

  return (
    <Grid container direction="row" alignItems="center" spacing={4}>
      <Grid item>
        <Equalizer />
      </Grid>
      <Grid item>
        <Typography variant="h4">{t('admin-menu.statistics')}</Typography>
      </Grid>
      <Grid item>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date de début"
          value={dateRange.start}
          onChange={handleDateChange('start') as any}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
      <Grid item>
        <ArrowForward />
      </Grid>
      <Grid item>
        <KeyboardDatePicker
          disableToolbar
          variant="inline"
          format="dd/MM/yyyy"
          margin="normal"
          id="date-picker-inline"
          label="Date de fin"
          value={dateRange.end}
          onChange={handleDateChange('end') as any}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
        />
      </Grid>
    </Grid>
  );
};
