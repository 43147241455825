import { Grid } from '@material-ui/core';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { NewClientForm } from '../components/new.client.form.component';
import { ClientList } from '../container/clients.list.container';

export const ClientsRouter = () => {
  const location: any = useLocation();
  const { path } = useRouteMatch();
  const background = location.state && location.state.background;

  return (
    <Grid item xs={12}>
      <Switch location={background || location}>
        <Route exact path={`${path}/`}>
          <ClientList />
        </Route>
        <Redirect to={path} />
      </Switch>
      {background && (
        <Route path={`${path}/add`} children={<NewClientForm />} />
      )}
    </Grid>
  );
};
