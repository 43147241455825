import {
  Box,
  CardHeader as CardHeaderMui,
  CardHeaderProps,
  Typography,
  withStyles,
} from '@material-ui/core';
import React from 'react';
import { useStyles } from '../styles/card-header.style';

const StyledCardHeader = withStyles((theme) => ({
  action: {
    marginTop: 0,
    marginRight: 0,
  },
}))(CardHeaderMui);

interface ICardHeaderProps extends CardHeaderProps {
  icon?: JSX.Element;
}

export const CardHeader: React.FC<ICardHeaderProps> = ({
  title,
  icon,
  ...rest
}) => {
  const styles = useStyles();
  return (
    <StyledCardHeader
      {...rest}
      disableTypography
      title={
        <Box display="flex" alignItems="center">
          {icon && <Box pr={1}>{icon}</Box>}
          <Typography component="h4" variant="h6" className={styles.title}>
            {title}
          </Typography>
        </Box>
      }
    />
  );
};
