import React from 'react';

import { useTranslation } from 'react-i18next';
import { SubmissionErrors } from 'final-form';

import { BankPicturesComponent } from '../components/bank.pictures.component';
import { IFormData } from '../components/bank.pictures.form.component';

export const BankPictures: React.FC = () => {
  const { t } = useTranslation();

  const validate = async (values: IFormData) => {
    const errors: SubmissionErrors = {};

    if (!values.category) {
      errors.category = t('common.form.errors.field-required', {});
    }
    if (!values.picture) {
      errors.picture = t('common.form.errors.field-required', {});
    }

    return errors;
  };

  return <BankPicturesComponent validate={validate} />;
};
