import { makeStyles, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IStylePictureTheme } from '../entities/style.picture.entity';

export const useStyleTheme = () => {
  const theme = useTheme() as ThemeOptions & IStylePictureTheme;
  const useThemeStyle = makeStyles(theme ? theme.stylePicture : ({} as any));
  return useThemeStyle();
};

export const useStyle = makeStyles((theme: ThemeOptions) => ({
  defaultContainer: {},
  defaultpictureContainer: {},
  defaultsquare: {},
}));
