import { Box } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import React from 'react';

export function ErrorFallback({
  error,
  componentStack,
  resetErrorBoundary,
}: any) {
  return (
    <Alert severity="error">
      <AlertTitle>Une erreur est survenue :</AlertTitle>
      <Box>
        <pre>{error.message}</pre>
        <pre>{componentStack}</pre>
        <button onClick={resetErrorBoundary}>Réessayer</button>
        <p>
          Si en cliquant sur "Réessayer" cela ne fonctionne pas, veuillez
          recharger la page.
        </p>
      </Box>
    </Alert>
  );
}
