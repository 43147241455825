import React from 'react';
import { CreditCardComponent } from '../components/credit-card.component';
import { ICreditCardValue } from '../entities/credit-card.entity';
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { Shop } from 'sherl-react-sdk';

export const CreditCard: React.FC<ICreditCardValue> = ({ value }) => {
  const { search } = useLocation();
  const { card_result } = queryString.parse(search);

  const { requestCredentials, lemonwayConfig } = Shop.usePayment();

  return (
    <CreditCardComponent
      value={value}
      card_result={card_result as string}
      requestCredentials={requestCredentials}
      lemonwayConfig={lemonwayConfig}
    />
  );
};
