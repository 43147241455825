import { Grid } from '@material-ui/core';
import React from 'react';
import {
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { EstablishmentAdd } from '../components/establishment.add.component';
import { EstablishmentEnable } from '../components/establishment.enable.component';
import { EstablishmentList } from '../containers/establishment.list.container';

export const EstablishmentRouter = () => {
  const location: any = useLocation();
  const { path } = useRouteMatch();
  const background = location.state && location.state.background;

  return (
    <Grid item xs={12}>
      <Switch location={background || location}>
        <Route exact path={`${path}/`}>
          <EstablishmentList />
        </Route>
        <Redirect to={path} />
      </Switch>
      {background && (
        <Route
          path={`${path}/:id/disable`}
          children={<EstablishmentEnable />}
        />
      )}
      {background && (
        <Route path={`${path}/add`} children={<EstablishmentAdd />} />
      )}
    </Grid>
  );
};
