import {
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Chat } from '@material-ui/icons';
import { IExtendedTheme } from 'common/assets';
import { useInfiniteScroll } from 'common/hooks';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Opinion } from 'sherl-react-sdk';
import { useStyles } from '../styles/appointment.review.style';
import ReviewItem from './review.item.component';

export const Reviews = () => {
  const theme = useTheme();
  const { t } = useTranslation();
  const styles = useStyles();

  const {
    getOpinions,
    opinions,
    loading,
    view,
    pagination,
  } = Opinion.useAdminOpinions();

  useEffect(() => {
    getOpinions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { loadingMore } = useInfiniteScroll(async () => {
    if (view.page * parseInt(pagination.itemsPerPage) < view.total) {
      await getOpinions({ page: (view.page + 1).toString() });
    }
  });

  return (
    <Card elevation={5}>
      <Grid container>
        <Grid spacing={8} item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              padding: '1rem',
            }}
          >
            <Grid item xs={6} container direction="row" alignItems="center">
              <Chat />
              <Typography className={styles.pageTitle}>
                <b>{t('review.title-list')}</b>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} className={styles.tableContainer}>
        <Grid container className={styles.header}>
          <Grid item md={2}>
            {t('review.author')}
          </Grid>
          <Grid item md={2}>
            {t('review.receiver')}
          </Grid>
          <Grid item md={2}>
            {t('review.rate')}
          </Grid>
          <Grid item md={2}>
            {t('review.status')}
          </Grid>
        </Grid>
        {opinions.map((opinion, index) => {
          if (index + 1 !== opinions.length) {
            return (
              <>
                <ReviewItem opinion={opinion} />
                <Divider />
              </>
            );
          }
          return <ReviewItem opinion={opinion} />;
        })}
        <Grid
          container
          style={{ padding: theme.aux.spacing.m }}
          justify="center"
          alignItems="center"
        >
          {(loadingMore || loading) && <CircularProgress />}
        </Grid>
      </Grid>
    </Card>
  );
};
