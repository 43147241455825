import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
  InputLabel,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardHeader, DropzoneForm } from 'common/components';
import { getErrors } from 'common/helpers';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { ICategoryNested, IProduct, Shop, Config } from 'sherl-react-sdk';
import { IFormData } from '../entities/product-form.entity';
import { useValidate } from '../hooks/product-form.hook';
import { useHistory } from 'react-router-dom';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import { SelectCategories } from './select-categories.component';

library.add(faUser);

interface IProps {
  title?: string;
  subtitle?: string;
  product: IProduct;
  update: (id: string, productData: Partial<IProduct>) => Promise<unknown>;
  create: (productData: Partial<IProduct>) => Promise<unknown>;
  loading: boolean;
}

const DEFAULT_MAX_DEPTH = 2;

export const ProductForm: React.FC<IProps> = ({
  product,
  update,
  create,
  loading,
  title,
  subtitle,
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<IFormData>();
  const [categoriesSelected, setCategoriesSelected] = useState<{
    [p: string]: ICategoryNested;
  }>();
  const { addPhoto, removePhoto } = Shop.useProducts();
  const categoryMaxDepth =
    Config.ConfigService.get('categoryMaxDepth') || DEFAULT_MAX_DEPTH;

  const handleCategoriesChange = async (categories: {
    [p: string]: ICategoryNested;
  }) => {
    setCategoriesSelected(categories);
  };
  const handleSubmit = async (values: IFormData) => {
    const metadatas = {
      slogan: values.slogan,
      advantages: values.advantages,
      characteristics: values.characteristics,
      domain: values.domain,
      manual: values.manual,
      packaging: values.packaging,
      securitySheetPath: values.securitySheetPath,
      storage: values.storage,
      technicalSheetPath: values.technicalSheetPath,
    };
    const categoryUris: string[] = [];
    if (categoriesSelected) {
      Object.values(categoriesSelected).map((category) => {
        categoryUris.push(category.uri);
      });
    }
    try {
      let productId = product?.id;
      if (productId) {
        await update(productId, {
          ...values,
          categoryUris,
          id: productId,
          metadatas,
        });
      } else {
        const createdProduct = await create({
          ...values,
          categoryUris,
          metadatas,
        });
        productId = (createdProduct as IProduct).id;
      }
      if (values.photo) {
        if (product?.photos && product?.photos[0]) {
          await removePhoto(productId, product?.photos[0]?.id);
        }
        await addPhoto(productId, values.photo);
      }
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      history.push(`/app/products/${productId}`);
    } catch (err) {
      console.error('err', err);
      return {
        [FORM_ERROR]: t(`product-form.error.${err?.response?.status}`),
      };
    }
  };

  const { validate } = useValidate();

  useEffect(() => {
    if (product) {
      setInitialValues({
        name: product?.name,
        description: product?.description,
        storage: product?.metadatas?.storage,
        packaging: product?.metadatas?.packaging,
        manual: product?.metadatas?.manual,
        domain: product?.metadatas?.domain,
        characteristics: product?.metadatas?.characteristics,
        advantages: product?.metadatas?.advantages,
        slogan: product?.slogan,
        technicalSheetPath: product?.metadatas?.technicalSheetPath,
        securitySheetPath: product?.metadatas?.securitySheetPath,
      });
    }
  }, [product]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate as any}
      initialValues={initialValues}
      subscription={{
        submitting: true,
        errors: true,
        submitErrors: true,
        submitFailed: true,
        dirtySinceLastSubmit: true,
        submitSucceeded: true,
      }}
      render={({
        handleSubmit,
        submitting,
        errors,
        submitErrors,
        submitFailed,
        dirtySinceLastSubmit,
        submitSucceeded,
      }) => {
        const errorToShow = getErrors(
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
        );
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              {errorToShow[FORM_ERROR] && (
                <Grid item xs={12}>
                  <Alert severity="error">{errorToShow[FORM_ERROR]}</Alert>
                </Grid>
              )}
              {submitSucceeded && (
                <Grid item xs={12}>
                  <Alert severity="success">
                    {t('common.form.updateSucceeded')}
                  </Alert>
                </Grid>
              )}
            </Grid>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <Card>
                  <CardContent>
                    <h4>{t('product-form.picture')}</h4>
                    <Field
                      name="photo"
                      label={t('screen.establishment.image')}
                      render={({ input }) => {
                        return (
                          <DropzoneForm
                            input={input}
                            picture={
                              product?.photos
                                ? product?.photos[0]?.thumbnail?.caption
                                    ?.contentUrl
                                : undefined
                            }
                          />
                        );
                      }}
                    />
                    <InputLabel htmlFor="grouped-native-select">
                      {t('product-form.categories')}
                    </InputLabel>
                    <SelectCategories
                      categoryUris={product?.categoryUris}
                      handleChange={handleCategoriesChange}
                      categoryMaxDepth={categoryMaxDepth}
                    />
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={9}>
                <Card>
                  <CardHeader
                    title={title ?? t('product-form.title')}
                    icon={<FontAwesomeIcon icon={faUser} />}
                  />
                  <CardContent>
                    {subtitle && (
                      <Typography variant="caption" paragraph>
                        {subtitle}
                      </Typography>
                    )}
                    {loading && (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    )}
                    {!loading && (
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            size="small"
                            name="name"
                            label={t('product-form.name')}
                            required
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            size="medium"
                            name="slogan"
                            label={t('product-form.slogan')}
                            required
                          />
                        </Grid>
                        <Grid item>
                          <InputLabel shrink required>
                            {t('product-form.description')}
                          </InputLabel>
                          <Field name="description">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
                <p></p>
                <Card>
                  <CardHeader
                    title={t('product-details.product-form.title-options')}
                    action={
                      <Button
                        type="submit"
                        variant="contained"
                        color="primary"
                        size="small"
                        disabled={submitting}
                      >
                        {t('common.save')}
                      </Button>
                    }
                  />
                  <CardContent>
                    {subtitle && (
                      <Typography variant="caption" paragraph>
                        {subtitle}
                      </Typography>
                    )}
                    {loading && (
                      <Box display="flex" justifyContent="center">
                        <CircularProgress />
                      </Box>
                    )}
                    {!loading && (
                      <Grid container direction="column" spacing={2}>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.advantages')}
                          </InputLabel>
                          <Field name="advantages">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.characteristics')}
                          </InputLabel>
                          <Field name="characteristics">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.domain')}
                          </InputLabel>
                          <Field name="domain">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.manual')}
                          </InputLabel>
                          <Field name="manual">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.packaging')}
                          </InputLabel>
                          <Field name="packaging">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <InputLabel shrink>
                            {t('product-form.storage')}
                          </InputLabel>
                          <Field name="storage">
                            {(props) => (
                              <div>
                                <CKEditor
                                  name={props.input.name}
                                  editor={ClassicEditor}
                                  data={props.input.value}
                                  onChange={(event, editor) => {
                                    const data = editor.getData();
                                    props.input.onChange(data);
                                  }}
                                />
                              </div>
                            )}
                          </Field>
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            size="small"
                            name="technicalSheetPath"
                            label={t('product-form.technicalSheetPath')}
                          />
                        </Grid>
                        <Grid item>
                          <TextField
                            variant="outlined"
                            size="small"
                            name="securitySheetPath"
                            label={t('product-form.securitySheetPath')}
                          />
                        </Grid>
                      </Grid>
                    )}
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
