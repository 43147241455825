import { Box } from '@material-ui/core';
import { Logo } from '../../../common/components/logo';
import React from 'react';
import { useStyles } from '../styles/payment.style';
export const Layout: React.FC = ({ children }) => {
  const styles = useStyles();
  return (
    <Box
      display="flex"
      width="100%"
      justifyContent="center"
      className={styles.background}
    >
      <Box py={10} px={2} className={styles.card}>
        <Box pb={3} display="flex" width="100%" justifyContent="center">
          <img alt="Logo" srcSet={Logo()} style={{ width: 150 }} />
        </Box>
        {children}
      </Box>
    </Box>
  );
};
