import { createMuiTheme } from '@material-ui/core/styles';
import { defaultTheme, theme as commonTheme } from 'common/assets';

export const theme = createMuiTheme({
  ...defaultTheme,
  ...commonTheme,
  palette: {
    ...commonTheme.palette,
  },
});
