import React from 'react';
import { TextField, TextFieldProps } from 'mui-rff';
import { useTranslation } from 'react-i18next';

interface IProps extends Partial<TextFieldProps> {
  name?: string;
}

export const PasswordField: React.FC<IProps> = ({
  name = 'password',
  ...rest
}) => {
  const { t } = useTranslation();
  const validate = (value: string) => {
    if (!/^((?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,50})$/.test(value)) {
      return t('authentication.errors.validate');
    }
  };
  return (
    <TextField
      required
      name={name}
      fieldProps={{ validate: validate }}
      label={t('authentication.password')}
      color="primary"
      variant="outlined"
      type="password"
      autoComplete="new-password"
      {...rest}
    />
  );
};
