import { CSSProperties } from '@material-ui/styles/withStyles';

export const fonts = {
  '@font-face': [
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 100,
      src: `
    url(/assets/fonts/Comfortaa-Light.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 200,
      src: `
    url(/assets/fonts/Comfortaa-Light.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 300,
      src: `
    url(/assets/fonts/Comfortaa-Light.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: `
    url(/assets/fonts/Comfortaa-Regular.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 500,
      src: `
    url(/assets/fonts/Comfortaa-Medium.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 600,
      src: `
    url(/assets/fonts/Comfortaa-SemiBold.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 700,
      src: `
    url(/assets/fonts/Comfortaa-Bold.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 800,
      src: `
    url(/assets/fonts/Comfortaa-Bold.ttf) format('truetype')
  `,
    },
    {
      fontFamily: 'Comfortaa',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 900,
      src: `
    url(/assets/fonts/Comfortaa-Bold.ttf) format('truetype')
  `,
    },

    {
      fontFamily: 'Abril Fatface',
      fontStyle: 'normal',
      fontDisplay: 'swap',
      fontWeight: 400,
      src: `
    url(/assets/fonts/AbrilFatface-Regular.ttf) format('truetype')
  `,
    },
  ],
} as CSSProperties;

export const fontWeights = {
  fontWeightThin: 100,
  fontWeightExtraLight: 300,
  fontWeightLight: 300,
  fontWeightRegular: 400,
  fontWeightMedium: 500,
  fontWeightSemiBold: 600,
  fontWeightBold: 700,
  fontWeightExtraBold: 800,
  fontWeightBlack: 900,
};
export const fontSize = {
  display1: {
    fontSize: '3.875rem',
  },
};
