import React, { ReactElement } from 'react';
import { Person } from 'sherl-react-sdk';

export const RequireAccountType: React.FC<{
  enabled?: boolean;
  requiredAccountTypes: string[] | undefined;
  children: ReactElement<any, any>;
}> = ({ requiredAccountTypes, enabled = true, children }) => {
  const { me } = Person.usePerson();
  if (enabled) {
    return requiredAccountTypes && requiredAccountTypes.includes(me.type)
      ? children
      : null;
  } else {
    return children;
  }
};
