import { useState } from 'react';

export const useConfirm = () => {
  const [itemToDelete, setItemToDelete] = useState<string>();
  const [error, setError] = useState<string>();

  const handleClickOpen = (item: string) => {
    setItemToDelete(item);
    setError(undefined);
  };

  const handleConfirm = (callback: () => void) => {
    callback();
    setItemToDelete(undefined);
    setError(undefined);
  };

  const handleClose = () => {
    setItemToDelete(undefined);
    setError(undefined);
  };

  const handleError = (errorMsg: string) => {
    setError(errorMsg);
  };

  return {
    itemToDelete,
    handleClickOpen,
    handleClose,
    handleConfirm,
    error,
    handleError,
  };
};
