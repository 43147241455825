import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { PersonAdd } from '@material-ui/icons';
import { ProAutocomplete } from 'common/components';
import { UserAutocompleteField } from 'common/components/user-autocomplete';
import { sleep } from 'common/helpers';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form, FormSpy } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import { IOrganization, IPerson, Shop } from 'sherl-react-sdk';

type FormValues = {
  projectName: string;
  description: string;
  amount: string;
  pro: IOrganization;
  user: IPerson;
};

export const Billing: React.FC<any> = () => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const theme = useTheme();
  const { addProduct } = Shop.useProducts();

  const { addBasket } = Shop.useBasket();

  const location = useLocation();

  const onSubmit = async (values: FormValues) => {
    try {
      const product = await addProduct({
        name: values.projectName,
        description: values.description,
        slogan: 'slogan',
        priceTaxIncluded: parseInt(values.amount),
      });

      await addBasket({
        organizationUri: values.pro.uri,
        customerUri: `/api/persons/${values.user.id}`,
        orderQuantity: 1,
        productId: product.id,
      });
      await sleep(500);
      push('/app/orders');
      toast.success(t('common.form.succeeded'));
    } catch (err) {
      console.log({ err });
      toast.error(t('common.form.failed'));
    }
  };

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Grid container spacing={4}>
              <Grid item xs={12}>
                <Card style={{ padding: theme.aux.spacing.s }}>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {t('orders-create.generalInformations')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('orders-create.generalInformationsCaption')}
                    </Typography>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item md={6} xs={12}>
                      <ProAutocomplete name="pro" />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <FormSpy
                        render={(formProps) => (
                          <>
                            <UserAutocompleteField {...(formProps as any)} />
                            <Link
                              to={{
                                pathname: `${location.pathname}/add-client`,
                                state: { background: location },
                              }}
                              style={{ textDecoration: 'none' }}
                            >
                              <Button
                                variant="text"
                                color="primary"
                                startIcon={<PersonAdd />}
                              >
                                {t('clients-list.add-client')}
                              </Button>
                            </Link>
                          </>
                        )}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={12}>
                <Card style={{ padding: theme.aux.spacing.s }}>
                  <Grid item xs={12}>
                    <Typography variant="h4">
                      {t('orders-create.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('orders-create.caption')}
                    </Typography>
                  </Grid>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <TextField
                        name="projectName"
                        label={t('orders-create.projectName')}
                        placeholder={t('orders-create.projectName')}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="description"
                        placeholder={t('orders-create.description')}
                        label={t('orders-create.description')}
                        multiline
                        rows={5}
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name="amount"
                        type="number"
                        placeholder={t('orders-create.amount')}
                        label={t('orders-create.amount')}
                        fieldProps={{
                          validate: (value) =>
                            value < 0 ? t('common.form.positive') : undefined,
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <Button
                          color="primary"
                          type="submit"
                          variant="contained"
                        >
                          {t('orders-create.send-bill')}
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
