import { library } from '@fortawesome/fontawesome-svg-core';
import { faPowerOff, faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  AppBar,
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  MenuList,
  Popover,
  Toolbar,
  Typography,
  useTheme,
  Icon,
} from '@material-ui/core';
import {
  ChevronLeft,
  ChevronRight,
  Menu as MenuIcon,
} from '@material-ui/icons';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Logo } from '../../common/components/logo';
import clsx from 'clsx';
import { Impersonate } from 'common/components/impersonate-token';
import Config from 'config/routes.config';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Link as RouterLink,
  LinkProps,
  useHistory,
  useLocation,
  useRouteMatch,
} from 'react-router-dom';
import { Auth, Person, store } from 'sherl-react-sdk';
import { useStyles } from '../styles/app.layout.style';
import AppRouter, { menuRoutes } from './app.router';
import { Footer } from './footer.component';
import { NotAuthorize } from './not-authorize.component';

library.add(faUser, faPowerOff);

export const GrantedRouterLink = (props: LinkProps) => {
  const { me } = Person.usePerson();
  const { path } = useRouteMatch();
  if (
    Config[me.type as 'ADMIN' | 'FOUNDER' | 'EMPLOYEE' | 'DEFAULT'].includes(
      (props.to as string).replace(path, '') as never,
    )
  ) {
    return <RouterLink {...props} />;
  } else {
    return null;
  }
};

export const Layout: React.FC = () => {
  const classes = useStyles();
  const theme = useTheme();
  const { path } = useRouteMatch();
  const { push } = useHistory();
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const { me, meLoading } = Person.usePerson();
  const { logout } = Auth.useAuthLogout();
  const [anchorEl, setAnchorEl] = useState(null);
  const { listen } = useHistory();

  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  listen((location, action) => {
    handleClose();
  });
  const openPopover = Boolean(anchorEl);
  const id = openPopover ? 'account-admin-dropdown' : undefined;

  return (
    <>
      {me && (
        <>
          {me.type !== 'ADMIN' && !me?.affiliation?.id && <NotAuthorize />}
          {me.type !== 'ADMIN' &&
            !!me?.affiliation?.id &&
            !me?.affiliation?.enabled && <NotAuthorize />}
          <div className={classes.root}>
            <AppBar
              position="fixed"
              color="default"
              className={clsx(classes.appBar, {
                [classes.appBarShift]: open,
              })}
            >
              <Toolbar>
                <Box
                  display="flex"
                  width="100%"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Box display="flex" alignItems="center">
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={handleDrawerOpen}
                      edge="start"
                      className={clsx(classes.menuButton, open && classes.hide)}
                    >
                      <MenuIcon />
                    </IconButton>
                    <img alt="Logo" srcSet={Logo()} style={{ width: 150 }} />
                  </Box>
                  <Box display="flex" flexDirection="row">
                    <Impersonate />
                    <Button onClick={handleClick}>
                      {me?.lastName} {me?.firstName}
                    </Button>
                    <Popover
                      id={id}
                      open={openPopover}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <MenuList id="split-button-menu-admin">
                        <MenuItem className={classes.menuItem}>
                          <Button
                            className={classes.menuItemButton}
                            component={RouterLink}
                            color="primary"
                            to={'/app/profile'}
                            startIcon={
                              <FontAwesomeIcon icon={faUser} size="sm" />
                            }
                          >
                            {t('navbar.my-account')}
                          </Button>
                        </MenuItem>
                        <MenuItem className={classes.menuItem}>
                          <Button
                            className={classes.menuItemButton}
                            onClick={() => {
                              localStorage.clear();
                              logout();
                              push('/');
                            }}
                            color="primary"
                            startIcon={
                              <FontAwesomeIcon icon={faPowerOff} size="sm" />
                            }
                          >
                            {t('navbar.logout')}
                          </Button>
                        </MenuItem>
                      </MenuList>
                    </Popover>
                  </Box>
                </Box>
              </Toolbar>
            </AppBar>
            <Drawer
              className={classes.drawer}
              variant="persistent"
              anchor="left"
              open={open}
              classes={{
                paper: classes.drawerPaper,
              }}
            >
              <ListItem
                button
                className={classes.drawerHeader}
                onClick={handleDrawerClose}
              >
                <Typography variant="body1" noWrap color="textSecondary">
                  {t('common.close')}
                </Typography>
                <IconButton>
                  {theme.direction === 'ltr' ? (
                    <ChevronLeft style={{ color: 'white' }} />
                  ) : (
                    <ChevronRight style={{ color: 'white' }} />
                  )}
                </IconButton>
              </ListItem>
              <Divider />
              {menuRoutes.map((route) => (
                <React.Fragment key={route.path}>
                  {(route.subRoutes == null ||
                    route.subRoutes.length === 0) && (
                    <GrantedRouterLink
                      to={`${path}${route.path}`}
                      style={{ textDecoration: 'none' }}
                    >
                      <ListItem
                        button
                        key={route.path}
                        className={clsx({
                          [classes.selected]: pathname.match(
                            `${path}${route.path}`,
                          ),
                        })}
                      >
                        <ListItemIcon>
                          <Icon>
                            {(Config[
                              route.path.replace(
                                path,
                                '',
                              ) as keyof typeof Config
                            ] as { [key: string]: string })?.icon || route.icon}
                          </Icon>
                        </ListItemIcon>
                        <ListItemText
                          primary={t(`admin-menu.${route.title}`)}
                        />
                      </ListItem>
                    </GrantedRouterLink>
                  )}
                  {route.subRoutes &&
                    Config[me.type][route.path.replace(path, '')] && (
                      <Accordion
                        classes={{
                          root: classes.expansion,
                        }}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          classes={{
                            root: classes.expansionSummary,
                            content: classes.expansionSummaryContent,
                          }}
                        >
                          <ListItem button key={route.path}>
                            <ListItemIcon>
                              <Icon>
                                {(Config[
                                  route.path.replace(
                                    path,
                                    '',
                                  ) as keyof typeof Config
                                ] as { [key: string]: string }).icon ||
                                  route.icon}
                              </Icon>
                            </ListItemIcon>
                            <ListItemText
                              primary={t(`admin-menu.${route.title}`)}
                            />
                          </ListItem>
                        </AccordionSummary>
                        {route.subRoutes.map((subRoute) => (
                          <GrantedRouterLink
                            to={`${path}${subRoute.path}`}
                            style={{ textDecoration: 'none' }}
                            key={subRoute.title}
                          >
                            <AccordionDetails
                              classes={{ root: classes.expansion }}
                            >
                              <ListItem
                                button
                                key={route.path}
                                className={clsx({
                                  [classes.selected]: pathname.match(
                                    `${path}${subRoute.path}`,
                                  ),
                                })}
                              >
                                <ListItemIcon>
                                  {(Config[
                                    subRoute.path.replace(
                                      path,
                                      '',
                                    ) as keyof typeof Config
                                  ] as { [key: string]: string }).icon ||
                                    subRoute.icon}
                                </ListItemIcon>
                                <ListItemText
                                  primary={t(`admin-menu.${subRoute.title}`)}
                                />
                              </ListItem>
                            </AccordionDetails>
                          </GrantedRouterLink>
                        ))}
                      </Accordion>
                    )}
                </React.Fragment>
              ))}
            </Drawer>
            <main
              className={clsx(classes.content, {
                [classes.contentShift]: open,
              })}
            >
              <div className={classes.drawerHeaderFake} />
              <AppRouter />
            </main>
          </div>
          <Footer />
        </>
      )}
      {!meLoading && !me && <NotAuthorize />}
    </>
  );
};
