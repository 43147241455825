import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Organization } from 'sherl-react-sdk';
import EstablishmentListView from '../components/establishement.list.view';

/* eslint-disable react-hooks/exhaustive-deps */

export const EstablishmentList: React.FC = () => {
  const { t } = useTranslation();
  const { location } = useHistory();
  const {
    getOrganizations,
    organizationsLoader,
    organizationsSortedByDateSelector,
    total,
  } = Organization.useOrganizations();

  const [page, setPage] = useState<number>(1);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  useEffect(() => {
    getOrganizations(page, undefined, {
      'sort[field]': 'createdAt',
      'sort[order]': 'desc',
    });
  }, [page, location.pathname]);
  const organizations = organizationsSortedByDateSelector('createdAt', 'desc');
  return (
    <EstablishmentListView
      isLoading={organizationsLoader}
      establishments={organizations}
      t={t}
      loadingMore={organizations.length !== total}
      handleNextPage={handleNextPage}
      searchEstablishment={() => null}
    />
  );
};
