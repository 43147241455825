import { Grid } from '@material-ui/core';
import React, { useState } from 'react';
import { CmsCard } from './cms-card.component';
import { CmsPageList } from './cms-page-list.component';

export const Cms: React.FC = () => {
  const [selectedPage, setSelectedPage] = useState<string>('');
  const handleListItemClick = (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
  ) => {
    setSelectedPage(id);
  };
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={4}>
        <CmsPageList
          selectedPage={selectedPage}
          handleClick={handleListItemClick}
        />
      </Grid>
      <Grid item xs={12} md={8}>
        <CmsCard id={selectedPage} />
      </Grid>
    </Grid>
  );
};
