import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CircularProgress,
  Grid,
  GridList,
  GridListTile,
  List,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PhotoLibrary } from '@material-ui/icons';
import clsx from 'clsx';
import { ConfirmDialog } from 'common/components';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Gallery, IPicture } from 'sherl-react-sdk';
import { useStyles } from '../styles/bank.pictures.style';
import { BankPictureForm, IFormData } from './bank.pictures.form.component';

interface IProps {
  validate: (values: IFormData) => any;
}

export const BankPicturesComponent: React.FC<IProps> = ({ validate }) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const {
    getList,
    list,
    deleteItem,
    deleteLoading,
    addLoading,
  } = Gallery.useGallery();
  useEffect(() => {
    getList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [selectedPicture, setSelectedPicture] = useState<{
    picture: { media: IPicture; id: string };
    categoryName: string;
  }>();
  const [isOpenconfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [isOpenForm, setIsOpenForm] = useState(false);

  const handleConfirmDelete = async () => {
    setIsOpenConfirmDelete(!isOpenconfirmDelete);
  };

  const handleForm = () => {
    setIsOpenForm(!isOpenForm);
  };

  const isPictureSelected = useMemo(
    () => (pictureId: string) => {
      if (selectedPicture !== undefined) {
        return pictureId === selectedPicture.picture.id;
      }
      return false;
    },
    [selectedPicture],
  );

  const confirmDeleteMessage = (): string => {
    if (
      selectedPicture &&
      selectedPicture.picture.media.caption &&
      selectedPicture.picture.media.caption.name
    ) {
      return selectedPicture.picture.media.caption.name;
    } else return '';
  };

  const onClickPicture = (picture) => {
    if (selectedPicture && selectedPicture.picture.id === picture.id) {
      setSelectedPicture(undefined);
    } else {
      setSelectedPicture(picture);
    }
  };

  return (
    <>
      <Card>
        <CardContent className={styles.container}>
          <Box display="flex">
            <PhotoLibrary color="primary" />
            <Typography className={styles.title}>
              {t('bank-pictures.bank-pictures')}
            </Typography>
          </Box>
          <List>
            {Object.keys(list).map((key) => {
              return (
                <Box component="div">
                  <Typography className={styles.categoryTitle}>
                    {key}
                  </Typography>
                  <div className={styles.imagesContainer}>
                    <GridList cellHeight={120}>
                      {list[key].map((picture) => (
                        <GridListTile
                          key={picture.id}
                          cols={2}
                          className={clsx({
                            [styles.img]: true,
                            [styles.imgSelected]: isPictureSelected(picture.id),
                          })}
                        >
                          <img
                            src={picture.media.thumbnail.caption.contentUrl}
                            alt={
                              picture.media.caption
                                ? picture.media.caption.name
                                : ''
                            }
                            onClick={() =>
                              onClickPicture({ picture, categoryName: key })
                            }
                          />
                        </GridListTile>
                      ))}
                    </GridList>
                  </div>
                </Box>
              );
            })}
          </List>
        </CardContent>
        <CardActions>
          <Grid container justify="flex-end" spacing={2}>
            <Grid item xs={12} md="auto">
              <Button
                fullWidth={isMobile}
                color="primary"
                variant="contained"
                disabled={!selectedPicture || deleteLoading}
                onClick={handleConfirmDelete}
              >
                {!deleteLoading && t('common.delete')}
                {deleteLoading && <CircularProgress size={20} />}
              </Button>
            </Grid>
            <Grid item xs={12} md="auto">
              <Button
                fullWidth={isMobile}
                disabled={addLoading}
                color="primary"
                variant="contained"
                onClick={handleForm}
              >
                {!addLoading && t('bank-pictures.add-picture')}
                {addLoading && <CircularProgress size={20} />}
              </Button>
            </Grid>
          </Grid>
        </CardActions>
      </Card>

      <ConfirmDialog
        isOpen={isOpenconfirmDelete}
        title={t('bank-pictures.confirm-delete-title')}
        onConfirm={() => {
          if (selectedPicture?.picture?.id) {
            deleteItem(
              selectedPicture?.picture?.id,
              selectedPicture?.categoryName,
            );
            setIsOpenConfirmDelete(false);
          }
        }}
        onClose={handleConfirmDelete}
        message={confirmDeleteMessage()}
      />
      <BankPictureForm
        handleForm={handleForm}
        isOpenForm={isOpenForm}
        onClose={() => setIsOpenForm(false)}
        validate={validate}
      />
    </>
  );
};
