import { makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IFooterTheme } from '../entities/footer.entity';

export const useStyleTheme = (): any => {
  const theme = useTheme() as ThemeOptions & IFooterTheme;
  const useThemeStyle = makeStyles(theme ? theme.footer : ({} as any));
  return useThemeStyle();
};

export const useStyle = makeStyles((theme: Theme) => ({
  footer: {
    width: '100%',
  },
  footerContainer: {
    backgroundColor: theme.palette.common.white,
  },
}));
