import { library } from '@fortawesome/fontawesome-svg-core';
import { faMoneyCheckAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardHeader } from 'common/components/card-header';
import { getErrors } from 'common/helpers';
import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import React, { useEffect } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IRib, Organization, Person } from 'sherl-react-sdk';
import * as Yup from 'yup';
import { IFormData } from '../entities/organization-rib-form.entity';

library.add(faMoneyCheckAlt);

export const OrganizationRIBForm: React.FC = () => {
  const { t } = useTranslation();
  const { addRib, rib, getRib } = Organization.useOrganization();
  const { me } = Person.usePerson();

  const initialValues = {
    iban: rib ? rib.iban : '',
    bic: rib ? rib.bic : '',
  };
  const onSubmit = async (values: IFormData) => {
    const rib: IRib = {
      iban: values?.iban,
      bic: values?.bic,
    };
    try {
      await addRib(me?.affiliation?.id, rib);
      toast.success(t('common.notification.update-success'));

      return {};
    } catch (err) {
      toast.error(t('common.notification.update-failed'));
      return { [FORM_ERROR]: t('rib.error') };
    }
  };
  const schema = Yup.object<IFormData>().shape({
    iban: Yup.string().required().label(t('organization-rib-form.iban')),
    bic: Yup.string().required().label(t('organization-rib-form.bic')),
  });
  const validate = makeValidate(schema);

  useEffect(() => {
    if (me?.affiliation && me.affiliation.id) {
      getRib(me.affiliation.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [me]);

  return (
    <Form
      onSubmit={onSubmit}
      initialValues={initialValues}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        errors,
        submitFailed,
        submitErrors,
        dirtySinceLastSubmit,
      }) => {
        const errorsToShow = getErrors(
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
        );
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={t('organization-rib-form.title')}
                icon={<FontAwesomeIcon icon={faMoneyCheckAlt} />}
                action={
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={submitting}
                  >
                    {!submitting && t('common.save')}
                    {submitting && <CircularProgress />}
                  </Button>
                }
              />
              <CardContent>
                {errorsToShow[FORM_ERROR] && (
                  <Alert severity="error">{errorsToShow[FORM_ERROR]}</Alert>
                )}

                <Grid container direction="column" spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="iban"
                      variant="outlined"
                      size="small"
                      label={t('organization-rib-form.iban')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      name="bic"
                      variant="outlined"
                      size="small"
                      label={t('organization-rib-form.bic')}
                    />
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </form>
        );
      }}
    />
  );
};
