import { Box } from '@material-ui/core';
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import {
  AnonymousPayment,
  PaymentSuccess,
  PaymentRefused,
  PaymentCancel,
} from 'screens';

export const PublicPaymentRouter: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <Box>
      <Switch>
        <Route path={`${path}/payment/:token`}>
          <AnonymousPayment />
        </Route>
        <Route path={`${path}/payment-success`}>
          <PaymentSuccess />
        </Route>
        <Route path={`${path}/payment-refused`}>
          <PaymentRefused />
        </Route>
        <Route path={`${path}/payment-cancel`}>
          <PaymentCancel />
        </Route>
        <Route path="/404">{/* <Page404 /> */}</Route>
      </Switch>
    </Box>
  );
};
