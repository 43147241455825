export const color = {
  primary: '#F14A1A',
  blue: '#00C6CF',
  white: '#FFFFFF',
  darkGrey: '#707070',
  shadowsLight: 'rgba(166, 218, 218, 0.4)',
  whiteShadows: '#f5f7f6',
  shadows: '#89B4B489',
  darkBlue: '#00C6CF80',
  info: '#00C6CF',
  veryDarkLightOpacity: '#171A234D',
  yellowLight: '#FCFFBE',
  cyan: '#A6DADA',
  grayishBlue: '#c0c1df',
  grey: '#171A2380',
};
