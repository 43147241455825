import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    height: '100%',
    borderRadius: 4,
    overflow: 'hidden',
  },
  draganddrop: {
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    cursor: 'pointer',
  },
  fillParent: {
    flex: 1,
    position: 'relative',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
  },
  imgUploadTextBg: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    '-webkit-transition': 'opacity 100ms ease-in',
    '-moz-transition': 'opacity 100ms ease-in',
    '-o-transition': 'opacity 100ms ease-in',
    '&:hover': {
      opacity: 0.3,
    },
  },
  imgUploadText: {
    padding: theme.spacing(2),
    borderRadius: 4,
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'row',
    display: 'flex',
    color: theme.palette.common.white,
  },
  imgContainer: {
    position: 'absolute',
    left: 0,
    bottom: 0,
    right: 0,
    top: 0,
    width: '100%',
    display: 'flex',
    alignItems: 'center',
  },
  img: {
    width: '100%',
    objectFit: 'cover',
  },
  uploadButton: {
    backgroundColor: theme.palette.primary.main,
    opacity: 0.5,
    padding: theme.spacing(2),
  },
  dropZone: {
    backgroundColor: theme.palette.grey[200],
    minHeight: '400px',
    minWidth: '400px',
  },
  marginTop: {
    marginTop: theme.spacing(2),
  },
  withMargin2x: {
    marginBottom: theme.spacing(4),
  },
  withMargin: {
    marginBottom: theme.spacing(2),
  },
}));
