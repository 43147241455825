import {
  Button,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useCopyToClipboard } from 'react-use';
import { IOrganization } from 'sherl-react-sdk';

interface IProps {
  handleCancel?: () => void;
  organization: IOrganization;
}

export const BankTransfer: React.FC<IProps> = ({
  handleCancel,
  organization,
}) => {
  const [state, copyToClipboard] = useCopyToClipboard();

  const { t } = useTranslation();

  const handleCopy = (value: string) => {
    copyToClipboard(value);
  };

  return (
    <div>
      <Typography paragraph>
        {t('anonymous-payment.transfer-instruction')}
      </Typography>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField
            label={t('anonymous-payment.iban')}
            value={organization?.bankAlias?.iban}
            fullWidth
            onFocus={(event) => {
              event.target.setSelectionRange(0, 200);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={t('anonymous-payment.copy-to-clipboard') as string}
                  >
                    <IconButton
                      aria-label="copy to clipboard"
                      onClick={() =>
                        handleCopy(organization?.bankAlias?.iban ?? '')
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            value={organization?.bankAlias?.bic}
            fullWidth
            label={t('anonymous-payment.bic')}
            onFocus={(event) => {
              event.target.setSelectionRange(0, 200);
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Tooltip
                    title={t('anonymous-payment.copy-to-clipboard') as string}
                  >
                    <IconButton
                      aria-label="copy to clipboard"
                      onClick={() =>
                        handleCopy(organization?.bankAlias?.bic ?? '')
                      }
                    >
                      <FileCopyIcon />
                    </IconButton>
                  </Tooltip>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
      </Grid>
      <Grid container spacing={2} justify="flex-end">
        <Grid item>
          <Button color="primary" variant="contained" onClick={handleCancel}>
            {t('common.back')}
          </Button>
        </Grid>
      </Grid>
    </div>
  );
};
