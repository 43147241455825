import { Grid } from '@material-ui/core';
import {
  OrganizationDocumentUpload,
  OrganizationRIBForm,
} from 'common/components';
import React from 'react';
import { AccountCompany } from './account.company.component';
import { AccountFounder } from './account.founder.component';

export const Account = () => {
  return (
    <Grid container spacing={2}>
      <Grid item md={6} xs={12}>
        <AccountCompany />
      </Grid>
      <Grid item md={6} xs={12}>
        <AccountFounder />
      </Grid>
      <Grid item xs={12} md={6}>
        <OrganizationDocumentUpload />
      </Grid>

      <Grid item xs={12} md={6}>
        <OrganizationRIBForm />
      </Grid>
    </Grid>
  );
};
