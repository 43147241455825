import { Box, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import { TransitionProps } from '@material-ui/core/transitions';
import { Alert } from '@material-ui/lab';
import { getErrors, validateEmail } from 'common/helpers';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { User } from 'sherl-react-sdk';

const Transition = React.forwardRef(
  (
    props: TransitionProps & { children?: React.ReactElement<any, any> },
    ref: React.Ref<unknown>,
  ) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

interface IFormData {
  email: string;
}

export const ForgottenPassword = () => {
  const history = useHistory();
  const locationState = useLocation();
  const [email, setEmail] = useState<string>('');
  const { t } = useTranslation();
  const { sendPasswordResetRequest } = User.usePasswordResetRequest();
  const goBack = (e: any) => {
    if (e.stopPropagation) {
      e.stopPropagation();
    }
    history.goBack();
  };

  const onSubmit = async (values: IFormData) => {
    const errors: SubmissionErrors = {};
    try {
      await sendPasswordResetRequest(values.email);
    } catch (err) {
      return { [FORM_ERROR]: t('authentication.forgot-password.failed') };
    }

    return errors;
  };

  useEffect(() => {
    if ((locationState?.state as any).state?.email) {
      setEmail((locationState?.state as any).state?.email);
    }
  }, [locationState]);

  return (
    <Box>
      <Form
        onSubmit={onSubmit}
        initialValues={{ email } as IFormData}
        validate={(values) => {
          const errors: SubmissionErrors = {};

          if (!values.email) {
            errors.email = t('authentication.errors.email-required');
          }
          if (!validateEmail(values.email)) {
            errors.email = t('authentication.errors.email-not-valid');
          }

          return errors;
        }}
        render={({
          handleSubmit,
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
          submitSucceeded,
          submitting,
        }) => {
          const errorsToShow = getErrors(
            errors,
            submitFailed,
            submitErrors,
            dirtySinceLastSubmit,
          );
          return (
            <Dialog
              fullWidth
              TransitionComponent={Transition}
              keepMounted
              open
              onClose={goBack}
              aria-labelledby="form-dialog-title"
            >
              <form onSubmit={handleSubmit}>
                <DialogTitle id="form-dialog-title" disableTypography={true}>
                  <Typography variant="h6">
                    {t('authentication.forgot-password.title')}
                  </Typography>
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    {t('authentication.forgot-password.description')}
                  </Typography>
                  <br />
                  {submitSucceeded && (
                    <>
                      <Grid container justify="center">
                        <Alert severity="success">
                          {t('authentication.forgot-request-succeeded')}
                        </Alert>
                      </Grid>
                      <br />
                    </>
                  )}
                  <TextField
                    label={t('common.form.email')}
                    fieldProps={{
                      validate: (value) => {
                        if (!validateEmail(value)) {
                          return t('authentication.errors.email-not-valid');
                        }
                      },
                    }}
                    name="email"
                    fullWidth
                    color="primary"
                    variant="filled"
                  />

                  {errorsToShow[FORM_ERROR] && (
                    <Grid container>
                      <Alert severity="error">{errorsToShow[FORM_ERROR]}</Alert>
                    </Grid>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button onClick={goBack} color="inherit">
                    {t('common.cancel')}
                  </Button>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={submitting || !!errorsToShow[FORM_ERROR]}
                  >
                    {t('common.confirm')}
                  </Button>
                </DialogActions>
              </form>
            </Dialog>
          );
        }}
      />
    </Box>
  );
};
