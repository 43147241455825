import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { IExtendedTheme } from 'common/assets';
import { TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Opinion } from 'sherl-react-sdk';
import { useStyles } from '../styles/appointment.review.style';

export const ReviewRejection: React.FC = () => {
  const { t } = useTranslation();
  const classNames = useStyles();
  const { goBack } = useHistory();
  const theme = useTheme();
  const { params } = useRouteMatch<{ id: string }>();
  const { loading, updateOpinion, opinion, error } = Opinion.useAdminOpinion(
    params.id,
  );
  const [processed, setProcessed] = useState(false);

  if (!opinion) {
    goBack();
  }

  const onSubmit = async ({ refusedComment }) => {
    try {
      await updateOpinion({ status: 'refused', refusedComment });
      setProcessed(true);
      setTimeout(() => {
        goBack();
      }, 1500);
    } catch (err) {
      setProcessed(true);
    }
  };

  return (
    <Dialog
      open
      fullWidth
      onClose={goBack}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        <Grid container justify="center">
          <Grid item>
            <Typography paragraph className={classNames.title}>
              {t('review.check.refuse-title')}
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <Form
        initialValues={{
          refusedComment: opinion.refusedComment,
        }}
        onSubmit={onSubmit}
        render={({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <Grid container justify="flex-start" direction="column">
                <Grid item>
                  <Typography className={classNames.categories}>
                    {t('review.check.refuse-desc')}
                  </Typography>
                </Grid>

                <Grid item>
                  <TextField
                    name="refusedComment"
                    multiline
                    required
                    fullWidth
                    variant="outlined"
                    rowsMax={8}
                    rows={8}
                    placeholder={t('review.placeholderComment')}
                    inputProps={{
                      maxLength: '500',
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item>
                {!loading && processed && !error && (
                  <Alert severity="success">{t('common.form.succeeded')}</Alert>
                )}
                {!loading && processed && error && (
                  <Alert severity="error">{t('common.form.failed')}</Alert>
                )}
              </Grid>
              <Grid
                style={{ marginTop: theme.spacing(2) }}
                container
                justify="center"
                alignItems="center"
                direction="row"
              >
                <Grid>
                  <Button
                    color="primary"
                    onClick={goBack}
                    disabled={processed || loading}
                  >
                    {t('common.cancel')}
                  </Button>
                </Grid>
                <Grid>
                  <Button
                    disabled={processed || loading}
                    color="primary"
                    variant="contained"
                    type="submit"
                  >
                    {t('common.confirm')}
                  </Button>
                </Grid>
              </Grid>
            </DialogContent>
          </form>
        )}
      />
    </Dialog>
  );
};
