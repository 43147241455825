import { makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[2],
  },
  arrow: {
    color: theme.palette.common.white,
  },
  icon: {
    fill: theme.palette.common.white,
  },
}));
