import { CircularProgress, makeStyles, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from '@material-ui/lab';

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  title: string;
  message: string;
  destructive?: boolean;
  loading?: boolean;
  error?: string;
}

const useStyles = makeStyles((theme) => ({
  errorButton: {
    color: theme.palette.error.main,
  },
}));

export const ConfirmDialog: React.FC<IProps> = ({
  isOpen,
  onClose,
  onConfirm,
  title,
  message,
  destructive,
  loading = false,
  error,
}) => {
  const style = useStyles();
  const { t } = useTranslation();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {error && <Alert severity="error">{error}</Alert>}
        <Typography id="alert-dialog-description">{message}</Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary" variant="outlined">
          {destructive ? t('common.confirm') : t('common.cancel')}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          className={style.errorButton}
          autoFocus
        >
          {!loading &&
            (destructive ? t('common.cancel') : t('common.validate'))}
          {loading && <CircularProgress color="primary" />}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
