import React, { createContext } from 'react';

export interface IEnvironmentContext {
  host?: string;
  proHost?: string;
  calendarUrl?: string;
  frontUrl?: string;
  adminHost?: string;
  localhost?: string;
  calendar?: string;
  api?: { [key: string]: string };
}

export const EnvironmentContext = createContext<IEnvironmentContext>({
  host: undefined,
  proHost: undefined,
  calendarUrl: undefined,
  frontUrl: undefined,
  adminHost: undefined,
  localhost: undefined,
  calendar: undefined,
  api: undefined,
});

const EnvironmentProvider: React.FC<IEnvironmentContext> = ({
  children,
  host,
  calendarUrl,
  frontUrl,
  proHost,
  adminHost,
  localhost,
  calendar,
  api,
}) => {
  return (
    <>
      <EnvironmentContext.Provider
        value={{
          host,
          proHost,
          calendarUrl,
          frontUrl,
          adminHost,
          localhost,
          calendar,
          api,
        }}
      >
        {children}
      </EnvironmentContext.Provider>
    </>
  );
};
export { EnvironmentProvider };
