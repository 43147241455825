import { color } from './color';

export const palette = {
  background: {
    default: '#F5F7F7',
    paper: '#F5F7F7',
  },
  primary: {
    main: '#F14A1A',
    dark: '#171A23',
    light: '#D0EAEB',
    contrastText: '#FFFFFF',
  },
  text: {
    primary: '#030A21',
    secondary: '#FFFFFF',
    disabled: 'gray',
    hint: 'blue',
  },
  secondary: {
    main: '#FF9F9F',
    contrastText: '#171A23',
    light: color.grey,
  },
  tertiary: {
    main: '#8BFAB2',
    contrastText: '#171A23',
    dark: '#A6DADA',
    light: '#E8F7F4',
  },
  quaternary: {
    main: '#A3A3F7',
    contrastText: '',
    dark: '',
    light: '',
  },
  quinary: {
    main: '#AFCBFF',
    contrastText: color.primary,
    dark: '#357e6d',
    light: '#EBFDFA',
  },
  error: {
    main: '#DD4B39',
  },
  grey: {
    '100': '#19181F',
    '200': '#FFFFFF0D',
    '300': '#707070',
    '400': '#464F53',
    '500': '#bdbdbd',
    '600': '#E0E1E2',
    '900': '#1d1e2e',
    A100: '#858585',
  },
  common: {
    white: '#ffffff',
    black: '#171A23',
  },
  action: {
    hover: '#FF7373',
  },
};
