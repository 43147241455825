import DateFnsUtils from '@date-io/date-fns';
import CssBaseline from '@material-ui/core/CssBaseline';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { GlobalCss } from 'common/assets';
import {
  AuthRouter,
  SplashScreen,
  PublicPaymentRouter,
  GlobalConfig,
} from 'common/components';
import 'common/langs/i18n';
import fr from 'date-fns/locale/fr';
import { Layout } from 'layouts';
import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import * as Sentry from '@sentry/react';
import { store } from 'sherl-react-sdk';
import './index.css';
import { ThemeProvider } from './common/components/theme-provider/containers/theme-provider.container';

// Sentry.init({
//   dsn:
//     'https://268a6b11f0dd4e7294ff47f5f120bb22@o244426.ingest.sentry.io/5323483',
//   enabled: process.env.NODE_ENV === 'production',
// });

function App() {
  return (
    <Provider store={store}>
      <GlobalConfig>
        <ThemeProvider>
          <CssBaseline />
          <GlobalCss />
          <BrowserRouter>
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={fr}>
              <Switch>
                <Route path="/public">
                  <PublicPaymentRouter />
                </Route>
                <Route path="/">
                  <AuthRouter initialRoute="app/catalog">
                    <SplashScreen>
                      <Layout />
                    </SplashScreen>
                  </AuthRouter>
                </Route>
              </Switch>
            </MuiPickersUtilsProvider>
            <ToastContainer />
          </BrowserRouter>
        </ThemeProvider>
      </GlobalConfig>
    </Provider>
  );
}

export default App;
