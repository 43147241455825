import { Box, Button, Grid, Typography } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { MangoPayCardsSelector } from 'common/components/mango-pay-cards-selector';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MangoPay, Person, Shop } from 'sherl-react-sdk';

interface IProps {
  meanOfPayment?: 'CARD';
  handleAddCard?: () => void;
  handleCancel?: () => void;
  handleSuccess?: () => void;
}

export const ValidateAndPay: React.FC<IProps> = ({
  meanOfPayment = 'CARD',
  handleAddCard,
  handleCancel,
  handleSuccess,
}) => {
  const { t } = useTranslation();
  const [loadingCard, setLoadingCard] = useState<boolean>(false);
  const { me } = Person.usePerson();
  const { setDefaultCard, deleteCard } = MangoPay.useCardActions();
  const { loading, validateAndPay, error } = Shop.useBasket();

  const handleChangeCard = async (value: string) => {
    try {
      setLoadingCard(true);
      await setDefaultCard(value);
    } catch (err) {
      console.log({ err });
    }
    setLoadingCard(false);
  };

  const handleDeleteCard = async (value: string) => {
    return deleteCard(value);
  };

  const handleValidateAndPay = async () => {
    try {
      await validateAndPay(meanOfPayment);
      if (handleSuccess) {
        handleSuccess();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <>
      <Box p={2}>
        <Typography variant="subtitle1">
          {t('validate-and-pay.choose-a-card')}
        </Typography>
        {error && <Alert severity="error">{error.message}</Alert>}
        <MangoPayCardsSelector
          cards={me?.mangopayCards || []}
          value={
            me?.mangopayCards &&
            me?.mangopayCards.find((card) => card.default)?.Id
          }
          onChange={handleChangeCard}
          onDelete={handleDeleteCard}
          loading={loadingCard}
          handleAddCard={handleAddCard}
        />
      </Box>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Button
            fullWidth
            disabled={loading || loadingCard}
            onClick={handleValidateAndPay}
            color="primary"
            variant="contained"
          >
            {t('validate-and-pay.end-and-pay')}
          </Button>
        </Grid>
        {handleCancel && (
          <Grid item xs={12}>
            <Button
              fullWidth
              disabled={loading}
              onClick={handleCancel}
              color="primary"
              variant="outlined"
            >
              {t('common.cancel')}
            </Button>
          </Grid>
        )}
      </Grid>
    </>
  );
};
