import { makeStyles, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IDescriptionBoxTheme } from '../entities/description.box.entity';

export const useStyleTheme = (): any => {
  const theme = useTheme() as ThemeOptions & IDescriptionBoxTheme;
  const useThemeStyle = makeStyles(theme.descriptionBox);
  return useThemeStyle();
};

export const useStyle = makeStyles((theme: ThemeOptions) => ({
  defaultContainer: {},
  defaultTitle: {},
  defaultSubTitle: {},
  defaultText: {},
}));
