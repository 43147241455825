import { Card, Grid, Typography } from '@material-ui/core';
import Equalizer from '@material-ui/icons/Equalizer';
import React from 'react';
import { useStyles } from '../styles/statistics.style';

interface Props {
  count: number;
  title: string;
  description: string;
}

export const StatCard: React.FC<Props> = ({ count, title, description }) => {
  const classNames = useStyles();
  return (
    <Grid item xs={12} md={6}>
      <Card elevation={5} className={classNames.card}>
        <Grid
          container
          direction="row"
          spacing={4}
          className={classNames.cardHeader}
        >
          <Grid xs={12} md={2} item>
            <Equalizer className={classNames.icon} />
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography className={classNames.title}>{title}</Typography>
            <Typography style={{ marginTop: 40 }}>{description}</Typography>
          </Grid>
          <Grid style={{ flex: 1 }} item xs={12} md={4}>
            <Grid
              container
              className={classNames.countWrapper}
              direction="column"
              justify="center"
              alignItems="center"
            >
              <Grid item>
                <Typography className={classNames.count}>{count}</Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};
