import { makeStyles } from '@material-ui/core';

interface IProps {
  loading: boolean;
}

export const useStyles = makeStyles((theme) => ({
  container: {
    position: 'relative',
  },
  overlay: ({ loading }: IProps) => ({
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: loading ? 9999 : -1,
    opacity: loading ? 1 : 0,
    transition: 'opacity 200ms',
    backgroundColor: loading ? 'rgba(0,0,0,.1)' : 'rgba(0,0,0,0)',
  }),
  overlayBox: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));
