import { CircularProgress, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useEffect, useState } from 'react';
import { Field, FormRenderProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Person } from 'sherl-react-sdk';
import { IFormData } from '../entities/user-autocomplete-modal.entity';

export const UserAutocompleteField: React.FC<
  FormRenderProps<IFormData>
> = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState<string>('');
  const { getSearchPersons, persons } = Person.usePersons();

  useEffect(() => {
    getSearchPersons(undefined, undefined, { q: search, type: 'DEFAULT' });
    // eslint-disable-next-line
  }, [search]);

  const loading = open && persons.length === 0;
  return (
    <Field
      name="user"
      render={(fieldProps) => (
        <>
          <Autocomplete
            open={open}
            onOpen={() => {
              setOpen(true);
            }}
            onClose={() => {
              setOpen(false);
            }}
            size="small"
            options={Object.values(persons || {})}
            loading={loading}
            loadingText={
              <Typography>{t('user-autocomplete.loading')}</Typography>
            }
            getOptionSelected={(option, value) => option.id === value.id}
            getOptionLabel={(option) =>
              option.firstName + ' ' + option.lastName + ' ' + option.email
            }
            onChange={(event, newValue) => {
              fieldProps.input.onChange(newValue);
            }}
            inputValue={search}
            onInputChange={(event, newInputValue) => {
              setSearch(newInputValue);
            }}
            noOptionsText={
              <Typography>{t('user-autocomplete.no-options')}</Typography>
            }
            filterOptions={(x) => x}
            renderInput={(params) => (
              <TextField
                {...params}
                label={t('user-autocomplete.choose-a-user')}
                fullWidth
                inputProps={{
                  ...params.inputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                }}
                InputProps={{
                  ...params.InputProps,
                  autoComplete: 'new-password', // disable autocomplete and autofill
                  endAdornment: (
                    <React.Fragment>
                      {loading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
          {fieldProps.meta.error && !fieldProps.meta.pristine && (
            <Typography color="error" variant="caption" paragraph>
              {fieldProps.meta.error}
            </Typography>
          )}
        </>
      )}
    />
  );
};
