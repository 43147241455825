import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidate = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    streetAddress: Yup.string()
      .required()
      .label(t('address-form-simple.form.streetAddress')),
    locality: Yup.string()
      .required()
      .label(t('address-form-simple.form.locality')),
    postalCode: Yup.string()
      .required()
      .label(t('address-form-simple.form.postalCode')),
  });
  const validate = makeValidate(schema);

  return { validate };
};
