import React from 'react';
import { TextField, KeyboardDatePicker } from 'mui-rff';
import { InputAdornment, Grid } from '@material-ui/core';
import { CreditCard, Lock, Person, Today } from '@material-ui/icons';
import { useTranslation } from 'react-i18next';

export const CreditCardFields: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Grid container direction="column" spacing={2}>
      <Grid item xs={12}>
        <TextField
          required
          name="cardNumber"
          label={t('credit-card-fields.card-number')}
          color="primary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CreditCard />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container direction="row" spacing={2}>
          <Grid item xs={12} md={6}>
            <KeyboardDatePicker
              name="expirationDate"
              label={t('credit-card-fields.expiration-date')}
              openTo="year"
              format="MM/yy"
              autoOk
              variant="inline"
              inputVariant="outlined"
              fullWidth
              required
              views={['year', 'month']}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Today />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              required
              name="securityCode"
              label={t('credit-card-fields.security-code')}
              color="primary"
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Lock />
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <TextField
          required
          name="cardName"
          label={t('credit-card-fields.credit-card-holder')}
          color="primary"
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person />
              </InputAdornment>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};
