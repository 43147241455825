import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import InputPrice from 'common/components/input-price/components/input-price.component';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Order, Shop } from 'sherl-react-sdk';
import * as Yup from 'yup';

interface IProps {
  id: string;
}

interface IFormValues {
  amount: number;
}

export const PayoutWithAmountDialog: React.FC<IProps> = ({ id }) => {
  const [open, setOpen] = useState<boolean>(false);
  const { t } = useTranslation();
  const { order } = Order.useOrder(id);
  const { submitPayout } = Shop.useShop();

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const schema = Yup.object<IFormValues>().shape({
    amount: Yup.number()
      .required()
      .min(0)
      .max(order.priceTaxIncluded)
      .label(t('payout-with-amount-dialog.amount-field')),
  });
  const validate = makeValidate(schema);

  const onSubmit = async (values: IFormValues) => {
    if (order.id) {
      try {
        await submitPayout({
          organizationUri: order.organization.uri,
          amount: values.amount,
        });
        toast.success(t('orders-list.bill-sent-success'));
        handleClose();
      } catch (err) {
        return { [FORM_ERROR]: err?.response?.data?.message ?? err?.message };
      }
    }
  };

  return (
    <>
      <Button color="primary" variant="contained" onClick={handleOpen}>
        {t('payout-with-amount-dialog.payout-button')}
      </Button>
      <Form<IFormValues>
        onSubmit={onSubmit}
        validate={validate}
        render={(formProps) => {
          const { handleSubmit, submitError } = formProps;

          return (
            <Dialog open={open} onClose={handleClose}>
              <form onSubmit={handleSubmit}>
                <DialogTitle>
                  {t('payout-with-amount-dialog.dialog-title')}
                </DialogTitle>
                <DialogContent>
                  <Typography paragraph>
                    {t('payout-with-amount-dialog.dialog-help-text')}
                  </Typography>
                  {submitError && (
                    <>
                      <Alert severity="error">{submitError}</Alert>
                      <br />
                    </>
                  )}
                  <TextField
                    name="amount"
                    label={t('payout-with-amount-dialog.amount-field')}
                    fieldProps={{
                      parse: (value) => parseFloat(value),
                    }}
                    InputProps={{
                      inputComponent: InputPrice as React.FC,
                    }}
                    type="text"
                    variant="outlined"
                    size="small"
                    required
                  />
                </DialogContent>
                <DialogActions>
                  <Grid container spacing={2} justify="flex-end">
                    <Grid item>
                      <Button
                        color="primary"
                        variant="outlined"
                        onClick={handleClose}
                      >
                        {t('common.cancel')}
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button type="submit" color="primary" variant="contained">
                        {t('payout-with-amount-dialog.dialog-confirm')}
                      </Button>
                    </Grid>
                  </Grid>
                </DialogActions>
              </form>
            </Dialog>
          );
        }}
      />
    </>
  );
};
