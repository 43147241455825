import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    paddingLeft: theme.aux.spacing.s,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.aux.spacing.s,
  },
  lastName: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.small.fontSize,
  },
  itemContainer: {
    paddingTop: theme.aux.spacing.xs,
    paddingBottom: theme.aux.spacing.xs,
  },
  globalItemStyle: {
    fontSize: theme.typography.small.fontSize,
    fontWeight: theme.typography.fontWeightBold,
    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.extraSmall.fontSize,
    },
  },
  mobileItemLabel: {
    paddingRight: theme.aux.spacing.xs,
  },
  fullWidth: {
    width: '100%',
  },
  dialogContainer: {
    maxWidth: 450,
  },
  dialogTitle: {
    fontSize: theme.typography.h5.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  dialogLabel: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h6.fontSize,
  },
  dialogContent: {
    fontSize: theme.typography.small.fontSize,
  },
  dialogLight: {
    fontWeight: theme.typography.fontWeightLight,
  },
  dialogActionContainer: {
    '&.MuiDialogActions-root': {
      justifyContent: 'center',
    },
  },
}));
