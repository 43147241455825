import { Box, Chip, Grid, IconButton } from '@material-ui/core';
import CreateIcon from '@material-ui/icons/Create';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  ConfirmDialog,
  RequireAccountType,
  useConfirm,
} from 'common/components';
import { stripHtmlTags, truncate } from 'common/helpers';
import { useGridConfiguration } from 'common/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IProduct } from 'sherl-react-sdk';

interface IProps {
  product: IProduct;
  isMobile: boolean;
  goToProductDetails: (productId: string) => void;
  handleProductDelete: (productId: string) => Promise<void>;
}

const DESCRIPTION_MAX_LENGTH = 100;

const ProductsListItemComponent: React.FC<IProps> = (props: IProps) => {
  const { product, goToProductDetails, handleProductDelete } = props;
  const { t } = useTranslation();
  const { columns, actions } = useGridConfiguration<'products'>('products');
  const {
    itemToDelete,
    handleClickOpen,
    handleClose,
    error,
    handleError,
  } = useConfirm();

  const handleDeleteProduct = async () => {
    if (itemToDelete) {
      try {
        await handleProductDelete(itemToDelete);
        handleClose();
      } catch (err) {
        handleError(t('confirm-dialog.error'));
      }
    }
  };

  return (
    <Box borderBottom={1} pb={2} mb={2} width={1}>
      <Grid container spacing={1} alignItems="center">
        {columns.picture.enabled && (
          <Grid item md={columns.picture.size} xs={12}>
            <Box display="flex" alignItems="baseline">
              {product.photos &&
                product.photos.length > 0 &&
                product.photos[0].thumbnail && (
                  <img
                    alt={product.name}
                    src={product.photos[0].thumbnail.caption.contentUrl}
                    style={{ width: 100 }}
                  />
                )}
            </Box>
          </Grid>
        )}
        {columns.name.enabled && (
          <Grid item md={columns.name.size} xs={12}>
            <Box display="flex" alignItems="baseline">
              {product.name}
            </Box>
          </Grid>
        )}
        {columns.description.enabled && (
          <Grid item md={columns.description.size} xs={12}>
            <Box display="flex" alignItems="baseline">
              {product.description &&
                truncate(
                  stripHtmlTags(product.description),
                  DESCRIPTION_MAX_LENGTH,
                )}
            </Box>
          </Grid>
        )}
        {columns.categories.enabled && (
          <Grid item md={columns.categories.size} xs={12}>
            <Box display="flex" alignItems="baseline" flexWrap="wrap">
              {product.categories &&
                product.categories.length > 0 &&
                product.categories.map((category, index) => {
                  if (!category.parent) {
                    return null;
                  }
                  return (
                    <Chip
                      key={index}
                      color="primary"
                      size="small"
                      label={category.name}
                    />
                  );
                })}
            </Box>
          </Grid>
        )}

        <Grid item md={actions.size} xs={12}>
          {actions.detail && (
            <RequireAccountType enabled={true} requiredAccountTypes={['ADMIN']}>
              <Grid container justify="space-around">
                <Grid item>
                  <IconButton
                    aria-label="update"
                    onClick={() => goToProductDetails(product.id)}
                  >
                    <CreateIcon />
                  </IconButton>
                </Grid>
                <Grid item>
                  <IconButton
                    aria-label="delete"
                    onClick={() => handleClickOpen(product.id)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </RequireAccountType>
          )}
        </Grid>
      </Grid>
      <ConfirmDialog
        title={t('confirm-dialog.title')}
        message={t('confirm-dialog.description')}
        isOpen={!!itemToDelete}
        onConfirm={handleDeleteProduct}
        onClose={handleClose}
        error={error}
      />
    </Box>
  );
};

export default ProductsListItemComponent;
