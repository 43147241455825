import React from 'react';
import NumberFormat from 'react-number-format';

interface IProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { value: string } }) => void;
}

const InputPrice: React.FC<IProps> = (props) => {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      allowedDecimalSeparators={[',', '.']}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      isNumericString
      prefix={'€'}
      inputMode="decimal"
    />
  );
};

export default InputPrice;
