import { FORM_ERROR } from 'final-form';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Shop, Config } from 'sherl-react-sdk';
import { CatalogComponent } from '../components/catalog.component';
import { IFormData } from '../components/catalog.form.component';

const HTTP_SUCCESS = 200;
const DEFAULT_MAX_DEPTH = 2;

export const Catalog: React.FC = () => {
  const { t } = useTranslation();
  const {
    addCategory,
    deleteCategory,
    updateCategory,
    getPublicCategories,
  } = Shop.useCategory();
  const categoryMaxDepth =
    Config.ConfigService.get('categoryMaxDepth') || DEFAULT_MAX_DEPTH;

  const addOrUpdateCategory = async (
    values: IFormData,
    parentCategoryId?: string,
    categoryId?: string,
  ): Promise<any> => {
    const { name } = values;
    let response: any;

    if (categoryId) {
      try {
        response = await updateCategory(categoryId, { name });
        getPublicCategories({ depth: categoryMaxDepth });
      } catch (err) {
        return { [FORM_ERROR]: t('common.form.update-failed') };
      }
      if (response?.statusCode !== HTTP_SUCCESS) {
        return { [FORM_ERROR]: t('common.form.update-failed') };
      }
    } else {
      try {
        response = await addCategory({ name }, parentCategoryId);
        getPublicCategories({ depth: categoryMaxDepth });
      } catch (err) {
        return { [FORM_ERROR]: t('common.form.add-failed') };
      }
      if (response?.statusCode !== HTTP_SUCCESS) {
        return { [FORM_ERROR]: t('common.form.add-failed') };
      }
    }

    return {};
  };

  const deleteCat = async (categoryId: string): Promise<any> => {
    let response: any;
    try {
      response = await deleteCategory(categoryId);
      getPublicCategories({ depth: categoryMaxDepth });
    } catch (err) {
      return { [FORM_ERROR]: t('common.form.delete-failed') };
    }
    if (response?.statusCode !== HTTP_SUCCESS) {
      return { [FORM_ERROR]: t('common.form.delete-failed') };
    }

    return {};
  };

  return (
    <CatalogComponent
      deleteCategory={deleteCat}
      addOrUpdateCategory={addOrUpdateCategory}
      categoryMaxDepth={categoryMaxDepth}
    />
  );
};
