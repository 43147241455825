import { makeStyles } from '@material-ui/core/styles';

const IMG_SIZE = 120;

export const useStyles = makeStyles((theme) => ({
  container: {
    maxHeight: '75vh',
    overflowY: 'auto',
  },
  title: {
    paddingLeft: theme.aux.spacing.s,
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  progress: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'rgba(255,255,255,0.75)',
  },
  img: {
    cursor: 'pointer',
    maxWidth: IMG_SIZE,
  },
  imgSelected: {
    boxShadow: theme.shadows[3],
    border: '4px solid',
    borderColor: theme.palette.action.hover,
  },
  categoryTitle: {
    fontWeight: theme.typography.fontWeightExtraBold,
    textTransform: 'uppercase',
    paddingTop: theme.aux.spacing.s,
    paddingBottom: theme.aux.spacing.xs,
  },
  imagesContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
}));
