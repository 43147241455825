import {
  Avatar,
  Box,
  Grid,
  IconButton,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PauseIcon from '@material-ui/icons/Pause';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import clsx from 'clsx';
import { format } from 'common/helpers';
import { useGridConfiguration } from 'common/hooks';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPerson, Person } from 'sherl-react-sdk';
import { useStyles } from '../styles/clients.list.styles';

interface IProps {
  client: IPerson;
  deleteClient: (clientId: string, clientName: string) => void;
  goToClientDetails: (clientId: string) => void;
  isMobile: boolean;
}

export const ClientListItem: React.FC<IProps> = (props) => {
  const { client, deleteClient, goToClientDetails, isMobile } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  const { disablePerson, enablePerson, me } = Person.usePerson();

  const handleEnabledDisabled = async () => {
    try {
      if (client.enabled) {
        await disablePerson(client.id);
      } else {
        await enablePerson(client.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const { columns, actions } = useGridConfiguration<'clients'>('clients');

  return (
    <Grid
      container
      className={clsx(styles.globalItemStyle, styles.itemContainer)}
      spacing={1}
      alignItems="center"
    >
      {columns.firstName.enabled && (
        <Grid item md={columns.firstName.size} xs={12}>
          <Box display="flex" alignItems="center">
            <ListItemAvatar>
              <Avatar
                sizes="small"
                src={
                  client.picture?.caption?.contentUrl
                    ? client.picture.caption.contentUrl
                    : ''
                }
                className={styles.avatar}
              />
            </ListItemAvatar>
            <Typography>
              <span className={styles.globalItemStyle}>{client.firstName}</span>
            </Typography>
          </Box>
        </Grid>
      )}
      {columns.lastName.enabled && (
        <Grid item md={columns.lastName.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            <span className={styles.lastName}>&nbsp;{client.lastName}</span>
          </Box>
        </Grid>
      )}
      {columns.birthDate.enabled && (
        <Grid item md={columns.birthDate.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('common.form.birthDate')}
              </Typography>
            )}
            {client?.birthDate
              ? format(new Date(client.birthDate), 'd MMMM yyyy')
              : ''}
          </Box>
        </Grid>
      )}
      {columns.signupDate.enabled && (
        <Grid item md={columns.signupDate.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('clients-list.subscription')}
              </Typography>
            )}
            {client?.createdAt
              ? format(new Date(client.createdAt), 'EEE d MMMM yyyy')
              : ''}
          </Box>
        </Grid>
      )}
      {columns.lastOrderDate.enabled && (
        <Grid item md={columns.lastOrderDate.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('clients-list.last-reservation')}
              </Typography>
            )}
            {client.statistics?.lastVisit
              ? format(new Date(client.statistics.lastVisit), 'EEE d MMMM yyyy')
              : ''}
          </Box>
        </Grid>
      )}
      {columns.ordersAmount.enabled && (
        <Grid item md={columns.ordersAmount.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('clients-list.reservations')}
              </Typography>
            )}
            {client.statistics ? client.statistics.totalVisit : ''}
          </Box>
        </Grid>
      )}
      {columns.totalMoneySpent.enabled && (
        <Grid item md={columns.totalMoneySpent.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('clients-list.total-spend')}
              </Typography>
            )}
            {client.statistics
              ? `${client.statistics.amountTotalOrder}${t(
                  'common-components.price.currency',
                )}`
              : ''}
          </Box>
        </Grid>
      )}

      <Grid item md={2} xs={12}>
        {me.id !== client.id && (
          <Grid container justify="space-around">
            <>
              {actions.disable && (
                <Grid item xs={4}>
                  <IconButton
                    onClick={() => handleEnabledDisabled()}
                    className={clsx({
                      [styles.activeIcon]: client.enabled,
                    })}
                  >
                    <PauseIcon />
                  </IconButton>
                </Grid>
              )}
              {actions.deletion && (
                <Grid item xs={4}>
                  <IconButton
                    onClick={() =>
                      deleteClient(
                        client.id,
                        `${client.firstName} ${client.lastName}`,
                      )
                    }
                  >
                    <DeleteIcon className={styles.actionsBtn} />
                  </IconButton>
                </Grid>
              )}
            </>
            {actions.detail && (
              <Grid item xs={4}>
                <IconButton onClick={() => goToClientDetails(client.id)}>
                  <RecentActorsIcon className={styles.actionsBtn} />
                </IconButton>
              </Grid>
            )}
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};
