import { createStyles, makeStyles, Theme } from '@material-ui/core';

export const FOOTER_HEIGHT = 50;

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.primary.dark,
      left: 0,
      bottom: 0,
      position: 'fixed',
      minWidth: '100%',
      height: FOOTER_HEIGHT,
      zIndex: 9999,
    },
    copyrights: {
      paddingLeft: theme.spacing(1),
    },
    winzana: {
      paddingRight: theme.spacing(1),
    },
    footerLink: {
      textDecoration: 'none',
      color: 'white',
    },
  }),
);
