import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles/footer.styles';

export const Footer = () => {
  const classNames = useStyles();
  const { t } = useTranslation();
  return (
    <Grid container direction="row" className={classNames.container}>
      <Grid
        container
        item
        xs={6}
        justify="flex-start"
        alignItems="center"
        className={classNames.copyrights}
      >
        <Grid item>
          <Typography variant="overline" color="textSecondary">
            {t('admin-footer.left')}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        container
        item
        xs={6}
        justify="flex-end"
        alignItems="center"
        className={classNames.winzana}
      >
        <Grid item>
          <Typography variant="overline" color="textSecondary">
            {t('admin-footer.right')}{' '}
            <a
              className={classNames.footerLink}
              href="https://www.keekata.com/"
            >
              Keekata
            </a>{' '}
            et{' '}
            <a
              className={classNames.footerLink}
              href="https://www.winzana.com/"
            >
              Winzana
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  );
};
