import { validateEmail } from 'common/helpers';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Auth } from 'sherl-react-sdk';
import { LoginView } from '../components/login.view';

const HTTP_SUCCESS = 200;

export interface IFormData {
  username: string;
  password: string;
}

interface IProps {
  initialRoute: string;
}

export const LoginContainer: React.FC<IProps> = ({ initialRoute }) => {
  const { t } = useTranslation();
  const { push } = useHistory();
  const { signUpWithCredential } = Auth.useLoginAuthentication();
  const token = useSelector(Auth.tokenSelector);
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const queryToken = query.get('token');

  const sendResetPassword = () => {
    return;
  };

  const validate = async (values: IFormData) => {
    const errors: SubmissionErrors = {};

    if (!values.username) {
      errors.username = t('common.form.errors.field-required', {
        field: t('common.form.email'),
      });
    }
    if (!validateEmail(values.username)) {
      errors.username = t('common.form.errors.formatEmailError');
    }
    if (!values.password) {
      errors.password = t('common.form.errors.field-required', {
        field: t('common.form.password'),
      });
    }

    return errors;
  };

  const onSubmit = async (values: IFormData): Promise<any> => {
    const { username, password } = values;
    let response: any;
    try {
      response = await signUpWithCredential(username, password);
    } catch (err) {
      return { [FORM_ERROR]: t('authentication.connection-failed') };
    }

    if (response?.statusCode !== HTTP_SUCCESS) {
      return { [FORM_ERROR]: t('authentication.connection-failed') };
    }

    return {};
  };

  useEffect(() => {
    if (queryToken) {
      localStorage.removeItem('token');
      localStorage.setItem('token', queryToken);
      push(initialRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryToken]);

  useEffect(() => {
    if (token) {
      push(initialRoute);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token, push]);

  return (
    <LoginView
      onSubmit={onSubmit}
      validate={validate}
      sendResetPassword={sendResetPassword}
    />
  );
};
