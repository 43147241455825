import Config from 'config/routes.config';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Auth, Person } from 'sherl-react-sdk';
import { SplashscreenComponent } from '../components/splashscreen.component';

export const SplashscreenContainer: React.FC<{ children: any }> = ({
  children,
}) => {
  const { push } = useHistory();
  const [loading, setLoading] = useState(false);
  const { getMe } = Person.usePerson();
  const token = useSelector(Auth.tokenSelector);

  useEffect(() => {
    getMe({ noBind: 'true' })
      .then(() => {
        setLoading(false);
      })
      .catch(() => {
        localStorage.clear();
        setLoading(false);
        push('/login');
      });
  }, [token]);

  useEffect(() => {
    console.log({ loading });
  }, [loading]);

  if (loading) {
    return <SplashscreenComponent />;
  }
  return children;
};
