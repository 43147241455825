import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { sleep } from 'common/helpers';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Organization } from 'sherl-react-sdk';

export const EstablishmentEnable = () => {
  const { goBack } = useHistory();
  const { params } = useRouteMatch<{ id: string }>();
  const [loading, setLoading] = useState<boolean>(false);
  const { t } = useTranslation();
  const {
    updateOrganization,

    organization,
    selectListItemOrganizationById,
  } = Organization.useOrganization();

  useEffect(() => {
    selectListItemOrganizationById(params.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const disableOrganization = async () => {
    setLoading(true);
    await updateOrganization({
      id: params.id,
      enabled: organization.enabled ? false : true,
    });
    await sleep(1000);
    setLoading(false);

    goBack();
  };

  return (
    <Dialog
      open
      onClose={goBack}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('providers-listing.enable.title')}
      </DialogTitle>
      <DialogContent>
        <DialogContentText color="primary" id="alert-dialog-description">
          {organization &&
            t(
              `providers-listing.enable.desc-${
                organization.enabled ? 'disable' : 'enable'
              }`,
            )}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={goBack} color="primary">
          {t('common.cancel')}
        </Button>
        <Button
          onClick={disableOrganization}
          color="primary"
          variant="outlined"
          autoFocus
        >
          {loading ? <CircularProgress /> : t('common.confirm')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
