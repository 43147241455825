import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  title: {
    textTransform: 'uppercase',
  },
  card: {
    width: '100%',
  },
  button: {
    fontWeight: theme.typography.fontWeightBold,
  },
  eraseButton: {
    backgroundColor: theme.palette.common.white,
  },
  saveButton: {
    color: theme.palette.common.white,
  },
  eraseButtonText: {
    color: theme.palette.common.black,
  },
  media: {
    width: 30,
    height: 30,
    objectFit: 'contain',
  },
}));
