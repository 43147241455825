import { Box, Grid, Typography } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { Alert } from '@material-ui/lab';
import { getErrors } from 'common/helpers';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import { User } from 'sherl-react-sdk';
import { PasswordField } from 'common/components/password-field';

interface IFormData {
  password: string;
  passwordRepeat: string;
}

export const ResetPassword = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { sendPasswordResetValidate } = User.usePasswordResetValidate();
  const { token } = useParams();
  const { push } = useHistory();

  const goBack = (e?: any) => {
    if (e && e.stopPropagation) {
      e.stopPropagation();
    }
    history.goBack();
  };

  const onSubmit = async (values: IFormData) => {
    if (!token) {
      return { [FORM_ERROR]: t('authentication.errors.token-invalid') };
    }
    try {
      await sendPasswordResetValidate(
        values.password,
        values.passwordRepeat,
        token,
      );
    } catch (err) {
      return {
        [FORM_ERROR]: t('authentication.errors.reset-reinitialization-failed'),
      };
    }
    setTimeout(() => {
      push('/login');
    }, 2000);

    return {};
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center">
      <Form
        onSubmit={onSubmit}
        validate={(values) => {
          const errors: SubmissionErrors = {};

          if (!values.password) {
            errors.password = t('authentication.errors.password-required');
          }
          if (!values.passwordRepeat) {
            errors.passwordRepeat = t(
              'authentication.errors.password-repeat-required',
            );
          }
          if (
            values.password &&
            values.passwordRepeat &&
            values.password !== values?.passwordRepeat
          ) {
            errors.passwordRepeat = t('authentication.errors.not-match');
          }

          return errors;
        }}
        render={({
          handleSubmit,
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
          submitting,
          submitSucceeded,
          valid,
        }) => {
          const errorsToShow = getErrors(
            errors,
            submitFailed,
            submitErrors,
            dirtySinceLastSubmit,
          );

          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                justify="center"
                alignItems="center"
                spacing={4}
                item
                style={{ padding: 15 }}
              >
                <Grid item container md={6} sm={12} spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h2">
                      {t('authentication.reset-password.title')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography>
                      {t('authentication.reset-password.description')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Alert severity="info">
                      {t('authentication.signup.password-strength')}
                    </Alert>
                  </Grid>
                  <Grid item xs={12} spacing={4}>
                    {!!errorsToShow[FORM_ERROR] && (
                      <Alert severity="error">{errorsToShow[FORM_ERROR]}</Alert>
                    )}
                    {submitSucceeded && (
                      <Alert severity="success">
                        {t('common.form.succeeded')}
                      </Alert>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <PasswordField variant="filled" fullWidth />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      type="password"
                      label={t('authentication.password-confirmation')}
                      name="passwordRepeat"
                      fullWidth
                      color="primary"
                      variant="filled"
                    />
                  </Grid>
                  <Grid item xs={12} container justify="flex-end">
                    <Button onClick={goBack} color="inherit">
                      {t('common.cancel')}
                    </Button>
                    <Button
                      type="submit"
                      color="primary"
                      disabled={
                        submitting ||
                        !!errorsToShow[FORM_ERROR] ||
                        submitSucceeded ||
                        (!dirtySinceLastSubmit && !valid)
                      }
                    >
                      {t('common.save')}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          );
        }}
      />
    </Box>
  );
};
