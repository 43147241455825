import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Typography,
} from '@material-ui/core';
import { CircularProgressWithLabel, DropzoneForm } from 'common/components';
import { Select } from 'mui-rff';
import React, { useEffect } from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Gallery, Shop } from 'sherl-react-sdk';
import { useStyles } from '../styles/bank.pictures.style';

export interface IFormData {
  picture: File;
  name: string;
  category: string;
}

interface IProps {
  isOpenForm: boolean;
  handleForm: () => void;
  validate: (values: IFormData) => any;
  onClose: () => void;
}

export const BankPictureForm: React.FC<IProps> = (props) => {
  const { isOpenForm, handleForm, validate, onClose } = props;
  const { getList, addItem, addLoading, uploadProgress } = Gallery.useGallery();
  const { getPublicCategories, parentCategories } = Shop.useCategory();
  const styles = useStyles();
  useEffect(() => {
    getPublicCategories();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { t } = useTranslation();

  const submit = async (values: IFormData) => {
    await addItem(values.picture, values.category);
    getList();
    onClose();
  };

  return (
    <Dialog open={isOpenForm} onClose={handleForm}>
      <DialogContent>
        <Form
          validate={validate}
          onSubmit={submit}
          render={({ handleSubmit, valid, submitting }) => {
            return (
              <form onSubmit={handleSubmit}>
                <DialogTitle>
                  {t('bank-pictures.add-picture-title')}
                </DialogTitle>
                <Grid>
                  <Select
                    required
                    name="category"
                    variant="outlined"
                    inputProps={{ size: 'small' }}
                    formControlProps={{ size: 'small' }}
                    label={t('bank-pictures.category')}
                  >
                    {parentCategories.map((item: any) => {
                      return (
                        <MenuItem key={item.id} value={item.uri}>
                          <Typography>{item.name}</Typography>
                        </MenuItem>
                      );
                    })}
                  </Select>
                </Grid>
                <DialogContent>
                  <Field
                    name="picture"
                    required
                    label={t('screen.establishment.image')}
                    render={({ input }) => {
                      return <DropzoneForm input={input} />;
                    }}
                  />

                  {addLoading && (
                    <Box className={styles.progress}>
                      <CircularProgressWithLabel
                        size={60}
                        value={uploadProgress}
                      />
                    </Box>
                  )}
                </DialogContent>
                <DialogActions>
                  <Button
                    disabled={addLoading}
                    onClick={handleForm}
                    color="primary"
                  >
                    {t('common.cancel')}
                  </Button>
                  <Button
                    variant="contained"
                    type="submit"
                    color="primary"
                    disabled={!valid || submitting || addLoading}
                  >
                    {t('common.save')}
                  </Button>
                </DialogActions>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
