import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Fade,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  CardHeader,
  CreditCardMangopayForm,
  ValidateAndPay,
} from 'common/components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Order, OrderStatusEnum, Person } from 'sherl-react-sdk';
import { CB } from '../icons/cb.component';
import { Mastercard } from '../icons/mastercard.component';
import { Mastercard2 } from '../icons/mastercard2.component';
import { Visa } from '../icons/visa.component';
import { useStyles } from '../styles/payment.style';
import { BankTransfer } from './bank-transfer.component';
import { Layout } from './layout.component';

export const AnonymousPayment: React.FC = () => {
  const { token: tokenUrl } = useParams<{ token: string }>();
  const decodedData = window.atob(tokenUrl);
  const { accessToken, orderId } = JSON.parse(decodedData);

  const [tab, setTab] = useState<
    'order' | 'transfer' | 'card' | 'cardForm' | 'confirmation'
  >('order');

  const [loading, setLoading] = useState<boolean>(true);
  const [orderHasBeenFetched, setOrderHasBeenFetched] = useState<boolean>(
    false,
  );

  const styles = useStyles();
  const { t } = useTranslation();

  const { getMe, me } = Person.usePerson();
  const { order, getOrder } = Order.useOrder(orderId);

  useEffect(() => {
    if (accessToken) {
      window.localStorage.setItem('token', accessToken);
      getMe();
    }
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await getOrder();
        setOrderHasBeenFetched(true);
      } catch (err) {
        toast.error(t('common.unknown-error'));
      }
    };
    if (me && !orderHasBeenFetched) {
      fetchData();
    }
  }, [me, orderHasBeenFetched]);

  useEffect(() => {
    if (order) {
      setLoading(false);
    }
  }, [order]);

  const handleShowTransfer = () => setTab('transfer');
  const handleShowOrder = () => setTab('order');
  const handleShowCard = async () => setTab('card');
  const handleShowCardForm = () => setTab('cardForm');
  const handleShowConfirmation = () => setTab('confirmation');

  return (
    <Layout>
      <Card>
        <CardHeader title={t('anonymous-payment.title')} />
        <CardContent>
          {loading && (
            <Box display="flex" width="100%" justifyContent="center">
              <CircularProgress />
            </Box>
          )}
          {!loading &&
            order &&
            order.orderStatus >= OrderStatusEnum.WAITING_VALIDATION && (
              <div>
                <Typography paragraph variant="subtitle1">
                  {order.orderedItems[0].product.name}
                </Typography>
                <Typography paragraph>
                  {order.orderedItems[0].product?.description &&
                    order.orderedItems[0].product.description
                      .split('\n')
                      .map((item, key) => {
                        return (
                          <React.Fragment key={key}>
                            {item}
                            <br />
                          </React.Fragment>
                        );
                      })}
                </Typography>
                <Alert severity="info">
                  <AlertTitle>{t('anonymous-payment.already-paid')}</AlertTitle>
                </Alert>
              </div>
            )}
          {!loading &&
            order &&
            order.orderStatus < OrderStatusEnum.PAYED &&
            tab === 'order' && (
              <Fade in>
                <div>
                  <Typography variant="caption" paragraph>
                    {t('anonymous-payment.subtitle')}
                  </Typography>
                  <Typography paragraph>
                    {order.orderedItems[0].product.name}
                  </Typography>
                  <Typography paragraph>
                    {order.orderedItems[0].product.description}
                  </Typography>
                  <Box
                    pb={2}
                    display="flex"
                    width="100%"
                    justifyContent="center"
                  >
                    <Typography className={styles.bold}>
                      {t('anonymous-payment.price-to-pay')}
                    </Typography>
                    <Typography>
                      {order.priceToPay} : {order.priceTaxIncluded} €
                    </Typography>
                  </Box>
                  <Box
                    pb={2}
                    display="flex"
                    width="100%"
                    justifyContent="center"
                  >
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Button
                          color="primary"
                          variant="contained"
                          onClick={
                            me?.mangopayCards && me?.mangopayCards.length > 0
                              ? handleShowCard
                              : handleShowCardForm
                          }
                          fullWidth
                        >
                          <Grid container spacing={2} alignItems="center">
                            <Grid item xs="auto">
                              <Typography
                                variant="button"
                                className={styles.textButton}
                              >
                                {t('anonymous-payment.pay-by-card')}
                              </Typography>
                            </Grid>
                            <Grid item xs="auto">
                              <Visa />
                            </Grid>
                            <Grid item xs="auto">
                              <Mastercard />
                            </Grid>
                            <Grid item xs="auto">
                              <Mastercard2 />
                            </Grid>
                            <Grid item xs="auto">
                              <CB />
                            </Grid>
                          </Grid>
                        </Button>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box display="flex" width="100%" justifyContent="center">
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={handleShowTransfer}
                      fullWidth
                    >
                      <Typography
                        variant="button"
                        className={styles.textButton}
                      >
                        {t('anonymous-payment.pay-by-transfer')}
                      </Typography>
                    </Button>
                  </Box>
                </div>
              </Fade>
            )}
          {!loading && order && tab === 'cardForm' && (
            <Fade in>
              <div>
                <Typography paragraph>
                  {t('anonymous-payment.card-instruction')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <CreditCardMangopayForm
                      handleCancel={handleShowOrder}
                      handleSuccess={handleShowCard}
                    />
                  </Grid>
                </Grid>
              </div>
            </Fade>
          )}
          {!loading && order && tab === 'card' && (
            <Fade in>
              <div>
                <Typography paragraph>
                  {t('anonymous-payment.card-instruction')}
                </Typography>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <ValidateAndPay
                      handleAddCard={handleShowCardForm}
                      handleSuccess={handleShowConfirmation}
                      handleCancel={handleShowOrder}
                    />
                  </Grid>
                </Grid>
              </div>
            </Fade>
          )}
          {!loading && order && tab === 'confirmation' && (
            <Fade in>
              <div>
                <Typography paragraph>
                  {t('anonymous-payment.confirmation')}
                </Typography>
                <Typography paragraph>
                  {t('anonymous-payment.confirmation-text')}
                </Typography>
              </div>
            </Fade>
          )}

          {!loading &&
            order &&
            tab === 'transfer' &&
            order?.organization?.bankAlias?.iban &&
            order?.organization?.bankAlias?.bic && (
              <Fade in>
                <BankTransfer
                  handleCancel={handleShowOrder}
                  organization={order.organization}
                />
              </Fade>
            )}
        </CardContent>
      </Card>
    </Layout>
  );
};
