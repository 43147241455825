import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { VpnKeySharp } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { Alert } from '@material-ui/lab';
import { ConfirmDialog } from 'common/components';
import { FormApi, SubmissionErrors } from 'final-form';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IPayloadError, IUser } from 'sherl-react-sdk';
import { IFormData } from '../components/administrator.form.component';
import { useStyles } from '../styles/administrators.style';
import { NewAdministratorForm } from './administrator.form.component';
import { AdministratorItem } from './administrator.item.component';

interface IProps {
  administrators: Partial<IUser>[];
  deleteAdminAccount: (customerId: string) => void;
  /**
   * Validate callback
   */
  validate: (values: IFormData) => any;
  onSubmit: (
    values: IFormData,
    form: FormApi<any>,
    callback?: ((errors?: SubmissionErrors | undefined) => void) | undefined,
  ) => any;
  isLoading: boolean;
  error: IPayloadError;
  loadingMore: boolean;
  handleAddAdminDialog: (open?: boolean) => void;
  isOpenAddAdmin: boolean;
  handleNextPage: () => void;
}

export const AdministratorsComponent: React.FC<IProps> = ({
  administrators,
  loadingMore,
  isLoading,
  error,
  deleteAdminAccount,
  onSubmit,
  validate,
  isOpenAddAdmin,
  handleAddAdminDialog,
  handleNextPage,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
  const [administratorToDelete, setAdministratorToDelete] = useState({
    id: '',
    name: '',
  });

  const handleConfirm = (id: string, name: string) => {
    setAdministratorToDelete({ id: id, name: name });
    setIsOpenConfirmDelete(!isOpenConfirmDelete);
  };

  const onConfirmDeleteAdmin = (adminId: string) => {
    deleteAdminAccount(adminId);
    setIsOpenConfirmDelete(false);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="space-between" spacing={2}>
                <Grid item xs={12} md="auto">
                  <Grid container justify="space-between">
                    <Grid item xs={12}>
                      <Box display="flex">
                        <VpnKeySharp color="primary" />
                        <Typography className={styles.title}>
                          {t('administrators-list.administrators-listing')}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Box>
                    <Button
                      fullWidth={isMobile}
                      color="primary"
                      variant="contained"
                      onClick={() => handleAddAdminDialog()}
                    >
                      <AddIcon fontSize="small" />
                      {t('administrators-list.add-admin')}
                    </Button>
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid item xs={12}>
                <Grid container className={styles.header}>
                  <Grid item md={3}>
                    {t('common.name')}
                  </Grid>
                  <Grid item md={3}>
                    {t('administrators-list.account-creation')}
                  </Grid>
                  <Grid item md={2}></Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {administrators &&
                administrators.map((admin) => (
                  <React.Fragment key={admin.id}>
                    <AdministratorItem
                      administrator={admin}
                      isMobile={isMobile}
                      deleteAdmin={handleConfirm}
                    />
                    {isMobile && <Divider light className={styles.fullWidth} />}
                  </React.Fragment>
                ))}
              {!administrators && (
                <Grid item xs={12}>
                  <Box display="flex" justifyContent="center">
                    <Typography>{t('common.no-data-found')}</Typography>
                  </Box>
                </Grid>
              )}
            </Grid>
            {!isLoading && loadingMore && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    size="small"
                    fullWidth={isMobile}
                    color="primary"
                    variant="outlined"
                    onClick={handleNextPage}
                  >
                    {t('orders-list.load-more')}
                  </Button>
                </Box>
              </Grid>
            )}
            <Grid container justify="center">
              {isLoading && !error && (
                <Grid
                  container
                  style={{ padding: theme.aux.spacing.m }}
                  justify="center"
                  alignItems="center"
                >
                  <CircularProgress />
                </Grid>
              )}
              {error && (
                <Grid
                  container
                  style={{ padding: theme.aux.spacing.m }}
                  justify="center"
                  alignItems="center"
                >
                  <Alert severity="error">{error.message}</Alert>
                </Grid>
              )}
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <NewAdministratorForm
        handleAddAdministratorDialog={handleAddAdminDialog}
        isMobile={isMobile}
        isOpen={isOpenAddAdmin}
        onSubmit={onSubmit}
        validate={validate}
      />
      <ConfirmDialog
        message={administratorToDelete.name}
        onConfirm={() => onConfirmDeleteAdmin(administratorToDelete.id)}
        isOpen={isOpenConfirmDelete}
        onClose={() => handleConfirm('', '')}
        title={t('administrators-list.confirm-delete-title')}
      />
    </>
  );
};
