import { format as formatDateFns, parse } from 'date-fns';
import i18n from 'i18next';

/**
 * Format Date object according to current locale
 */
export const format: typeof formatDateFns = (date, formatString, option) => {
  const { language } = i18n;
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const { [language]: locale } = require('date-fns/locale');
  if (date) {
    return formatDateFns(new Date(date), formatString, {
      ...option,
      locale,
    });
  }
  return '';
};

export const formatBirthDate = (dateString: string | undefined): string => {
  return dateString
    ? parse(
        dateString,
        "dd'/'MM'/'yyyy",
        new Date(1990, 0, 0, 0, 0, 0),
      ).toISOString()
    : '';
};
