import { ValidationErrors } from 'final-form';
import { useTranslation } from 'react-i18next';
import { IFormData } from '../entities/password-change-form.entity';

export const useValidate = () => {
  const { t } = useTranslation();

  const validate = (values: IFormData) => {
    const errors: ValidationErrors = {};

    if (!values.oldPassword) {
      errors.oldPassword = t('common.form.errors.field-required', {
        field: t('password-change-form.old-password'),
      });
    }

    if (!values.password) {
      errors.password = t('common.form.errors.field-required', {
        field: t('password-change-form.password'),
      });
    }
    if (!values.passwordRepeat) {
      errors.passwordRepeat = t('common.form.errors.field-required', {
        field: t('password-change-form.password-repeat'),
      });
    }

    if (values.password !== values.passwordRepeat) {
      errors.passwordRepeat = t('common.form.errors.passwordsShouldBeEgal');
    }

    return errors;
  };

  return { validate };
};
