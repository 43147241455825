import { library } from '@fortawesome/fontawesome-svg-core';
import { faBullhorn } from '@fortawesome/free-solid-svg-icons';
import {
  Chat,
  Equalizer,
  MenuBook,
  Message,
  PhotoLibrary,
  PostAdd,
  ReportProblem,
  VpnKeySharp,
} from '@material-ui/icons';
import Config from 'config/routes.config';
import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Redirect,
  Route,
  RouteProps,
  Switch,
  useRouteMatch,
} from 'react-router-dom';
import {
  Account,
  Administrators,
  Advertising,
  BankPictures,
  Catalog,
  ClientFormProfileAddress,
  ClientsRouter,
  Cms,
  OrdersRouter,
  Profile,
  Products,
  ProductDetails,
  Providers,
  ReportsList,
  Reviews,
  Statistics,
} from 'screens';
import { Person } from 'sherl-react-sdk';
import { ErrorFallback } from './error.wrapper';

library.add(faBullhorn);

const routes = [
  {
    path: '/profile',
    component: () => <Profile />,
    subRoutes: undefined,
  },
  {
    path: '/client/:id',
    title: 'reports',
    component: () => <ClientFormProfileAddress />,
    icon: ReportProblem,
  },
  {
    path: '/products/create',
    title: 'products',
    component: () => <ProductDetails />,
    icon: MenuBook,
  },
  {
    path: '/products/:id',
    title: 'products',
    component: () => <ProductDetails />,
    icon: MenuBook,
  },
];

export const menuRoutes = [
  {
    path: '/stats',
    title: 'statistics',
    component: () => <Statistics />,
    icon: Equalizer,
  },
  {
    path: '/account',
    title: 'account',
    component: () => <Account />,
    icon: 'person',
  },
  {
    path: '/clients',
    title: 'clients',
    component: () => <ClientsRouter />,
    icon: 'people',
  },
  {
    path: '/providers',
    title: 'providers',
    component: () => <Providers />,
    icon: 'work_outlined',
  },

  {
    path: '/orders',
    title: 'orders',
    component: () => <OrdersRouter />,
    icon: 'shopping_basket',
  },
  {
    path: '/reports',
    title: 'reports',
    component: () => <ReportsList />,
    icon: ReportProblem,
  },
  {
    path: '/administrators',
    title: 'administrators',
    component: () => <Administrators />,
    icon: VpnKeySharp,
  },
  {
    path: '/reviews',
    title: 'reviews',
    component: () => <Reviews />,
    icon: Chat,
  },
  {
    path: '/catalog',
    title: 'catalog',
    component: () => <Catalog />,
    icon: 'menu-book',
  },
  {
    path: '/communication',
    title: 'communication',
    icon: Message,
    subRoutes: [
      {
        path: '/communication/advertising',
        component: () => <Advertising />,
        title: 'advertising-area',
        icon: Message,
      },
    ],
  },
  {
    path: '/bank-pictures',
    title: 'bank-pictures',
    component: () => <BankPictures />,
    icon: PhotoLibrary,
  },
  {
    path: '/cms',
    title: 'cms',
    component: () => <Cms />,
    icon: PostAdd,
  },
  {
    path: '/products',
    title: 'products',
    component: () => <Products />,
    icon: MenuBook,
  },
];

const GrantedRoute = (props: RouteProps) => {
  const { me } = Person.usePerson();
  const { path } = useRouteMatch();

  const { component, ...rest } = props;
  return (
    <Route
      {...rest}
      render={(routeProps) => {
        // Current path without prefix /app
        const currentPath = routeProps.match.path.replace(path, '');

        // Check if current path match authorized paths
        const matchProfilType = Config[
          me.type as 'ADMIN' | 'FOUNDER' | 'EMPLOYEE' | 'DEFAULT'
        ].includes(currentPath as never);

        if (matchProfilType) {
          return component ? (component as () => JSX.Element)() : null;
        } else {
          return <Redirect to={`${path}${Config.initialPath}`} />;
        }
      }}
    />
  );
};

export const AppRouter: React.FC = () => {
  const { path } = useRouteMatch();
  return (
    <ErrorBoundary FallbackComponent={ErrorFallback}>
      <Switch>
        {[...routes, ...menuRoutes].map((route, index) => (
          <GrantedRoute
            exact={index === 0}
            path={`${path}${route.path}`}
            key={index}
            component={route.component}
          >
            {route.subRoutes &&
              route.subRoutes.map((subRoute, j) => (
                <GrantedRoute
                  key={`${index}${j}`}
                  path={`${path}${subRoute.path}`}
                  component={subRoute.component}
                />
              ))}
          </GrantedRoute>
        ))}

        <Redirect to={`${path}${Config.initialPath}`} />
      </Switch>
    </ErrorBoundary>
  );
};

export default AppRouter;
