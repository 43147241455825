import { Theme } from '@material-ui/core';
import { IFooterStyle } from 'common/components';

export const footer = (theme: Theme): IFooterStyle => ({
  footerCategory: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBlack,
    paddingLeft: theme.aux.spacing.m,
  },
});
