import {
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid,
  GridSize,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { AddCircleOutline } from '@material-ui/icons';
import { ConfirmDialog } from 'common/components';
import React, { useEffect, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Shop } from 'sherl-react-sdk';
import { useStyles } from '../styles/catalog.styles';
import { CatalogCategoryItemComponent } from './catalog.category.item.component';
import { CategoryForm, IFormData } from './catalog.form.component';

interface IProps {
  deleteCategory: (categoriId: string) => void;
  addOrUpdateCategory: (
    values: IFormData,
    parentCategoryId?: string,
    categoryId?: string,
  ) => any;
  categoryMaxDepth: number;
}

export const CatalogComponent: React.FC<IProps> = (props) => {
  const { addOrUpdateCategory, deleteCategory, categoryMaxDepth } = props;
  const styles = useStyles();
  const { t } = useTranslation();
  const {
    parentCategories: categories,
    getPublicCategories,
    categories: allCategories,
  } = Shop.useCategory();
  const [currentCategoryId, setCurrentCategoryId] = useState<string>();
  const [openCategoriesIds, setOpenCategoriesIds] = useState<string[]>([]);
  const [openForm, setOpenForm] = useState<string>('');
  const [categoryToDelete, setCategoryToDelete] = useState<{
    id?: string;
    name?: string;
  }>();

  const handleOpenForm = async (
    categoryId: string,
    parentCategory?: Shop.ICategoryNested,
  ) => {
    if (
      categoryId === 'add' &&
      parentCategory &&
      currentCategoryId !== parentCategory.id
    ) {
      // Update current category id to add child element in right level
      setCurrentCategoryId(parentCategory.id);
    }
    setOpenForm(categoryId);
  };
  const handleCloseForm = async () => {
    setOpenForm('');
  };

  const handleConfirmDialog = (categoryId: string, name: string) => {
    setCategoryToDelete({ id: categoryId, name });
  };
  const handleCancelConfirm = () => {
    setCategoryToDelete({ id: '', name: '' });
  };

  const deleteCategoryById = (id: string) => {
    deleteCategory(id);
    setCategoryToDelete({ id: '', name: '' });
  };

  const isCategorySelected = (categoryItemId: string): boolean => {
    if (currentCategoryId && currentCategoryId === categoryItemId) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    getPublicCategories({ depth: categoryMaxDepth });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const addOpenCategoryId = (categoryId: string, level: number) => {
    if (level >= categoryMaxDepth) {
      return;
    }
    const newOpenCategoriesIds: string[] = [...openCategoriesIds];
    if (newOpenCategoriesIds.length > 0) {
      newOpenCategoriesIds.splice(level, newOpenCategoriesIds.length - level);
    }
    newOpenCategoriesIds.push(categoryId);
    setOpenCategoriesIds(newOpenCategoriesIds);
  };

  const openCategories = useMemo(() => {
    return openCategoriesIds
      .map((categoryId) => allCategories[categoryId])
      .filter((category) => !!category);
  }, [openCategoriesIds, allCategories]);

  const mdGridSize = (12 / (openCategories.length + 1)) as GridSize;

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={mdGridSize}>
        <Card className={styles.categoriesContainer}>
          <CardHeader
            titleTypographyProps={{ variant: 'h5', className: styles.title }}
            title={t('catalog.categories')}
          />
          <CardContent>
            <Grid container spacing={2} justify="center">
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <List className={styles.listContainer}>
                    {Object.values(categories).map((item) => (
                      <ListItem
                        selected={isCategorySelected(item.id)}
                        key={item.id}
                        onClick={() => {
                          addOpenCategoryId(item.id, 0);
                          setCurrentCategoryId(item.id);
                        }}
                        className={styles.itemContainer}
                      >
                        <CatalogCategoryItemComponent
                          categoryId={item.id}
                          categoryName={item.name}
                          deleteCategory={handleConfirmDialog}
                          handleOpenForm={() => handleOpenForm(item.id)}
                        />
                      </ListItem>
                    ))}
                  </List>
                </Grid>
              </Grid>
              <Grid item>
                <Button onClick={() => handleOpenForm('addParentCategory')}>
                  <AddCircleOutline color="primary" fontSize="small" />
                  <Typography className={styles.addBtn}>
                    {t('catalog.add-category')}
                  </Typography>
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
      {openCategories.length > 0 &&
        openCategories.map((category, index) => (
          <Grid item xs={12} md={mdGridSize}>
            <Card>
              <CardHeader
                title={t('catalog.sub-categories')}
                subheader={category.name}
                titleTypographyProps={{
                  variant: 'h5',
                  className: styles.title,
                }}
              />
              <CardContent>
                <Grid container spacing={2} justify="center">
                  <Grid item xs={12}>
                    <List className={styles.listContainer}>
                      {category.subCategories &&
                        Object.values(category.subCategories).length > 0 &&
                        Object.values(category.subCategories).map((item) => (
                          <ListItem
                            key={item.id}
                            className={styles.itemContainer}
                            selected={isCategorySelected(item.id)}
                            onClick={() => {
                              addOpenCategoryId(item.id, index + 1);
                              setCurrentCategoryId(item.id);
                            }}
                          >
                            <CatalogCategoryItemComponent
                              categoryId={item.id}
                              categoryName={item.name}
                              deleteCategory={handleConfirmDialog}
                              handleOpenForm={() => handleOpenForm(item.id)}
                              isSubCategory
                            />
                          </ListItem>
                        ))}
                    </List>
                  </Grid>
                  <Grid item>
                    <Button
                      onClick={() => handleOpenForm('add', category)}
                      disabled={!currentCategoryId}
                    >
                      <AddCircleOutline color="primary" fontSize="small" />
                      <Typography className={styles.addBtn}>
                        {t('catalog.add-sub-category')}
                      </Typography>
                    </Button>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        ))}
      <CategoryForm
        addOrUpdateCategory={addOrUpdateCategory}
        onClose={handleCloseForm}
        isOpen={openForm}
        parentCategoryId={
          openForm === 'addParentCategory' ? undefined : currentCategoryId
        }
      />
      <ConfirmDialog
        isOpen={!!categoryToDelete?.id}
        message={categoryToDelete?.name ? categoryToDelete.name : ''}
        title={t('catalog.confirm-delete-title')}
        onConfirm={() => deleteCategoryById(categoryToDelete?.id ?? '')}
        onClose={() => handleCancelConfirm()}
      />
    </Grid>
  );
};
