import {
  Avatar,
  Box,
  Grid,
  ListItemAvatar,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import PauseIcon from '@material-ui/icons/Pause';
import clsx from 'clsx';
import { format } from 'common/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IPerson, Person } from 'sherl-react-sdk';
import { useStyles } from '../styles/administrators.style';

interface IProps {
  administrator: Partial<IPerson>;
  isMobile: boolean;
  deleteAdmin: (administratorId: string, name: string) => void;
}

export const AdministratorItem: React.FC<IProps> = (props) => {
  const { administrator, deleteAdmin, isMobile } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const { disablePerson, enablePerson } = Person.usePerson();

  const handleAdminEnabled = async () => {
    if (administrator.enabled) {
      await disablePerson(administrator?.id || '');
    } else {
      await enablePerson(administrator?.id || '');
    }
  };

  return (
    <Grid
      container
      className={clsx(styles.globalItemStyle, styles.itemContainer)}
      spacing={1}
      alignItems="center"
    >
      <Grid item md={3} xs={12}>
        <Box display="flex" alignItems="center">
          <ListItemAvatar>
            <Avatar
              sizes="small"
              src={administrator?.picture ? administrator?.picture?.uri : ''}
              className={styles.avatar}
            />
          </ListItemAvatar>
          <Typography className={styles.globalItemStyle}>
            {administrator?.firstName}
          </Typography>
          <Typography className={styles.lastName}>
            &nbsp;{`${administrator?.lastName}`}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={3} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('administrators-list.account-creation')}
            </Typography>
          )}
          {administrator.createdAt
            ? format(new Date(administrator.createdAt), 'EEE d MMMM yyyy')
            : ''}
        </Box>
      </Grid>

      <Grid item md={2} xs={12}>
        <Grid container justify="space-around">
          <Grid item onClick={() => handleAdminEnabled()} xs={4}>
            <PauseIcon
              className={styles.actionsBtn}
              color={administrator.enabled ? 'primary' : 'disabled'}
            />
          </Grid>
          <Grid
            item
            onClick={() =>
              deleteAdmin(
                administrator?.id || '',
                `${administrator?.firstName} ${administrator?.lastName}`,
              )
            }
            xs={4}
          >
            <DeleteIcon className={styles.actionsBtn} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
