import { Theme } from '@material-ui/core';
import { IPresentationBoxStyle } from 'common/components';

export const presentationBox = (theme: Theme): IPresentationBoxStyle => ({
  container: {
    boxShadow: theme.shadows[4],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
    position: 'relative',
    height: '100%',
  },
  filter: {
    backgroundColor: 'rgba(0,0,0,0.3)',
    width: '100%',
    height: '100%',
    padding: theme.aux.spacing.s,
  },
  title: {
    fontWeight: theme.typography.fontWeightBlack,
    color: theme.palette.common.white,
    fontSize: theme.typography.subtitle1.fontSize,
    letterSpacing: theme.aux.spacing.xs,
    textTransform: 'uppercase',
    paddingBottom: theme.aux.spacing.xs,
  },
  subTitle: {
    fontWeight: theme.typography.fontWeightBold,
    color: theme.palette.common.white,
    letterSpacing: '4px',
    textTransform: 'uppercase',
    paddingBottom: theme.aux.spacing.s,
  },
  organizationContainer: {},
  avatar: {
    minHeight: '67px',
    minWidth: '67px',
    boxShadow: theme.shadows[2],
  },
  organizationDescription: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBlack,
    fontSize: theme.typography.small.fontSize,
    paddingTop: theme.aux.spacing.xs,
  },
  buttonText: {
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
});
