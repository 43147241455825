import {
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { PeopleAlt } from '@material-ui/icons';
import Pause from '@material-ui/icons/Pause';
import clsx from 'clsx';
import { format } from 'common/helpers';
import React, { memo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { Auth, IOrganization } from 'sherl-react-sdk';
import { useStyle } from '../styles/establishment.list.item.style';

interface IProps {
  establishment: IOrganization;
}

const EstablishmentListItem: React.FC<IProps> = memo(({ establishment }) => {
  const classNames = useStyle();
  const { t } = useTranslation();
  const location = useLocation();
  const { impersonate, impersonateToken } = Auth.useImpersonate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  useEffect(() => {
    if (impersonateToken) {
      window.location.href = `/app/clients`;
    }
  }, [impersonateToken]);

  return (
    <Grid
      container
      className={clsx(classNames.globalItemStyle, classNames.itemContainer)}
      spacing={1}
      alignItems="center"
    >
      <Grid item md={3} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('common.form.firstName')}
            </Typography>
          )}
          {establishment.founders && establishment.founders[0]?.firstName}
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('common.form.lastName')}
            </Typography>
          )}
          {establishment.founders && establishment.founders[0]?.lastName}
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('common.form.birthDate')}
            </Typography>
          )}
          {establishment.founders &&
            establishment.founders[0]?.birthDate &&
            format(
              new Date(establishment.founders[0]?.birthDate),
              'd MMM yyyy',
            )}
        </Box>
      </Grid>
      <Grid item md={3} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('common.form.legalName')}
            </Typography>
          )}
          {establishment.legalName}
        </Box>
      </Grid>
      {/* <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('providers-listing.profits')}
            </Typography>
          )}
          {establishment.statistics?.subscription?.name
            ? establishment.statistics.subscription.name
            : t('common.free-subscription')}
        </Box>
      </Grid> */}
      {/* <Grid item md={1} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('providers-listing.package')}
            </Typography>
          )}
          {establishment.statistics?.subscription?.frequency
            ? paimentFrequency(establishment.statistics.subscription.frequency)
            : t('-')}
        </Box>
      </Grid> */}
      {/* <Grid item md={1} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('providers-listing.payment-terms')}
            </Typography>
          )}
          {establishment.statistics?.subscription?.createdAt
            ? format(
                new Date(establishment.statistics.subscription.createdAt),
                'd MMM yyyy',
              )
            : '-'}
        </Box>
      </Grid> */}
      <Grid item md={2} xs={12}>
        <Grid container className={classNames.iconsPosition}>
          <Grid item>
            <Link
              key={establishment.id}
              to={{
                pathname: `${location.pathname}/${establishment.id}/disable`,
                state: { background: location },
              }}
            >
              <Tooltip
                title={
                  establishment.enabled
                    ? (t('providers-listing.disable-action') as string)
                    : (t('providers-listing.enable-action') as string)
                }
              >
                <IconButton
                  className={clsx({
                    [classNames.activeIcon]: establishment.enabled,
                  })}
                >
                  <Pause />
                </IconButton>
              </Tooltip>
            </Link>
          </Grid>
          <Grid item>
            {establishment &&
              establishment?.founders &&
              establishment.founders.length > 0 && (
                <Tooltip title={t('providers-listing.impersonate') as string}>
                  <IconButton
                    onClick={() => {
                      impersonate(
                        establishment?.founders &&
                          establishment.founders[0]?.id,
                      );
                    }}
                  >
                    <PeopleAlt color="primary" />
                  </IconButton>
                </Tooltip>
              )}
          </Grid>
          {/* <Grid item>
            {establishment?.founders && establishment?.founders.length > 0 && (
              <Tooltip
                title={
                  establishment.isPublic
                    ? t('providers-listing.is-public')
                    : t('providers-listing.no-public')
                }
              >
                <IconButton onClick={handleUpdatePublic}>
                  <Public
                    color="primary"
                    className={clsx({
                      [classNames.activePublic]:
                        establishment.isPublic === false,
                    })}
                  />
                </IconButton>
              </Tooltip>
            )}
          </Grid> */}
        </Grid>
      </Grid>
    </Grid>
  );
});

export default EstablishmentListItem;
