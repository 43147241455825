import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Grid,
} from '@material-ui/core';
import { CardHeader, EditorComponent as Editor } from 'common/components';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Field, Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { CMS } from 'sherl-react-sdk';
import { IFormData } from '../entities/cms.entity';

interface IProps {
  id?: string;
}

export const CmsCard: React.FC<IProps> = ({ id }) => {
  const { t } = useTranslation();

  const { updatePost: updatePage, post: page } = CMS.usePost(id || '');

  const handleSubmit = async (values: IFormData) => {
    try {
      await updatePage(values);
    } catch (err) {
      return { [FORM_ERROR]: err.message };
    }
    return {};
  };

  return (
    <Form
      onSubmit={handleSubmit}
      initialValues={{
        title: page?.title,
        resume: '',
        content: page?.content,
      }}
      subscription={{ values: true }}
      render={({ handleSubmit, values }) => {
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader title={t('cms-card.title')} />
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="title"
                      size="small"
                      variant="outlined"
                      label={t('cms-card.form.title')}
                    />
                  </Grid>
                  {/* <Grid item xs={12}>
                    <TextField
                      name="resume"
                      size="small"
                      variant="outlined"
                      label={t('cms-card.form.resume')}
                    />
                  </Grid> */}
                  <Grid item xs={12}>
                    <Field
                      name="content"
                      render={(inputProps) => (
                        <Editor
                          value={inputProps.input.value}
                          onChange={(value) => inputProps.input.onChange(value)}
                        />
                      )}
                    />
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box display="flex" width="100%" justifyContent="flex-end">
                  <Button color="primary" variant="contained" type="submit">
                    {t('common.save')}
                  </Button>
                </Box>
              </CardActions>
            </Card>
          </form>
        );
      }}
    />
  );
};
