import {
  Box,
  Button,
  Card,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { BirthdateDatePicker } from 'common/components/birthdate-datepicker';
import { validateEmail } from 'common/helpers';
import { format } from 'date-fns';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Person } from 'sherl-react-sdk';

interface FormValues {
  firstName: string;
  lastName: string;
  birthDate: string | Date;
  email: string;
}

export const AccountFounder = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { me, updateUser } = Person.usePerson();

  const onSubmit = async (values: FormValues) => {
    try {
      await updateUser(me.id, {
        id: me.id,
        firstName: values.firstName,
        lastName: values.lastName,
        birthDate:
          typeof values.birthDate === 'string'
            ? format(new Date(values.birthDate), 'dd/MM/yyyy')
            : format(values.birthDate, 'dd/MM/yyyy'),
        email: values.email,
      });
      toast.success(t('common.form.succeeded'));
      return {};
    } catch (err) {
      toast.error(t('common.form.failed'));
      return { [FORM_ERROR]: t('common.form.error') };
    }
  };

  return (
    <Form<FormValues>
      onSubmit={onSubmit}
      initialValues={{
        firstName: me.firstName,
        lastName: me.lastName,
        birthDate: me.birthDate,
        email: me.email,
      }}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Card style={{ padding: theme.aux.spacing.s }}>
            <Grid container spacing={3}>
              <Grid container style={{ margin: theme.aux.spacing.s }}>
                <Grid item xs={12}>
                  <Typography variant="h4">{t('account.title')}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant="caption">
                    {t('account.description')}
                  </Typography>
                </Grid>
              </Grid>

              <Grid item xs={12}>
                <TextField
                  required
                  name="lastName"
                  label={t('common.form.lastName')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  name="firstName"
                  label={t('common.form.firstName')}
                />
              </Grid>
              <Grid item xs={12}>
                <BirthdateDatePicker name="birthDate" />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label={t('common.form.email')}
                  name="email"
                  fieldProps={{
                    validate: (value) => {
                      return value && validateEmail(value)
                        ? undefined
                        : t('common.form.errors.formatEmailError');
                    },
                  }}
                />
              </Grid>
            </Grid>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="flex-end"
              style={{ marginTop: theme.aux.spacing.sm }}
            >
              <Button type="submit" variant="contained" color="primary">
                {t('common.save')}
              </Button>
            </Box>
          </Card>
        </form>
      )}
    />
  );
};
