import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import { ConfirmDialog } from 'common/components';
import { useGridConfiguration } from 'common/hooks';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useHistory, useLocation, useRouteMatch } from 'react-router-dom';
import { Person } from 'sherl-react-sdk';
import { ClientListItem } from '../components/client.list.item.component';
import { useStyles } from '../styles/clients.list.styles';

const ITEMS_PER_PAGE = 10;

export interface IFormData {
  firstName: string;
  lastName: string;
  birthDate: Date;
  email: string;
  enabled?: boolean;
}

export const ClientList: React.FC = () => {
  const styles = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const location = useLocation();
  const { push } = useHistory();
  const { path } = useRouteMatch();

  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [page, setPage] = useState<number>(1);
  const [search, setSearch] = useState<string>();
  const getNextPage = () => {
    setPage(page + 1);
  };

  const {
    clients,
    getPersons,
    total,
    getSearchPersons,
    loading,
  } = Person.usePersons();

  const { deletePerson } = Person.usePerson();
  const { location: historyLocation } = useHistory();
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  const [clientToDelete, setClientToDelete] = useState({ id: '', name: '' });

  const deleteAccount = async (clientId: string) => {
    await deletePerson(clientId);

    setClientToDelete(undefined as any);
    setIsOpenConfirm(false);
  };

  const onSearchSubmit = debounce((event, value) => {
    if (page !== 1) {
      push(`${path}?page=${1}`);
    }
    setPage(1);
    setSearch(value);
  }, 300);

  const handleConfirm = (clientId: string, clientName: string) => {
    setClientToDelete({ id: clientId, name: clientName });
    setIsOpenConfirm(!isOpenConfirm);
  };

  const goToClientDetails = (clientId: string) => {
    push(`/app/client/${clientId}`);
  };

  useEffect(() => {
    if (!!search) {
      getSearchPersons(page, ITEMS_PER_PAGE, {
        analytics: 1,
        q: search,
        type: 'DEFAULT',
        'sort[field]': 'createdAt',
        'sort[order]': 'asc',
      });
    } else {
      getPersons(page, ITEMS_PER_PAGE, {
        analytics: 1,
        type: 'DEFAULT',
        'sort[field]': 'createdAt',
        'sort[order]': 'asc',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, historyLocation.pathname, search]);

  const { columns } = useGridConfiguration<'clients'>('clients');
  return (
    <Card style={{ padding: theme.aux?.spacing?.s }}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item xs={12} md="auto">
                <Grid
                  container
                  alignItems="center"
                  justify="flex-start"
                  spacing={1}
                >
                  <Grid item xs={12}>
                    <Typography variant="h4" className={styles.title}>
                      {t('clients-list.title')}
                    </Typography>
                    <Typography variant="caption">
                      {t('clients-list.title-caption')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    md="auto"
                    className={styles.searchPosition}
                  >
                    <TextField
                      fullWidth={isMobile}
                      name="search"
                      variant="standard"
                      className={styles.search}
                      placeholder={t('received-rates.search')}
                      onChange={(values) =>
                        onSearchSubmit(values, values.target.value)
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search />
                          </InputAdornment>
                        ),
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12} md="auto">
                <Link
                  style={{ textDecoration: 'none' }}
                  to={{
                    pathname: `${location.pathname}/add`,
                    state: { background: location },
                  }}
                >
                  <Button
                    fullWidth={isMobile}
                    color="primary"
                    variant="contained"
                  >
                    <AddIcon fontSize="small" />
                    {t('clients-list.add-client')}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={12}>
              <Grid container className={styles.header}>
                {columns.firstName.enabled && (
                  <Grid item md={columns.firstName.size} xs={12}>
                    {t('common.form.firstName')}
                  </Grid>
                )}
                {columns.lastName.enabled && (
                  <Grid item md={columns.lastName.size} xs={12}>
                    {t('common.form.lastName')}
                  </Grid>
                )}
                {columns.birthDate.enabled && (
                  <Grid
                    item
                    className={styles.noWrap}
                    md={columns.birthDate.size}
                    xs={12}
                  >
                    {t('common.form.birthDate')}
                  </Grid>
                )}
                {columns.signupDate.enabled && (
                  <Grid item md={columns.signupDate.size} xs={12}>
                    {t('clients-list.subscription')}
                  </Grid>
                )}
                {columns.lastOrderDate.enabled && (
                  <Grid item md={columns.lastOrderDate.size} xs={12}>
                    {t('clients-list.last-reservation')}
                  </Grid>
                )}
                {columns.ordersAmount.enabled && (
                  <Grid item md={columns.ordersAmount.size} xs={12}>
                    {t('clients-list.reservations')}
                  </Grid>
                )}
                {columns.totalMoneySpent.enabled && (
                  <Grid
                    item
                    className={styles.noWrap}
                    md={columns.totalMoneySpent.size}
                    xs={12}
                  >
                    {t('clients-list.total-spend')}
                  </Grid>
                )}
                <Grid item md={2}></Grid>
              </Grid>
            </Grid>
          )}
          {!(loading['list'] && page === 1) &&
            clients &&
            clients.map((client) => (
              <React.Fragment key={client.id}>
                <ClientListItem
                  client={client}
                  isMobile={isMobile}
                  deleteClient={handleConfirm}
                  goToClientDetails={goToClientDetails}
                />
                {isMobile && <Divider light className={styles.fullWidth} />}
              </React.Fragment>
            ))}
          {clients.length !== total && !loading['list'] && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  size="small"
                  fullWidth={isMobile}
                  color="primary"
                  variant="outlined"
                  onClick={getNextPage}
                >
                  {t('orders-list.load-more')}
                </Button>
              </Box>
            </Grid>
          )}
          {loading['list'] && (
            <Grid
              container
              style={{ padding: theme.aux.spacing.m }}
              justify="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
          {(!clients || clients.length < 1) && !loading['list'] && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Typography>{t('common.no-data-found')}</Typography>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>

      <ConfirmDialog
        isOpen={isOpenConfirm}
        onClose={() => handleConfirm('', '')}
        onConfirm={() => deleteAccount(clientToDelete?.id)}
        title={t('clients-list.confirm-delete-title')}
        message={clientToDelete?.name}
      />
    </Card>
  );
};
