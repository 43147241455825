import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidate = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    firstName: Yup.string().required().label(t('profile-form.firstname')),
    lastName: Yup.string().required().label(t('profile-form.lastname')),
    email: Yup.string().required().email().label(t('profile-form.email')),
  });
  const validate = makeValidate(schema);

  return { validate };
};
