import { Box, Button, CircularProgress, Grid } from '@material-ui/core';
import { sleep } from 'common/helpers';
import { format } from 'date-fns';
import { FORM_ERROR } from 'final-form';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { MangoPay, Person } from 'sherl-react-sdk';
import { CreditCardFields } from '../components/credit-card-fields.component';

interface IFormData {
  cardNumber: string;
  expirationDate: Date;
  securityCode: string;
  cardName: string;
}

interface IProps {
  handleCancel?: () => void;
  handleSuccess?: () => void;
}

export const CreditCardMangopayForm: React.FC<IProps> = ({
  handleCancel,
  handleSuccess,
}) => {
  const { t } = useTranslation();
  const { me, getMe } = Person.usePerson();

  const [loading, setLoading] = useState<boolean>(false);

  const {
    mangoPay,
    requestCredentials,
    loading: loadingCredentials,
  } = MangoPay.usePayment();
  const { addCard, loading: loadingAddCard } = MangoPay.useCardRegistration();

  const registration = () => {
    const { firstName, lastName, birthDate, email } = me;

    // - User has not wallet yet
    // - Create wallet then refresh userMe and redirect to creditCard Creation
    requestCredentials(firstName, lastName, birthDate, email, async () => {
      // - Wait before refreshing user
      sleep(2000).then(async () => {
        await getMe();
      });
    });
  };

  useEffect(() => {
    if (!mangoPay) {
      setLoading(true);
      registration();
    }
  }, [mangoPay]);

  const saveCard = async (values: IFormData) => {
    const errors: { [key: string]: string } = {};
    if (values) {
      try {
        await addCard(
          values.securityCode,
          format(values.expirationDate, 'MMyy'),
          values.cardNumber.replace(/ /g, ''),
        );

        await sleep(1000);
        await getMe();

        if (handleSuccess) {
          setTimeout(() => {
            handleSuccess();
          }, 50);
        }
      } catch (err) {
        errors[FORM_ERROR] = err;
      }
    } else {
      errors[FORM_ERROR] = 'invalid_card';
    }
    return errors;
  };

  return (
    <Form
      onSubmit={saveCard}
      render={({ handleSubmit }) => {
        return (
          <form onSubmit={handleSubmit}>
            {loadingCredentials && (
              <Box display="flex" width="100%" justifyContent="center" p={5}>
                <CircularProgress />
              </Box>
            )}
            {!loadingCredentials && <CreditCardFields />}
            <Grid container spacing={2} justify="flex-end">
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleCancel}
                >
                  {t('common.cancel')}
                </Button>
              </Grid>
              <Grid item>
                <Button color="primary" variant="contained" type="submit">
                  {t('common.save')}
                </Button>
              </Grid>
            </Grid>
          </form>
        );
      }}
    />
  );
};
