import { Button, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import clsx from 'clsx';
import { getErrors, validateEmail } from 'common/helpers';
import { FORM_ERROR, FormApi, SubmissionErrors } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IFormData } from '../containers/login.container';
import { useStyles } from '../styles/login.style';

interface IProps {
  /**
   * Reset password
   * @memberof IProps
   */
  sendResetPassword: () => void;

  onSubmit: (
    values: IFormData,
    form: FormApi<any>,
    callback?: ((errors?: SubmissionErrors | undefined) => void) | undefined,
  ) => any;
  /**
   * Validate callback
   */
  validate: (values: IFormData) => any;
}

export const LoginView: React.FC<IProps> = ({ onSubmit, validate }) => {
  const style = useStyles();
  const { t } = useTranslation();
  const location = useLocation();

  return (
    <Grid item xs={10} md={4} lg={3}>
      <Grid container direction="column" justify="center">
        <Grid item xs={12}>
          <h1 className={style.title}>{t('common.signIn').toUpperCase()}</h1>
        </Grid>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({
            values,
            errors,
            submitFailed,
            submitErrors,
            handleSubmit,
            dirtySinceLastSubmit,
          }) => {
            const errorsToShow = getErrors(
              errors,
              submitFailed,
              submitErrors,
              dirtySinceLastSubmit,
            );

            return (
              <form onSubmit={handleSubmit}>
                <Grid item className={style.formGroup}>
                  <TextField
                    fieldProps={{
                      validate: (value) => {
                        if (!validateEmail(value)) {
                          return t('authentication.errors.email-not-valid');
                        }
                      },
                    }}
                    name="username"
                    fullWidth
                    label={t('common.form.email')}
                    variant="outlined"
                    className={style.inputLogin}
                    id="login"
                    InputProps={{
                      classes: {
                        input: style.inputText,
                      },
                    }}
                    type="email"
                  />
                </Grid>

                <Grid item className={style.formGroup}>
                  <TextField
                    name="password"
                    fullWidth
                    label={t('common.form.password')}
                    variant="outlined"
                    className={style.inputLogin}
                    id="password"
                    InputProps={{
                      classes: {
                        input: style.inputText,
                      },
                    }}
                    type="password"
                  />
                </Grid>
                <Grid item className={style.formGroup}>
                  <Button
                    className={clsx(style.connectionBtn, style.btn)}
                    type="submit"
                    data-testid="btn-connection-login-pw"
                  >
                    {t('common.login')}
                  </Button>
                </Grid>
                <Grid item className={style.forgotPasswordContainer}>
                  <Link
                    to={{
                      pathname: `${location.pathname}/forgotten-password`,
                      state: {
                        background: location,
                        email: values.username,
                      },
                    }}
                  >
                    <span
                      className={style.forgotPassword}
                      data-testid="sendResetPassword"
                    >
                      {t('authentication.forgotten-psw')}
                    </span>
                  </Link>
                </Grid>
                <Grid item xs={12} style={{ marginTop: 10 }}>
                  {errorsToShow[FORM_ERROR] && (
                    <Alert severity="error">{errorsToShow[FORM_ERROR]}</Alert>
                  )}
                </Grid>
              </form>
            );
          }}
        />
      </Grid>
    </Grid>
  );
};
