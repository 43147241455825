import { makeStyles } from '@material-ui/core';

const useStyle = makeStyles((theme) => ({
  container: {
    marginBottom: '1rem',
    '& .MuiFormControl-marginNormal': {
      marginTop: 0,
      marginBottom: 0,
    },
  },
  pickerBtn: {
    '.MuiButton-textPrimary': {
      color: theme.palette.text.primary,
    },
  },
}));

export default useStyle;
