import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Shop } from 'sherl-react-sdk';
import ProductsListComponent from '../components/products.list.component';

const ITEMS_PER_PAGE = 15;

export const Products: React.FC = () => {
  const { push } = useHistory();
  const [page, setPage] = useState(1);
  const {
    productsLoading,
    products,
    getProducts,
    total,
    deleteProduct,
  } = Shop.useProducts();

  useEffect(() => {
    getProducts({ page, itemsPerPage: ITEMS_PER_PAGE });
  }, [page]);

  const getNextPage = () => {
    setPage(page + 1);
  };

  const goToProductDetails = (productId: string) => {
    push(`/app/products/${productId}`);
  };

  const handleProductDelete = async (productId: string) => {
    await deleteProduct(productId);
  };

  return (
    <ProductsListComponent
      productsList={products}
      loading={productsLoading}
      getNextPage={getNextPage}
      total={total}
      goToProductDetails={goToProductDetails}
      handleProductDelete={handleProductDelete}
    />
  );
};
