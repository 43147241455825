import { ClickAwayListener, Tooltip, Typography } from '@material-ui/core';
import { Help as HelpIcon } from '@material-ui/icons';
import React, { useState } from 'react';
import { useStyles } from '../styles/document-refused-help.style';

interface IProps {
  reason?: string;
}

export const DocumentRefusedHelp: React.FC<IProps> = ({ reason }) => {
  const styles = useStyles();
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = (event: React.ChangeEvent<any>) => {
    event.preventDefault();
    setOpen(true);
  };
  return (
    <ClickAwayListener onClickAway={handleTooltipClose}>
      <Tooltip
        arrow
        onClose={handleTooltipClose}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        classes={{
          tooltip: styles.tooltip,
          arrow: styles.arrow,
        }}
        title={<Typography>{reason}</Typography>}
      >
        <HelpIcon className={styles.icon} onClick={handleTooltipOpen} />
      </Tooltip>
    </ClickAwayListener>
  );
};
