import { CircularProgress, Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Alert } from '@material-ui/lab';
import { BirthdateDatePicker } from 'common/components/birthdate-datepicker';
import { sleep } from 'common/helpers';
import 'date-fns';
import { FORM_ERROR } from 'final-form';
import { makeValidate, TextField } from 'mui-rff';
import React, { useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { Organization } from 'sherl-react-sdk';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';

interface FormData {
  firstName: string;
  lastName: string;
  country: string;
  postalCode: string;
  locality: string;
  streetAddress: string;
  email: string;
  legalName: string;
  address: string;
  birthdate: Date;
  siret: string;
}

export const EstablishmentAdd = () => {
  const { goBack } = useHistory();
  const { t } = useTranslation();
  const [loading, setLoading] = useState<boolean>(false);
  const { addOrganization } = Organization.useOrganization();

  const { addFounder } = Organization.useFounder();

  const onSubmit = async (values: FormData) => {
    setLoading(true);
    try {
      const {
        legalName,
        country,
        siret,
        locality,
        postalCode,
        streetAddress,
        firstName,
        lastName,
        birthdate,
        email,
      } = values;
      const orgId = uuidv4();
      await addOrganization({
        id: orgId,
        legalName,
        createdAt: new Date().toISOString(),
        location: {
          id: uuidv4(),
          country,
          locality,
          postalCode,
          streetAddress,
        },
        siret: parseInt(siret, 10),
      });
      await sleep(1000);
      await addFounder(orgId, {
        birthDate: birthdate,
        email,
        firstName,
        lastName,
      });
      setLoading(false);
    } catch (err) {
      setLoading(false);
      return {
        [FORM_ERROR]: 'An error occured',
      };
    }
    setTimeout(() => {
      goBack();
    }, 1000);
  };

  const schema = Yup.object<FormData>().shape({
    firstName: Yup.string().required().label(t('common.form.firstName')),
    lastName: Yup.string().required().label(t('common.form.lastName')),
    legalName: Yup.string().required().label(t('common.form.legalName')),
    siret: Yup.string()
      .nullable()
      .matches(new RegExp(/^[0-9]+$/), {
        message: t('common.form.errors.number-only'),
      })
      .required()
      .label(t('common.form.siret')),
    streetAddress: Yup.string()
      .required()
      .label(t('common.form.address.streetAddress')),
    postalCode: Yup.string()
      .required()
      .label(t('common.form.address.postalCode')),
    locality: Yup.string().required().label(t('common.form.address.locality')),
    email: Yup.string().required().email().label(t('common.form.email')),
    birthdate: Yup.date().required().label(t('common.form.birthDate')),
  });
  const validate = makeValidate(schema);

  return (
    <Dialog
      open
      style={{ marginBottom: 20 }}
      onClose={goBack}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t('providers-listing.add')}
      </DialogTitle>
      <Form<FormData>
        onSubmit={onSubmit}
        validate={validate}
        render={({
          handleSubmit,
          submitSucceeded,
          submitFailed,
          submitError,
        }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      size="small"
                      required
                      label={t('common.form.firstName')}
                      variant="outlined"
                      name="firstName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      size="small"
                      required
                      label={t('common.form.lastName')}
                      variant="outlined"
                      name="lastName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      size="small"
                      required
                      label={t('common.form.legalName')}
                      variant="outlined"
                      name="legalName"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      size="small"
                      required
                      type="tel"
                      label={t('common.form.siret')}
                      variant="outlined"
                      name="siret"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      label={t('common.form.address.country')}
                      name="country"
                      type="text"
                      variant="outlined"
                      size="small"
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      label={t('common.form.address.streetAddress')}
                      name="streetAddress"
                      type="text"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      label={t('common.form.address.postalCode')}
                      name="postalCode"
                      type="text"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12} md={6} sm={6}>
                    <TextField
                      label={t('common.form.address.locality')}
                      name="locality"
                      type="text"
                      variant="outlined"
                      size="small"
                      required
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      required
                      size="small"
                      label={t('common.form.email')}
                      variant="outlined"
                      name="email"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <BirthdateDatePicker name="birthdate" />
                  </Grid>
                  <Grid item xs={12}>
                    {submitFailed && submitError && (
                      <Alert severity="error">{t('common.form.failed')}</Alert>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    {submitSucceeded && (
                      <Alert severity="success">
                        {t('common.form.succeeded')}
                      </Alert>
                    )}
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button onClick={goBack} color="primary">
                  {t('common.cancel')}
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  variant="outlined"
                  autoFocus
                >
                  {loading ? <CircularProgress /> : t('common.confirm')}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
