import { useTranslation } from 'react-i18next';
import { ValidationErrors } from 'final-form';
import { ICreditCardFields } from '../entities/credit-card-fields.entity';

export const useValidateCreditCard = () => {
  const { t } = useTranslation();

  const validate = (values: ICreditCardFields) => {
    const errors: ValidationErrors = {};

    if (!values.cardName) {
      errors.cardName = t('common.form.errors.field-required', {
        field: t('credit-card-fields.card-name'),
      });
    }
    if (!values.cardNumber) {
      errors.cardNumber = t('common.form.errors.field-required', {
        field: t('credit-card-fields.card-number'),
      });
    }
    if (!values.expirationDate) {
      errors.expirationDate = t('common.form.errors.field-required', {
        field: t('credit-card-fields.expiration-date'),
      });
    }

    if (!values.securityCode) {
      errors.securityCode = t('common.form.errors.field-required', {
        field: t('credit-card-fields.security-code'),
      });
    }
    if (values.securityCode && values.securityCode.length !== 3) {
      errors.securityCode = t('credit-card-fields.form.errors.notValidFormat', {
        field: t('credit-card-fields.3-numbers'),
      });
    }

    return errors;
  };

  return { validate };
};
