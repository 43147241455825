import React from 'react';

export const Mastercard2: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="12">
      <path
        d="M10 10.444A6 6 0 006 0a5.984 5.984 0 100 11.968 5.81 5.81 0 004-1.524z"
        fill="#07a9e2"
      />
      <path
        d="M0 6a5.988 5.988 0 006 5.968 5.779 5.779 0 004-1.556A5.984 5.984 0 006 0"
        fill="#009ddd"
      />
      <path
        d="M10 1.556A5.988 5.988 0 006 0h-.286a7.376 7.376 0 00-.032 11.968h.286a5.779 5.779 0 004-1.556A6 6 0 0010 1.555z"
        fill="#0284cc"
      />
      <path
        d="M14.032 0A5.916 5.916 0 0010 1.556a5.7 5.7 0 00-.6.635h1.206a4.536 4.536 0 01.444.635H8.923a6.293 6.293 0 00-.349.635h2.825a5.292 5.292 0 01.254.635h-3.3c-.063.222-.127.413-.19.635h3.683a5.562 5.562 0 01.127 1.27 5.455 5.455 0 01-.317 1.9h-3.3a5.291 5.291 0 00.254.635h2.825a4.066 4.066 0 01-.349.635H8.959a4.537 4.537 0 00.444.635h1.206a5.7 5.7 0 01-.6.635 6.07 6.07 0 004.032 1.556 6 6 0 000-12z"
        fill="#ff2957"
      />
      <path
        d="M14.032 0A5.916 5.916 0 0010 1.556a5.7 5.7 0 00-.6.635h1.206a4.536 4.536 0 01.444.635H8.923a6.293 6.293 0 00-.349.635h2.825a5.292 5.292 0 01.254.635h-3.3c-.063.222-.127.413-.19.635h3.683a5.562 5.562 0 01.127 1.27 5.455 5.455 0 01-.317 1.9h-3.3a5.291 5.291 0 00.254.635h2.825a4.066 4.066 0 01-.349.635H8.959a4.537 4.537 0 00.444.635h1.206a5.7 5.7 0 01-.6.635 6.07 6.07 0 004.032 1.556 5.961 5.961 0 005.968-5.968"
        fill="#ed1c2e"
      />
      <path d="M10 10.445a6 6 0 000-8.889v8.889z" fill="#0466b5" />
      <path
        style={{ fill: '#fff' }}
        d="M17.937 7.302a.19.19 0 11.19.19.2.2 0 01-.19-.19zm.19.159c.1 0 .127-.064.127-.159a.116.116 0 00-.127-.127.145.145 0 00-.159.127.2.2 0 00.159.159zm0-.1h-.032v-.15h.1l.032.032c0 .032 0 .032-.032.032l.032.063h-.032l-.032-.063h-.032v.1zm0-.1h.032v-.032h-.032v.032z"
      />
      <path
        d="M10 1.555a5.705 5.705 0 00-.6.635h1.206a4.536 4.536 0 01.444.635H8.923a6.292 6.292 0 00-.349.635h2.825a5.293 5.293 0 01.254.635h-3.3c-.063.222-.127.413-.19.635h3.683A5.562 5.562 0 0111.973 6a5.455 5.455 0 01-.317 1.9h-3.3a5.292 5.292 0 00.254.635h2.825a4.066 4.066 0 01-.349.635H8.959a4.537 4.537 0 00.444.635h1.206a5.7 5.7 0 01-.6.635 6.07 6.07 0 004.032 1.556h.286a7.368 7.368 0 000-11.937h-.286A5.719 5.719 0 0010 1.555z"
        fill="#d1094b"
      />
      <path
        style={{ fill: '#fff' }}
        d="M5.238 7.492h-.762l.444-2.317L3.9 7.492h-.667l-.127-2.317-.444 2.317H2l.571-3.048h1.175l.063 1.9.825-1.9H5.84z"
      />
      <path
        style={{ fill: '#dce5e5' }}
        d="M4.508 6.159l.444-.984-.349 1.841a2.971 2.971 0 00.127.508h.508l.571-3.048H4.698a6.134 6.134 0 00-.222 1.556c.032.031.032.063.032.127zM13.555 7.46a1.761 1.761 0 01-.54.1c-.381 0-.571-.19-.571-.54a.62.62 0 01.032-.222l.032-.254.032-.19.349-1.873h.73l-.1.571h.381l-.1.571h-.381l-.191 1.016a.32.32 0 01-.032.1c0 .127.1.19.254.19.1 0 .159 0 .19-.032l-.1.571zM15.746 5.047c-.064-.032-.064-.032-.1-.032s-.063 0-.063-.032h-.1c-.254 0-.413.1-.635.413l.064-.381h-.667l-.436 2.477h.762c.254-1.524.381-1.778.73-1.778h.1l.1.032.254-.7zM10.603 5.809q0 .476.571.667c.317.127.349.159.349.286 0 .159-.127.222-.444.222a2.416 2.416 0 01-.667-.1l-.1.571h.032l.127.032h.19a2.278 2.278 0 00.413.032c.794 0 1.175-.254 1.175-.825 0-.349-.159-.54-.54-.7-.317-.127-.349-.159-.349-.254q0-.19.381-.19a3.461 3.461 0 01.54.032l.1-.571a4.694 4.694 0 00-.667-.032c-.825 0-1.111.381-1.111.825zM7.651 7.492h-.6v-.254a1.056 1.056 0 01-.794.286.633.633 0 01-.667-.667c0-.6.476-.952 1.3-.952h.317a.425.425 0 00.032-.159c0-.159-.127-.222-.476-.222a3.2 3.2 0 00-.6.063l-.1.032h-.064l.1-.54a3.315 3.315 0 01.889-.127c.635 0 .984.254.984.73a1.908 1.908 0 01-.063.508l-.159.889-.032.159-.032.127v.1l-.032.032zm-.54-1.111h-.159c-.413 0-.635.127-.635.381a.234.234 0 00.254.254.563.563 0 00.54-.635zM10.095 7.428a3.132 3.132 0 01-.762.1c-.825 0-1.27-.381-1.27-1.111a1.331 1.331 0 011.3-1.46.878.878 0 01.984.889 2.346 2.346 0 01-.1.6H8.763v.1c0 .286.222.444.635.444a1.568 1.568 0 00.762-.159l-.064.6zm-.381-1.46v-.127a.31.31 0 00-.349-.317.5.5 0 00-.476.444z"
      />
      <g>
        <path
          style={{ fill: '#fff' }}
          d="M18.064 6.381a1.32 1.32 0 01-1.46 1.175 1.059 1.059 0 01-1.175-1.143 1.383 1.383 0 011.46-1.46 1.084 1.084 0 011.175 1.111v.317zm-.794-.317c0-.254-.1-.476-.413-.476-.381 0-.6.444-.6.857 0 .317.159.54.413.54a.736.736 0 00.571-.6 1.19 1.19 0 00.032-.317z"
        />
        <path
          style={{ fill: '#fff' }}
          d="M15.461 6.698a5.193 5.193 0 00.063-.7v-.062a2.468 2.468 0 00-.063.508.348.348 0 000 .254z"
        />
      </g>
    </svg>
  );
};
