import { common, grey } from '@material-ui/core/colors';
import { Overrides } from '@material-ui/core/styles/overrides';
import { fonts, fontWeights } from '../font';
import { color } from './color';
import { palette } from './palette';

export const overrides: Overrides = {
  MuiInputBase: {
    root: {
      color: palette.common.black,
    },
    input: {
      color: palette.common.black,
    },
  },
  MuiFilledInput: {
    adornedEnd: {
      paddingRight: 0,
    },
  },
  MuiCard: {
    root: {
      boxShadow: `0px 3px 6px ${color.shadows}`,
      backgroundColor: color.white,
    },
  },
  MuiCssBaseline: {
    '@global': {
      ...(fonts as any),
    },
  },
  MuiIconButton: {
    root: {
      '&$disabled': {
        color: palette.grey[400],
      },
    },
  },
  MuiTypography: {
    root: {
      color: palette.common.black,
    },
  },
  MuiTablePagination: {
    root: {
      color: palette.common.white,
    },
  },
  MuiButton: {
    root: {
      color: palette.common.white,
      borderRadius: '2px',
    },
    outlined: {
      border: `1px solid ${palette.common.black}`,
      borderRadius: 0,
    },
    contained: {
      '&:hover': {
        backgroundColor: grey[700],
      },
    },
  },
  MuiListItem: {
    selected: {},
    secondaryAction: {
      paddingRight: '60px',
    },
  },
  MuiListItemIcon: {
    root: {
      minWidth: '40px',
      paddingLeft: 6,
    },
  },
  MuiTableSortLabel: {
    active: {
      color: `${palette.primary.main} !important`,
    },
    icon: {
      color: palette.common.white,
    },
    iconDirectionAsc: {
      color: `${palette.primary.main} !important`,
    },
    iconDirectionDesc: {
      color: `${palette.primary.main} !important`,
    },
    root: {
      color: `${palette.common.white} !important`,
    },
  },
  MuiTableRow: {
    root: {
      borderTop: `1px solid rgba(255,255,255,0.15) !important`,
    },
    head: {
      borderTop: `1px solid rgba(255,255,255,0.5) !important`,
    },
    footer: {
      border: 'none',
      backgroundColor: 'transparent',
    },
  },
  MuiSvgIcon: {
    colorPrimary: { color: palette.common.black },
  },
  MuiCheckbox: {
    root: {
      color: `${palette.common.black}`,

      '&$checked': {
        color: palette.common.black,
      },
    },
  },
  MuiFormLabel: {
    root: {
      color: palette.common.black,
    },
  },
  // TODO à revoir (pb couleur intérieur des radio cf:page login )
  MuiRadio: {
    root: {
      color: common.black,
      '&$checked': {
        color: common.black,
      },
      colorPrimary: {
        colors: common.black,
        '&$checked': {
          color: common.black,
        },
      },
      '&MuiChecked': {
        color: `${common.black} !important`,
      },
    },
  },
  MuiTabs: {
    indicator: {
      display: 'none',
    },
  },
  MuiTab: {
    root: {
      textTransform: 'uppercase',
      fontWeight: fontWeights.fontWeightBlack,
      '&$selected': {
        backgroundColor: palette.secondary.main,
      },
    },
    textColorInherit: {
      color: palette.primary.main,
    },
  },
  MuiPickersCalendarHeader: {
    dayLabel: {
      color: color.primary,
    },
  },
  MuiPickersDay: {
    dayDisabled: {
      color: color.primary,
      opacity: 0.5,
    },
    current: {
      color: color.cyan,
      opacity: 1,
    },
  },
  MuiOutlinedInput: {
    root: {},
  },
  // need for the datepicker on search bar
  MuiFormControl: {
    // marginNormal: {
    //   marginTop: 0,
    //   marginBottom: 0
    // }
  },
  MuiPopover: {
    root: {
      zIndex: `${2000} !important` as any,
    },
  },
  MuiDrawer: {
    root: {
      overflowX: 'hidden',
    },
    paper: {
      overflowX: 'hidden',
    },
  },
  MuiListItemAvatar: {
    root: {
      minWidth: '40px',
    },
  },
};
