import { makeValidate } from 'mui-rff';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

export const useValidate = () => {
  const { t } = useTranslation();
  const schema = Yup.object().shape({
    name: Yup.string().required().label(t('product-form.name')),
    description: Yup.string().required().label(t('product-form.description')),
  });
  const validate = makeValidate(schema);

  return { validate };
};
