import React, { useState, useEffect } from 'react';
import { IAddress } from 'sherl-react-sdk';
import {
  Grid,
  Box,
  CircularProgress,
  Button,
  Card,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Form } from 'react-final-form';
import { FORM_ERROR } from 'final-form';
import { getErrors } from 'common/helpers';
import { Alert } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';
import { TextField } from 'mui-rff';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { CardHeader } from 'common/components/card-header';
import { useValidate } from '../hooks/validate.hook';

interface IProps {
  title?: string;
  subtitle?: string;
  address?: IAddress;
  onSubmit: (address: Partial<IAddress>) => Promise<void>;
  loading?: boolean;
}

export const AddressFormSimple: React.FC<IProps> = ({
  address,
  onSubmit,
  loading,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<IAddress>();

  useEffect(() => {
    if (address) {
      setInitialValues(address);
    }
  }, [address]);

  const submit = async (values: Partial<IAddress>) => {
    try {
      await onSubmit(values);
    } catch (err) {
      return {
        [FORM_ERROR]: t(`address-form-simple.error.${err?.response?.status}`),
      };
    }
  };

  const { validate } = useValidate();

  return (
    <Form
      onSubmit={submit}
      initialValues={initialValues}
      validate={validate as any}
      render={({
        handleSubmit,
        errors,
        submitErrors,
        submitFailed,
        dirtySinceLastSubmit,
        submitSucceeded,
        submitting,
      }) => {
        const errorToShow = getErrors(
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
        );
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={title ?? t('address-form-simple.title')}
                icon={<FontAwesomeIcon icon={faUser} />}
                action={
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={submitting}
                  >
                    {t('common.save')}
                  </Button>
                }
              />
              <CardContent>
                {subtitle && (
                  <Typography variant="caption" paragraph>
                    {subtitle}
                  </Typography>
                )}
                {loading && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                {!loading && (
                  <>
                    <Grid container justify="center" spacing={2}>
                      <Grid item xs={12}>
                        {errorToShow[FORM_ERROR] && (
                          <Alert severity="error">
                            {errorToShow[FORM_ERROR]}
                          </Alert>
                        )}
                        {submitSucceeded && (
                          <Alert severity="success">
                            {t('common.form.updateSucceeded')}
                          </Alert>
                        )}
                      </Grid>

                      <Grid item xs={12}>
                        <TextField
                          label={t('address-form-simple.form.streetAddress')}
                          name="streetAddress"
                          type="text"
                          variant="outlined"
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          label={t(
                            'address-form-simple.form.complementaryStreetAddress',
                          )}
                          name="complementaryStreetAddress"
                          type="text"
                          size="small"
                          variant="outlined"
                        />
                      </Grid>

                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t('address-form-simple.form.postalCode')}
                          name="postalCode"
                          type="text"
                          variant="outlined"
                          size="small"
                          required
                        />
                      </Grid>
                      <Grid item xs={12} md={6}>
                        <TextField
                          label={t('address-form-simple.form.locality')}
                          name="locality"
                          type="text"
                          variant="outlined"
                          size="small"
                          required
                        />
                      </Grid>
                    </Grid>
                  </>
                )}
              </CardContent>
            </Card>
          </form>
        );
      }}
    />
  );
};
