import { Box, Button, Grid } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ICategoryNested } from 'sherl-react-sdk';
import { useStyle, useStyleTheme } from '../styles/menu.style';

interface IProps {
  isOpen: boolean;
  items: ICategoryNested;
  isSmallScreen?: boolean;
  handleClose?: () => void;
  /**
   * Sub-menu marginLeft
   */
  subMenuPosition?: number;
}

export const MenuCategoriesDialog: React.FC<IProps> = ({
  isOpen,
  items,
  isSmallScreen,
  handleClose,
  subMenuPosition,
}) => {
  const styleTheme = useStyleTheme();
  const classNames = useStyle({ isSmallScreen, subMenuPosition });
  const { t } = useTranslation();

  const onClickItem = (subCatId: string) => {
    if (handleClose) {
      handleClose();
    }
    alert('navigate to the product with his id');
  };

  return (
    <>
      {isOpen && items && (
        <Grid
          container
          className={
            isSmallScreen
              ? `${classNames.defaultModalSubCaterogiesContainerSmallScreen} ${styleTheme.modalSubCaterogiesContainerSmallScreen} ${classNames.subMenu}`
              : `${classNames.defaultModalSubCaterogiesContainer} ${styleTheme.modalSubCaterogiesContainer}`
          }
          onMouseLeave={handleClose}
        >
          {Object.values(items.subCategories || {}).map((subCategory) => (
            <Grid item xs={12} md={6} key={subCategory.id}>
              <Box
                onClick={() => onClickItem(subCategory.id)}
                className={`${classNames.defaultModalSubMenuItemText} ${styleTheme.modalSubMenuItemText}`}
              >
                {subCategory.name}
              </Box>
            </Grid>
          ))}
          {isSmallScreen && (
            <Button
              color="primary"
              startIcon={<ArrowBackIosIcon />}
              onClick={handleClose}
              className={`${classNames.defaultSmallScreenReturnBtnContainer} ${styleTheme.smallScreenReturnBtnContainer}`}
            >
              {t('common.return')}
            </Button>
          )}
        </Grid>
      )}
    </>
  );
};
