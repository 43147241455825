import LogoAssets from 'assets/images/logo/logo.jpg';
import { Config } from 'sherl-react-sdk';

export const Logo = (): string | undefined => {
  let logoUri: string = LogoAssets;
  if (Config.ConfigService.get('logoUri')) {
    logoUri = Config.ConfigService.get('logoUri');
  }
  return logoUri;
};
