import { makeStyles } from '@material-ui/core/styles';
import { color } from 'common/assets';

export const useStyles = makeStyles((theme) => ({
  categoriesContainer: {
    [theme.breakpoints.up('md')]: {
      minHeight: 650,
    },
  },
  listContainer: {
    width: '100%',
  },
  itemContainer: {
    boxShadow: `0px 3px 6px ${color.shadows}`,
    borderRadius: '4px',
    marginBottom: theme.aux.spacing.xs,
  },
  addBtn: {
    fontSize: theme.typography.small.fontSize,
    marginLeft: theme.spacing[2],
  },
  chevron: {
    marginLeft: theme.aux.spacing.s,
    marginRight: theme.aux.spacing.s,
  },
  title: {
    fontWeight: theme.typography.fontWeightBold,
  },
}));
