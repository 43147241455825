import { makeStyles, Theme } from '@material-ui/core';
import { getStatusColor } from '../components/status.component';

export const useStyles = makeStyles((theme: Theme) => ({
  status: (props: any) => ({
    color: getStatusColor(props.status, props.typeRate),
    fontWeight: theme.typography.fontWeightExtraBold,
    fontSize: theme.typography.body1.fontSize,
  }),
}));
