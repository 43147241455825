import { makeStyles, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IMenuTheme } from '../entities/menu.entity';

export const useStyleTheme = () => {
  const theme = useTheme() as ThemeOptions & IMenuTheme;
  const useThemeStyle = makeStyles(theme.menu);
  return useThemeStyle();
};

export const useStyle = makeStyles((theme) => ({
  goodDealsBox: {},
  goodDealsContainer: {
    textAlign: 'center',
  },
  goodDeals: {
    display: 'inline',
    fontWeight: theme.typography.fontWeightBlack,
    textTransform: 'uppercase',
    backgroundColor: theme.palette.action.hover,
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  zIndex: {
    zIndex: 1200,
  },
  menuItemContainer: {
    position: 'relative',
    display: 'flex',
  },
  menuItemText: {
    fontWeight: theme.typography.fontWeightBlack,
    textTransform: 'uppercase',
    '&:hover': {
      textDecoration: 'none !important',
    },
  },
  menuItemSubText: {
    fontWeight: theme.typography.fontWeightSemiBold,
  },
  subCategoriesList: {
    boxShadow: theme.shadows[1],
    backgroundColor: theme.palette.common.white,
    position: 'relative',
    width: '100%',

    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  defaultContainer: {
    paddingBottom: 10,
    backgroundColor: theme.palette.common.white,
  },
  defaultContainerSmallScreen: {},
  defaultMenuItemContainer: {
    flexGrow: 1,
  },

  defaultMenuItemText: {
    color: theme.palette.common.black,
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  defaultMenuItemRightBlockContainer: {},
  defaultMenuItemRightBlockContainerMobile: {},
  defaultItemsContainer: {},
  defaultModalSubCaterogiesContainer: {
    backgroundColor: theme.palette.common.white,
    zIndex: 100,
    position: 'absolute',
    padding: 16,
  },
  defaultModalSubCaterogiesContainerSmallScreen: {
    backgroundColor: theme.palette.common.white,
  },
  defaultModalSubMenuItemText: {
    color: theme.palette.common.black,
    fontSize: '1rem',
  },
  defaultSmallScreenReturnBtnContainer: {},
  subMenu: ({ isSmallScreen, subMenuPosition }: any) => ({
    marginLeft: isSmallScreen ? `${subMenuPosition}%` : 0,
  }),
}));
