import { Grid } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Analytics, IAnalytics, Order, Person } from 'sherl-react-sdk';
import { StatCard } from './statistics.card.component';
import { StatisticsDatesSelector } from './statistics.dates.selector';

const CLIENT_TYPE = 'DEFAULT';

export const Statistics = () => {
  const { t } = useTranslation();
  const {
    getOrders,
    view: { total: totalOrders },
  } = Order.useOrders();
  const { total: totalClients, getSearchPersons } = Person.usePersons();
  const { getCa } = Analytics.useAnalytics();
  const [ca, setCa] = useState(0);

  const caCalculation = (data: IAnalytics[]) => {
    let newCa = 0;
    if (data.length > 0) {
      data.forEach((dailyCa) => {
        newCa += dailyCa.value;
      });
    }
    setCa(newCa);
  };

  useEffect(() => {
    getOrders(1, 1);
    getSearchPersons(1, 1, { type: CLIENT_TYPE });
    getCa(new Date('2020-06-25T08:30:41.719Z'), new Date(), '', 'USER').then(
      (data) => {
        caCalculation(data);
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateValuesWithNewRange = (dates: { start: Date; end: Date }) => {
    getOrders(
      1,
      1,
      {},
      {
        dateRangeMin: dates.start,
        dateRangeMax: dates.end,
      },
    );

    getCa(dates.start, dates.end, '', 'DATE').then((data) => {
      caCalculation(data);
    });

    getSearchPersons(1, 1, {
      beginDate: dates.start,
      endDate: dates.end,
      type: CLIENT_TYPE,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <StatisticsDatesSelector
          onDatesChange={(data) => {
            updateValuesWithNewRange(data as any);
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <StatCard
            title={t('admin-stats.reservations-count')}
            description={t('admin-stats.reservation-count-desc')}
            count={totalOrders}
          />
          <StatCard
            title={t('admin-stats.customer-count')}
            description={t('admin-stats.customer-count-desc')}
            count={totalClients}
          />
          <StatCard
            title={t('admin-stats.total-ca')}
            description={t('admin-stats.total-ca-dec')}
            count={Number(ca.toFixed(2))}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
