type GridMap =
  | boolean
  | 'auto'
  | 1
  | 2
  | 3
  | 4
  | 5
  | 6
  | 7
  | 8
  | 9
  | 10
  | 11
  | 12
  | undefined;

export default {
  baseRoutePrefix: '/app',
  initialPath: '/orders',
  '/profile': {},
  '/stats': {},
  '/clients': {
    icon: 'people',
  },
  '/providers': {
    icon: 'business_center',
  },
  '/client/:id': {
    icon: 'person_circle',
  },
  '/orders': {
    icon: 'home',
  },
  '/reports': {},
  '/administrators': {},
  '/reviews': {},
  '/bank-pictures': {},
  '/catalog': {},
  '/cms': {},
  '/communication': {},
  '/communication/sms': {},
  '/communication/mailing': {},
  '/communication/advertising': {},
  '/formation': {},
  '/products': {
    icon: 'person_circle',
  },
  '/products/:id': {},
  DEFAULT: [],
  EMPLOYEE: [],
  FOUNDER: ['/profile', '/orders', '/person/:id', '/account'],
  ADMIN: [
    '/clients',
    '/profile',
    '/orders/create',
    '/providers',
    '/orders',
    '/ordersList',
    '/client/:id',
  ],
  'screens-options': {
    clients: {
      columns: {
        firstName: {
          enabled: true,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        lastName: {
          enabled: true,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        signupDate: {
          enabled: false,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        lastOrderDate: {
          enabled: false,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        ordersAmount: {
          enabled: false,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        totalMoneySpent: {
          enabled: false,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
        birthDate: {
          enabled: true,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
      } as {
        [key: string]: {
          enabled: boolean;
          size: GridMap;
          accountType?: string[];
          accountTypeFiltered?: boolean;
        };
      },
      actions: {
        size: 2 as GridMap,
        disable: false,
        deletion: false,
        detail: true,
      },
    },
    orders: {
      header: {
        add: ['ADMIN'],
      },
      columns: {
        ref: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 1,
        },
        label: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 2,
        },
        provider: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 2,
        },
        client: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 2,
        },
        date: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: false,
          size: 2,
        },
        amount: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 1,
        },
        statusUpdate: {
          accountType: ['ADMIN'],
          accountTypeFiltered: true,
          enabled: true,
          size: 2,
        },
        status: {
          accountType: ['FOUNDER'],
          accountTypeFiltered: true,
          enabled: true,
          size: 2,
        },
        'means-of-payment': {
          enabled: false,
          size: 2,
          accountType: [],
          accountTypeFiltered: false,
        },
      } as {
        [key: string]: {
          accountType?: string[];
          accountTypeFiltered?: boolean;
          enabled: boolean;
          size: GridMap;
        };
      },
      actions: {
        size: 2 as GridMap,
        sendBill: {
          enabled: true,
          accountType: ['ADMIN'],
          accountTypeFiltered: true,
        },
      },
    },
    products: {
      header: {
        add: ['ADMIN'],
      },
      columns: {
        picture: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 2,
        },
        name: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 2,
        },
        description: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 3,
        },
        categories: {
          accountType: [],
          accountTypeFiltered: false,
          enabled: true,
          size: 4,
        },
      } as {
        [key: string]: {
          accountType?: string[];
          accountTypeFiltered?: boolean;
          enabled: boolean;
          size: GridMap;
        };
      },
      actions: {
        size: 1 as GridMap,
        disable: false,
        deletion: false,
        detail: true,
      },
    },
  },
};
