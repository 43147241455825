import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';
import {
  IActionBoxTheme,
  IDescriptionBoxTheme,
  IFooterTheme,
  IMenuTheme,
  IOpeningTimesTheme,
  IPresentationBoxTheme,
  IRowBrandSupportTheme,
  IStylePictureTheme,
} from 'common/components';
import {
  defaultTheme,
  IDefaultExtendedTheme as IExtendedThemeParent,
} from './default-theme';
import { fontWeights } from './font';
import {
  actionBox,
  descriptionBox,
  footer,
  menu,
  openingTimes,
  presentationBox,
  rowBrandsSupport,
  stylePicture,
} from './theme/components';
import { overrides } from './theme/overrides';
import { palette } from './theme/palette';

// Create default shadows elevations range
export const shadows = defaultTheme.shadows;
if (shadows) {
  shadows[1] = '0px 3px 6px #00000042';
}
export interface IExtendedTheme
  extends IExtendedThemeParent,
    IRowBrandSupportTheme,
    IPresentationBoxTheme,
    IActionBoxTheme,
    IMenuTheme,
    IStylePictureTheme,
    IDescriptionBoxTheme,
    IFooterTheme,
    IOpeningTimesTheme {}

export const auxComponentsTheme = {
  actionBox,
  menu,
  stylePicture,
  descriptionBox,
  footer,
  rowBrandSupport: rowBrandsSupport,
  presentationBox,
  openingTimes,
};

const options: IExtendedTheme = {
  ...defaultTheme,
  shadows,
  palette,
  ...auxComponentsTheme,
  typography: {
    fontSize: 12,
    fontFamily: ['Comfortaa', 'sans-serif'].join(','),
    fontFamilySecondary: ['Abril Fatface', 'serif'].join(','),
    ...fontWeights,
    caption: {
      color: palette.grey[400],
      fontSize: '0.85rem',
    },
    extraSmall: {
      fontSize: '0.66rem',
    },
    small: {
      fontSize: '0.77rem',
    },
    body1: {
      fontSize: '0.925rem',
    },
    body2: {
      fontSize: '1.05rem',
    },
    subtitle1: {
      fontSize: '1.125rem',
    },
    subtitle2: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '2rem',
    },
    h2: {
      fontSize: '2.25rem',
    },
    h1: {
      fontSize: '3.875rem',
    },
  },
  overrides: overrides,
};

// A custom theme for this app
let themeCustom = createMuiTheme(options);
themeCustom = responsiveFontSizes(themeCustom);

export default themeCustom;
export { themeCustom as theme };
