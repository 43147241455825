import {
  Box,
  IconButton,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@material-ui/core';
import { Backup, Check, HourglassEmpty } from '@material-ui/icons';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles/document-list-item.style';
import { DocumentRefusedHelp } from './document-refused-help.component';
import {
  IDocumentResponse,
  KycDocumentType,
  StatusType,
} from 'sherl-react-sdk';

interface IProps {
  document?: IDocumentResponse;
  documentType: KycDocumentType;
  setUpload: (type: KycDocumentType) => void;
}

export const DocumentListItem: React.FC<IProps> = ({
  documentType,
  document,
  setUpload,
}) => {
  const { t } = useTranslation();
  const styles = useStyles({ status: document?.status as StatusType });

  let action: JSX.Element | null = null;
  switch (document?.status) {
    case StatusType.NOT_UPLOADED:
      action = (
        <IconButton onClick={() => setUpload(documentType)}>
          <Backup />
        </IconButton>
      );
      break;
    case StatusType.REFUSED:
      action = (
        <IconButton onClick={() => setUpload(documentType)}>
          <Backup />
        </IconButton>
      );
      break;
    case StatusType.VALIDATED:
      action = (
        <IconButton disabled>
          <Check />
        </IconButton>
      );
      break;
    case StatusType.CREATED:
      action = (
        <IconButton disabled>
          <HourglassEmpty />
        </IconButton>
      );
      break;
    case StatusType.VALIDATION_ASKED:
      action = (
        <IconButton disabled>
          <HourglassEmpty />
        </IconButton>
      );
      break;
    default:
      action = (
        <IconButton onClick={() => setUpload(documentType)}>
          <Backup />
        </IconButton>
      );
      break;
  }

  return (
    <ListItem key={documentType}>
      <ListItemText disableTypography>
        <Box display="flex" justifyContent="space-between">
          <Box>
            <Typography>
              {t(`organization-document-upload.document-type.${documentType}`)}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography component="span" className={styles.status}>
              {document &&
                t(
                  `organization-document-upload.status-type.${document?.status}`,
                )}
              {!document &&
                t(`organization-document-upload.status-type.NOT_UPLOADED`)}
              {document && document.status === StatusType.REFUSED && (
                <Typography component="span" className={styles.helpContainer}>
                  <DocumentRefusedHelp reason={document.refusedReasonMessage} />
                </Typography>
              )}
            </Typography>
          </Box>
        </Box>
      </ListItemText>
      <ListItemSecondaryAction>{action}</ListItemSecondaryAction>
    </ListItem>
  );
};
