import { Typography } from '@material-ui/core';
import { green, orange, red } from '@material-ui/core/colors';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RateStatus } from 'sherl-react-sdk';
import { useStyles } from '../styles/status.style';

interface IProps {
  status: string;
  typeRate?: 'given' | 'received';
}

export const getStatusColor = (status: string, typeRate: string) => {
  let finalStatus = status;

  if (
    typeRate &&
    typeRate === 'given' &&
    status === RateStatus.STATUS_CLAIMED
  ) {
    finalStatus = RateStatus.STATUS_PUBLIC;
  }

  switch (finalStatus) {
    case RateStatus.STATUS_PUBLIC:
      return green['400'];
    case RateStatus.STATUS_REFUSED:
      return red['400'];
    case RateStatus.STATUS_CLAIMED:
      return orange['400'];
  }
};
export const Status: React.FC<IProps> = (props) => {
  const { status, typeRate } = props;
  const { t } = useTranslation();
  const classNames = useStyles(props);
  const [statusToDisplayed, setStatusDisplayed] = useState('');

  useEffect(() => {
    if (
      typeRate &&
      typeRate === 'given' &&
      status === RateStatus.STATUS_CLAIMED
    ) {
      setStatusDisplayed(RateStatus.STATUS_PUBLIC);
    }
  }, [status, typeRate]);

  return (
    <Typography className={classNames.status}>
      {t(`rate-tab.${statusToDisplayed ? statusToDisplayed : status}`)}
      {props.children}
    </Typography>
  );
};
