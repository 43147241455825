import { makeStyles, Theme, useTheme } from '@material-ui/core';
import { IOpeningTimesTheme } from '../entities/opening-times.entity';

export const useStyleTheme = () => {
  const theme = useTheme() as Theme & IOpeningTimesTheme;
  const useThemeStyle = makeStyles(theme.openingTimes);
  return useThemeStyle();
};

export const useStyle = makeStyles((theme) => ({
  defaultCardContainer: {
    backgroundColor: theme.palette.grey[200],
    padding: '1rem',
    width: '100%',
  },
  defaultTitle: {
    fontWeight: 'bold',
    fontSize: theme.typography.body2.fontSize,
    textTransform: 'uppercase',
    paddingBottom: '1rem',
  },
  defaultDayTitle: {
    fontSize: theme.typography.body1.fontSize,
    minWidth: '150px',
  },
  defaultDay: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    borderBottom: `1px solid ${theme.palette.grey[800]}`,
  },
  defaultFullWidth: { width: '100%' },
  defaultPickerBtn: {
    marginTop: '0px',
    marginBottom: '0px',
    '.MuiButton-textPrimary': {
      color: '#fff',
    },
  },
}));

export default useStyle;
