import i18n from 'i18next';
import intervalPlural from 'i18next-intervalplural-postprocessor';
import { initReactI18next } from 'react-i18next';
import fr from './lang/fr.json';
import { fr as yupFr } from 'yup-locales';
import { setLocale } from 'yup';

setLocale(yupFr);

export default i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .use(intervalPlural)
  .init({
    fallbackLng: 'fr',
    interpolation: {
      escapeValue: false,
    },
    lng: 'fr',
    resources: {
      fr,
    },
  });
