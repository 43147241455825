import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.aux.spacing.s,
  },
  activeIcon: {
    color: theme.palette.primary.main,
    filter: `drop-shadow(2px 4px 6px ${theme.palette.primary.main});`,
  },
  lastName: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.small.fontSize,
  },
  itemContainer: {
    paddingTop: theme.aux.spacing.xs,
    paddingBottom: theme.aux.spacing.xs,
  },
  globalItemStyle: {
    fontSize: theme.typography.small.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  actionsBtn: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mobileItemLabel: {
    paddingRight: theme.aux.spacing.xs,
  },
  fullWidth: {
    width: '100%',
  },
  search: {
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  searchPosition: {
    [theme.breakpoints.up('md')]: {
      paddingLeft: theme.aux.spacing.s,
    },
  },
  loading: {
    width: '100%',
  },
  noWrap: {
    whiteSpace: 'nowrap',
  },
}));
