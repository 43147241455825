import { makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IPresentationBoxTheme } from '../entities/presentation.box.entities';

export const useStyleTheme = () => {
  const theme = useTheme() as ThemeOptions & IPresentationBoxTheme;
  const useThemeStyle = makeStyles(theme ? theme.presentationBox : ({} as any));
  return useThemeStyle();
};

export const useStyle = makeStyles((theme: Theme) => ({
  defaultContainer: {
    boxShadow: theme.shadows[2],
  },
  defaultFilter: {},
  defaultTitle: {},
  defaultSubTitle: {},
  defaultOrganizationContainer: {},
  defaultAvatar: {},
  defaultOrganizationDescription: {},
  defaultButtonText: {},
}));
