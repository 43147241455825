import { makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IActionBoxTheme } from '../entities/action.box.entity';

export const useStyleTheme = () => {
  const theme = useTheme() as ThemeOptions & IActionBoxTheme;
  const useThemeStyle = makeStyles(theme ? theme.actionBox : ({} as any));
  return useThemeStyle();
};

export const useStyle = makeStyles((theme: Theme) => ({
  defaultContainer: ({ backgroundImage, noBackground }: any) => ({
    backgroundImage: !noBackground
      ? `linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.7)), url(${backgroundImage})`
      : undefined,
    backgroundSize: 'cover',
    position: 'relative',
  }),
  defaultTitle: (props: any) => ({
    color: props.titleColor ? props.titleColor : theme.palette.common.black,
    fontSize: props.titleFontSize ? props.titleFontSize : undefined,
    fontWeight: props.titleFontWeight ? props.titleFontWeight : undefined,
    textTransform: props.titleUppercase ? props.titleUppercase : undefined,
  }),
  defaultText: (props: any) => ({
    color: props.textColor ? props.textColor : theme.palette.common.black,
    fontWeight: props.textFontWeight ? props.textFontWeight : undefined,
    fontSize: props.textFontSize ? props.textFontSize : undefined,
  }),
  defaultContent: {},
  defaultBtn: {},
  defaultBtnText: {},
  defaultStep: {
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.body2.fontSize,
    boxShadow: `#A6DACE 0px -5px 0px inset`,
  },
  defaultStepContent: {
    fontSize: theme.typography.body2.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));
