import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { CardHeader } from 'common/components';
import { getErrors } from 'common/helpers';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { User } from 'sherl-react-sdk';
import { IFormData } from '../entities/password-change-form.entity';
import { useValidate } from '../hooks/password-change-form.hook';

library.add(faUser);

export const PasswordChangeForm: React.FC = () => {
  const { t } = useTranslation();
  const { changePassword, loading } = User.useChangePassword();

  const handleSubmit = async (values: IFormData) => {
    try {
      await changePassword(
        values.oldPassword,
        values.password,
        values.passwordRepeat,
      );
    } catch (err) {
      return {
        [FORM_ERROR]: t(
          `password-change-form.error.${err?.response?.data?.message}`,
        ),
      };
    }
  };

  const { validate } = useValidate();

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate}
      render={({
        handleSubmit,
        submitting,
        errors,
        submitErrors,
        submitFailed,
        dirtySinceLastSubmit,
        submitSucceeded,
      }) => {
        const errorToShow = getErrors(
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
        );

        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={t('password-change-form.change-password')}
                icon={<FontAwesomeIcon icon={faUser} />}
                action={
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={submitting}
                  >
                    {t('common.save')}
                  </Button>
                }
              />
              <CardContent>
                {loading && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                {!loading && (
                  <Grid container direction="column" spacing={2}>
                    {errorToShow && (
                      <Grid item>
                        <Typography color="error">
                          {errorToShow[FORM_ERROR]}
                        </Typography>
                      </Grid>
                    )}
                    {submitSucceeded && (
                      <Grid item>
                        <Typography color="error">
                          {t('common.form.updateSucceeded')}
                        </Typography>
                      </Grid>
                    )}
                    <Grid item>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="oldPassword"
                        type="password"
                        autoComplete="new-password"
                        label={t('password-change-form.old-password')}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="password"
                        variant="outlined"
                        size="small"
                        type="password"
                        autoComplete="new-password"
                        label={t('password-change-form.password')}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="passwordRepeat"
                        variant="outlined"
                        size="small"
                        type="password"
                        autoComplete="new-password"
                        label={t('password-change-form.password-repeat')}
                      />
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </form>
        );
      }}
    />
  );
};
