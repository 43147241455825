import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  pageTitle: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    marginLeft: theme.aux.spacing.s,
  },
  title: {
    fontWeight: theme.typography.fontWeightExtraBold,
    fontSize: theme.typography.h4.fontSize,
    letterSpacing: theme.spacing(0.125),
    textTransform: 'uppercase',
  },
  subtitle: {
    fontSize: theme.typography.subtitle1.fontSize,
    textAlign: 'center',
  },
  categories: {
    fontWeight: theme.typography.fontWeightMedium,
    fontSize: theme.typography.h5.fontSize,
  },
  validateButton: {
    color: theme.palette.common.white,
    fontWeight: theme.typography.fontWeightBold,
  },
  return: {
    fontWeight: theme.typography.fontWeightBold,
  },
  progress: {
    marginBottom: theme.aux.spacing.xs,
  },
  deliver: {
    fontsize: theme.typography.body2.fontSize,
    opacity: 0.5,
  },
  commentContainer: {
    paddingBottom: theme.aux.spacing.s,
  },
  returnButton: {
    backgroundColor: theme.palette.common.white,
  },
  tableContainer: {
    paddingLeft: theme.aux.spacing.s,
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.aux.spacing.s,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  globalItemStyle: {
    fontSize: theme.typography.small.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  itemContainer: {
    paddingTop: theme.aux.spacing.xs,
    paddingBottom: theme.aux.spacing.xs,
  },
  mobileItemLabel: {
    paddingRight: theme.aux.spacing.xs,
  },
}));
