import { KeyboardDatePicker } from 'mui-rff';
import React from 'react';
import { useTranslation } from 'react-i18next';

interface IProps {
  name?: string;
}

export const BirthdateDatePicker: React.FC<IProps> = ({
  name = 'birthDate',
}) => {
  const { t } = useTranslation();
  return (
    <KeyboardDatePicker
      name={name}
      label={t('profile-form.birthdate')}
      disableFuture
      openTo="year"
      format="dd/MM/yyyy"
      autoOk
      variant="inline"
      inputVariant="outlined"
      size="small"
      fullWidth
      views={['year', 'month', 'date']}
    />
  );
};
