import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import { Form, Field } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { DropzoneForm } from 'common/components/dropzone';
import { CircularProgressWithLabel } from 'common/components/circular-progress-label';

interface IProps {
  organizationId?: string;
  isOpen: boolean;
  loading?: boolean;
  uploadProgress?: number;
  onClose: () => void;
  title: string;
  currentPicture?: string;
  onSubmit: (values: { image: File }) => Promise<void>;
}

export const PictureModal: React.FC<IProps> = ({
  isOpen,
  onClose,
  currentPicture,
  uploadProgress,
  onSubmit,
  title,
  loading,
}) => {
  const { t } = useTranslation();

  const handleClose = () => {
    onClose();
  };
  return (
    <Dialog open={isOpen} onClose={handleClose}>
      <Form
        onSubmit={onSubmit}
        render={({ handleSubmit, submitting }) => {
          return (
            <form onSubmit={handleSubmit}>
              <DialogTitle>{title}</DialogTitle>
              <DialogContent>
                <Field
                  name="image"
                  label={t('screen.establishment.image')}
                  render={({ input }) => {
                    return (
                      <DropzoneForm
                        input={input}
                        picture={currentPicture}
                        accepteType={['image/*', 'application/pdf']}
                      />
                    );
                  }}
                />
              </DialogContent>
              <DialogActions>
                {!loading && (
                  <Button
                    variant="contained"
                    onClick={handleClose}
                    color="default"
                  >
                    {t('common.cancel')}
                  </Button>
                )}
                <Button
                  variant="contained"
                  type="submit"
                  color="primary"
                  disabled={submitting || loading}
                >
                  {loading && uploadProgress ? (
                    <CircularProgressWithLabel
                      value={uploadProgress}
                      size={52}
                    />
                  ) : (
                    t('common.save')
                  )}
                </Button>
              </DialogActions>
            </form>
          );
        }}
      />
    </Dialog>
  );
};
