import { Theme } from '@material-ui/core';
import { IActionBoxStyle } from 'common/components';

export const actionBox = (theme: Theme): IActionBoxStyle => ({
  container: {
    display: 'flex',
    padding: theme.aux.spacing.s,
    paddingBottom: theme.aux.spacing.m,
    textAlign: 'center',
    justifyContent: 'center',
  },
  btn: {
    alignContent: 'flex-end',
  },
  btnText: {
    paddingLeft: theme.aux.spacing.s,
    paddingRight: theme.aux.spacing.s,
    fontWeight: theme.typography.fontWeightBold,
    textTransform: 'uppercase',
  },
  content: {
    maxWidth: '200px',

    [theme.breakpoints.up('sm')]: {
      maxWidth: '300px',
    },
    [theme.breakpoints.up('md')]: {
      maxWidth: '600px',
    },
  },
  text: {
    paddingBottom: theme.aux.spacing.s,
    paddingLeft: theme.aux.spacing.s,
    paddingRight: theme.aux.spacing.s,
  },
  title: {
    paddingBottom: theme.aux.spacing.s,
  },
});
