import { Theme } from '@material-ui/core';
import { IDescriptionBoxStyle } from 'common/components';

export const descriptionBox = (theme: Theme): IDescriptionBoxStyle => ({
  container: {
    textAlign: 'center',
    justifyContent: 'center',
  },
  title: {
    fontWeight: theme.typography.fontWeightBlack,
    color: theme.palette.common.black,
    textTransform: 'uppercase',
    paddingTop: theme.aux.spacing.m,
    paddingBottom: theme.aux.spacing.s,
    letterSpacing: 1,
  },
  subTitle: {
    color: theme.palette.common.black,
    fontWeight: theme.typography.fontWeightMedium,

    paddingBottom: theme.aux.spacing.s,
    letterSpacing: 0.75,
  },
  text: {
    fontWeight: theme.typography.fontWeightLight,
    fontSize: theme.typography.small.fontSize,
    color: theme.palette.common.black,
  },
});
