import { Grid } from '@material-ui/core';
import { AddressFormSimple, ProfileForm } from 'common/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { IAddress, Person } from 'sherl-react-sdk';

export const ClientFormProfileAddress: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const { getPerson, personLoading, person, updateUser } = Person.usePerson();

  useEffect(() => {
    getPerson(id, false);
    // eslint-disable-next-line
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <ProfileForm
          title={t('client-form-profile-address.profile-form.title')}
          subtitle={t('client-form-profile-address.profile-form.subtitle')}
          user={person}
          update={updateUser}
          loading={personLoading}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <AddressFormSimple
          title={t('client-form-profile-address.address-form.title')}
          subtitle={t('client-form-profile-address.address-form.subtitle')}
          address={person?.address}
          onSubmit={async (data: Partial<IAddress>) =>
            updateUser(id, { address: data })
          }
          loading={personLoading}
        />
      </Grid>
    </Grid>
  );
};
