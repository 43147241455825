import { Theme } from '@material-ui/core';
import { IStylePictureStyle } from 'common/components';

export const stylePicture = (theme: Theme): IStylePictureStyle => ({
  container: {
    padding: theme.aux.spacing.sm,
    justifyContent: 'center',
  },
  pictureContainer: {
    height: '65px',
    width: '65px',
    border: '1px solid',
  },
  square: {
    height: '65px',
    width: '65px',
    borderColor: theme.palette.common.black,
    border: '1px solid',
    transform: 'rotate(45deg)',
    position: 'absolute',
  },
});
