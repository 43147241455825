import { Box, Button, Grid, Typography } from '@material-ui/core';
import { ChevronRight, Delete, Edit } from '@material-ui/icons';
import React from 'react';
import { useStyles } from '../styles/catalog.styles';

interface IProps {
  categoryName: string;
  categoryId: string;
  deleteCategory: (categoryId: string, name: string) => void;
  handleOpenForm: () => void;
  isSubCategory?: boolean;
}

export const CatalogCategoryItemComponent: React.FC<IProps> = (props) => {
  const {
    categoryName,
    categoryId,
    deleteCategory,
    handleOpenForm,
    isSubCategory,
  } = props;

  const styles = useStyles();

  return (
    <Grid container justify="space-between" alignItems="center">
      <Grid item xs={12} sm="auto">
        <Typography>{categoryName}</Typography>
      </Grid>
      <Grid item>
        <Box display="flex">
          <Button onClick={handleOpenForm}>
            <Edit fontSize="small" color="primary" />
          </Button>
          <Button onClick={() => deleteCategory(categoryId, categoryName)}>
            <Delete fontSize="small" color="primary" />
          </Button>
          {!isSubCategory && (
            <Box
              component="span"
              alignSelf="flex-end"
              className={styles.chevron}
            >
              <ChevronRight fontSize="small" color="primary" />
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
};
