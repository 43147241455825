import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';
import { format } from 'common/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBugReport } from 'sherl-react-sdk';
import { useStyles } from '../styles/reports.style';

interface IProps {
  report: IBugReport;
  toggleDialog: () => void;
  open: boolean;
}

export const ReportDetailsDialog: React.FC<IProps> = ({
  toggleDialog,
  open,
  report,
}) => {
  const styles = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog onClose={toggleDialog} open={open}>
      <Box display="flex" justifyContent="center" marginTop={2}>
        <Typography className={styles.dialogTitle}>
          {t('report-list.check-report')}
        </Typography>
      </Box>
      <DialogContent className={styles.dialogContainer}>
        <List>
          <ListItem>
            <Box>
              <Typography className={styles.dialogLabel}>
                {t('report-list.system')}
              </Typography>
              <Typography className={styles.dialogLight}>
                {report.osName ? report.osName : ''}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography className={styles.dialogLabel}>
                {t('report-list.browser')}
              </Typography>
              <Typography className={styles.dialogLight}>
                {report.browserName ? report.browserName : ''}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography className={styles.dialogLabel}>
                {t('report-list.screen-resolution')}
              </Typography>
              <Typography className={styles.dialogContent}>
                {report.windowWidth && report.windowHeight
                  ? `${report.windowWidth} * ${report.windowHeight}`
                  : ''}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box>
              <Typography className={styles.dialogLabel}>
                {t('report-list.date')}
              </Typography>
              <Typography className={styles.dialogContent}>
                {format(new Date(report.createdAt), 'EEE dd LLL yyyy HH:mm')}
              </Typography>
            </Box>
          </ListItem>
          <ListItem>
            <Box display="flex" flexDirection="column">
              <Typography className={styles.dialogLabel}>
                {t('report-list.message')}
              </Typography>
              <Typography className={styles.dialogContent}>
                {report.message ? report.message : ''}
              </Typography>
            </Box>
          </ListItem>
        </List>
        <DialogActions className={styles.dialogActionContainer}>
          <Button variant="outlined" color="primary" onClick={toggleDialog}>
            {t('common.return')}
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
