import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { CustomRating, Status } from 'common/components';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { IOpinion, RateStatus } from 'sherl-react-sdk';
import { useStyles } from '../styles/appointment.review.style';

interface IProps {
  opinion: IOpinion<any, any>;
}

const RateStatusMapping = {
  published: RateStatus.STATUS_PUBLIC,
  refused: RateStatus.STATUS_REFUSED,
};

const ReviewItem: React.FC<IProps> = memo(({ opinion }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const theme = useTheme();
  const classNames = useStyles();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Grid
      container
      className={clsx(classNames.globalItemStyle, classNames.itemContainer)}
      spacing={1}
      alignItems="center"
    >
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="center">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('review.author')}
            </Typography>
          )}
          {opinion.opinionFrom?.picture?.thumbnail?.caption?.contentUrl && (
            <Avatar
              src={opinion.opinionFrom.picture.thumbnail.caption.contentUrl}
            />
          )}
          {opinion.opinionFrom?.displayed?.logoImg &&
            !opinion.opinionFrom.firstName && (
              <Avatar src={opinion.opinionFrom.displayed.logoImg} />
            )}
          <Typography>
            {opinion.opinionFrom.firstName}
            {opinion.opinionFrom.lastName}
            {opinion.opinionFrom.legalName}
            {'  '}
            {opinion.opinionFrom.firstName && <b>{t('review.customer')}</b>}
            {opinion.opinionFrom.legalName && <b>{t('review.presta')}</b>}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('review.receiver')}
            </Typography>
          )}
          <Typography>
            {opinion.opinionTo.firstName} {opinion.opinionTo.lastName}
            {opinion.opinionTo.legalName}
            {'  '}
            {opinion.opinionTo.firstName && <b>{t('review.customer')}</b>}
            {opinion.opinionTo.legalName && <b>{t('review.presta')}</b>}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('review.rate')}
            </Typography>
          )}
          <CustomRating max={5} value={opinion.score} readOnly />
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={classNames.mobileItemLabel}>
              {t('review.status')}
            </Typography>
          )}
          <Status
            status={RateStatusMapping[opinion.status]}
            typeRate="given"
            children={
              opinion.status === 'refused' ? (
                <Typography
                  style={{ color: theme.palette.error.main }}
                  variant="caption"
                >
                  {'  '}({opinion.refusedComment})
                </Typography>
              ) : null
            }
          />
        </Box>
      </Grid>
      <Grid item md={4}>
        <Grid container justify="flex-end">
          <Grid item>
            <Tooltip title={t('review.check.title') as string}>
              <Link
                to={{
                  pathname: `${location.pathname}/${opinion.id}/check`,
                  state: { background: location },
                }}
              >
                <IconButton>
                  <Visibility color="primary" />
                </IconButton>
              </Link>
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
});

export default ReviewItem;
