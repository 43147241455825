import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { getErrors } from 'common/helpers';
import { FORM_ERROR, SubmissionErrors } from 'final-form';
import { TextField } from 'mui-rff';
import React from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { Shop } from 'sherl-react-sdk';

export interface IFormData {
  name: string;
  id?: string;
}

interface IProps {
  parentCategoryId: string | undefined;
  isOpen: string;
  onClose: (id: string) => void;
  addOrUpdateCategory: (
    values: IFormData,
    parentCategoryId?: string,
    categoryId?: string,
  ) => any;
}

export const CategoryForm: React.FC<IProps> = (props) => {
  const { addOrUpdateCategory, isOpen, onClose, parentCategoryId } = props;
  const { getCategoryById } = Shop.useCategory();
  const { t } = useTranslation();

  const customOnSubmit = (categoryId, categoryParentId?) => (values: any) => {
    addOrUpdateCategory(values, categoryParentId, categoryId);
    onClose('close');
  };
  const categoryToUpdate = getCategoryById(isOpen);
  const validate = async (values: IFormData) => {
    const errors: SubmissionErrors = {};
    if (!values.name) {
      errors.name = t('common.form.errors.field-required', {
        field: t('common.name'),
      });
    }
    return errors;
  };

  return (
    <Dialog open={!!isOpen} onClose={() => onClose('close')}>
      <DialogTitle>
        {categoryToUpdate
          ? t('catalog.edit-category')
          : t('catalog.add-category')}
      </DialogTitle>
      <DialogContent>
        <Form
          onSubmit={customOnSubmit(
            categoryToUpdate ? categoryToUpdate.id : '',
            parentCategoryId,
          )}
          validate={validate}
          initialValues={categoryToUpdate ? categoryToUpdate : { name: '' }}
          render={({
            errors,
            submitFailed,
            submitErrors,
            handleSubmit,
            dirtySinceLastSubmit,
            valid,
            submitting,
          }) => {
            const errorsToShow = getErrors(
              errors,
              submitFailed,
              submitErrors,
              dirtySinceLastSubmit,
            );
            return (
              <form onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <TextField
                      name="name"
                      required
                      size="small"
                      label={t('common.name')}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="space-around">
                      <Button color="primary" onClick={() => onClose('close')}>
                        {t('common.cancel')}
                      </Button>
                      <Button
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={!valid || submitting}
                      >
                        {t('common.send')}
                      </Button>
                    </Box>
                    <Grid item xs={12} style={{ marginTop: 10 }}>
                      {errorsToShow[FORM_ERROR] && (
                        <Alert severity="error">
                          {errorsToShow[FORM_ERROR]}
                        </Alert>
                      )}
                    </Grid>
                  </Grid>
                </Grid>
              </form>
            );
          }}
        />
      </DialogContent>
    </Dialog>
  );
};
