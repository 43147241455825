import {
  Box,
  Button,
  Card,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { TFunction } from 'i18next';
import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IOrganization } from 'sherl-react-sdk';
import useStyle from '../styles/establishment.list.style';
import EstablishmentListItem from './establishment.list.item.component';

interface IProps {
  establishments: IOrganization[] | undefined;
  t: TFunction;
  searchEstablishment: (event: any) => void;
  isLoading: boolean;
  loadingMore: boolean;
  handleNextPage: () => void;
}

const EstablishmentListView: React.FC<IProps> = ({
  establishments,
  t,
  isLoading,
  loadingMore,
  handleNextPage,
}) => {
  const history = useHistory();
  const location = useLocation();
  const styles = useStyle();
  const theme = useTheme();
  const addOrganization = () =>
    history.push(`${location.pathname}/add`, { background: location });

  return (
    <Card elevation={5} style={{ flex: 1, padding: theme.spacing(2) }}>
      <Grid container>
        <Grid item xs={12}>
          <Grid
            container
            direction="row"
            alignItems="center"
            justify="space-between"
            style={{
              padding: '1rem',
            }}
          >
            <Grid item xs={12} md="auto">
              <Typography variant="h4" className={styles.title}>
                <b>{t('providers-listing.title')}</b>
              </Typography>
              <Typography variant="caption">
                {t('providers-listing.title-caption')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                onClick={addOrganization}
                color="primary"
                variant="contained"
                endIcon={<Add />}
              >
                {t('providers-listing.add')}
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} className={styles.tableContainer}>
          <Grid container className={styles.header}>
            <Grid item md={3}>
              {t('common.form.firstName')}
            </Grid>
            <Grid item md={2}>
              {t('common.form.lastName')}
            </Grid>
            <Grid item md={2}>
              {t('common.form.birthDate')}
            </Grid>
            <Grid item md={3}>
              {t('common.form.legalName')}
            </Grid>
          </Grid>
          {(establishments || []).map((item, index) => {
            if (index !== (establishments || []).length) {
              return (
                <React.Fragment key={item.id}>
                  <EstablishmentListItem establishment={item} />
                  {establishments && establishments[index + 1] && <Divider />}
                </React.Fragment>
              );
            }
            return (
              <EstablishmentListItem
                establishment={item}
                key={`${item.id}-${index}`}
              />
            );
          })}
          {loadingMore && !isLoading && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center" py={2}>
                <Button
                  size="small"
                  color="primary"
                  variant="outlined"
                  onClick={handleNextPage}
                >
                  {t('orders-list.load-more')}
                </Button>
              </Box>
            </Grid>
          )}
          {isLoading && (
            <Grid
              container
              style={{ padding: theme.spacing(3) }}
              justify="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}
        </Grid>
      </Grid>
    </Card>
  );
};

export default EstablishmentListView;
