import { Theme } from '@material-ui/core';
import { ThemeProvider as MuiThemeProvider } from '@material-ui/styles';
import { themes } from 'assets';
import React from 'react';
import { Config } from 'sherl-react-sdk';

const DEFAULT_THEME = 'default';

export const ThemeProvider: React.FC<{ children: any }> = ({ children }) => {
  const themeKey = Config.ConfigService.get('theme') || DEFAULT_THEME;
  const theme: Theme =
    themeKey in themes ? themes[themeKey] : themes[DEFAULT_THEME];

  if (theme) {
    return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
  }

  return null;
};
