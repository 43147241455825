import React, { useEffect, useState } from 'react';
import { initialize, Config } from 'sherl-react-sdk';
import RoutesConfig from 'config/routes.config';

export const GlobalConfigContainer: React.FC<{ children: any }> = ({
  children,
}) => {
  const { getApiConfig } = Config.useConfig();
  const [isReady, setIsReady] = useState<boolean>(false);

  useEffect(() => {
    initialize(
      '79ab369d-67a6-469b-9502-c9204eae0044',
      'ff384c7d-5ad1-4878-b371-1ea60d64b18a',
      'sherl',
    );

    const hostname = window.location.hostname;
    getApiConfig(hostname).then((conf) => {
      console.warn('config', conf);
      Config.ConfigService.init(conf);
      initialize(
        Config.ConfigService.get('apiKey'),
        Config.ConfigService.get('apiSecret'),
        Config.ConfigService.get('instanceName'),
      );
      Config.ConfigService.set(
        'websiteUrl',
        `${window.location.protocol}//${window.location.host}`,
      );

      // Get route configuration
      const customRoutesConfig = Config.ConfigService.get('routesConfig');
      if (customRoutesConfig) {
        Object.assign(RoutesConfig, customRoutesConfig);
      }

      setIsReady(true);
    });
  }, []);

  if (isReady) {
    return children;
  }
  return null;
};
