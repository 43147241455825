import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Add } from '@material-ui/icons';
import { RequireAccountType } from 'common/components';
import { useGridConfiguration } from 'common/hooks';
import RoutesConfig from 'config/routes.config';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { IOrder } from 'sherl-react-sdk';
import { useStyles } from '../styles/orders.list.styles';
import { OrdersListItem } from './order.list.item.component';

interface IProps {
  ordersList: { [key: string]: IOrder };
  getNextPage: () => void;
  loading: boolean;
  total: number;
}

export const OrdersListComponent: React.FC<IProps> = ({
  ordersList,
  getNextPage,
  loading,
  total,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const { columns } = useGridConfiguration('orders');

  return (
    <Card style={{ padding: theme.aux?.spacing?.s }}>
      <CardContent>
        <Grid container>
          <Grid item xs={12}>
            <Grid container justify="space-between">
              <Grid item xs={12} md={12}>
                <Grid container alignItems="flex-start">
                  <Grid item xs={12} md={4}>
                    <Box display="flex">
                      <Typography variant="h4" className={styles.title}>
                        {t('orders-list.title')}
                      </Typography>
                    </Box>
                  </Grid>
                  <RequireAccountType
                    requiredAccountTypes={
                      RoutesConfig['screens-options'].orders.header.add
                    }
                  >
                    <Grid item xs={12} md={8}>
                      <Box display="flex" justifyContent="flex-end">
                        <Link
                          to={`/app/orders/create`}
                          style={{ textDecoration: 'none' }}
                        >
                          <Button color="primary" variant="contained">
                            <Add fontSize="small" />
                            {t('orders-list.add-project')}
                          </Button>
                        </Link>
                      </Box>
                    </Grid>
                  </RequireAccountType>
                  <Grid item xs={12}>
                    <Typography variant="caption">
                      {t('orders-list.orders-caption')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!isMobile && (
            <Grid item xs={12} style={{ marginTop: theme.aux?.spacing?.m }}>
              <Grid container className={styles.header}>
                {Object.keys(columns).map((value) => {
                  if (columns[value].enabled) {
                    return (
                      <RequireAccountType
                        enabled={!!columns[value]?.accountTypeFiltered}
                        requiredAccountTypes={columns[value]?.accountType || []}
                      >
                        <Grid item md={columns[value].size}>
                          {t(`orders-list.${value}`)}
                        </Grid>
                      </RequireAccountType>
                    );
                  }
                  return null;
                })}
              </Grid>
            </Grid>
          )}
          {Object.values(ordersList) &&
            Object.values(ordersList).length > 0 &&
            Object.values(ordersList).map((order) => (
              <React.Fragment key={order.id}>
                <OrdersListItem order={order} isMobile={isMobile} />
                {isMobile && <Divider light className={styles.fullWidth} />}
              </React.Fragment>
            ))}
          {!loading && Object.values(ordersList).length !== total && (
            <Grid item xs={12}>
              <Box display="flex" justifyContent="center">
                <Button
                  size="small"
                  fullWidth={isMobile}
                  color="primary"
                  variant="outlined"
                  onClick={getNextPage}
                >
                  {t('orders-list.load-more')}
                </Button>
              </Box>
            </Grid>
          )}
          {loading && (
            <Grid
              container
              style={{ padding: theme.aux.spacing.m }}
              justify="center"
              alignItems="center"
            >
              <CircularProgress />
            </Grid>
          )}

          {!Object.values(ordersList) ||
            (Object.values(ordersList).length === 0 && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Typography>{t('common.no-data-found')}</Typography>
                </Box>
              </Grid>
            ))}
        </Grid>
      </CardContent>
    </Card>
  );
};
