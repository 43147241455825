import {
  Avatar,
  Box,
  Button,
  Grid,
  ListItemAvatar,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from '@material-ui/core';
import { Mail } from '@material-ui/icons';
import clsx from 'clsx';
import {
  ConfirmDialog,
  PayoutWithAmountDialog,
  RequireAccountType,
  useConfirm,
} from 'common/components';
import { format } from 'common/helpers';
import { useGridConfiguration } from 'common/hooks';
import routesConfig from 'config/routes.config';
import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { IOrder, Order, OrderStatusEnum, Shop } from 'sherl-react-sdk';
import { useStyles } from '../styles/orders.list.styles';

interface IProps {
  order: IOrder;
  isMobile: boolean;
}

export const OrdersListItem: React.FC<IProps> = (props) => {
  const { order, isMobile } = props;
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const { updateStatus } = Order.useOrder(order.id);
  const { columns, actions } = useGridConfiguration<'orders'>('orders');
  const { itemToDelete: orderId, handleClickOpen, handleClose } = useConfirm();
  const { sendLinkToPayOnline } = Shop.useInvoice();
  const handleConfirm = () => {
    if (orderId) {
      try {
        sendLinkToPayOnline(orderId);
        handleClose();
        toast.success(t('orders-list.bill-sent-success'));
      } catch (err) {
        toast.error(t('common.form.failed'));
      }
    }
  };
  const badgeStatusMap = useMemo(() => {
    switch (order.orderStatus) {
      case 1000:
        // Paid
        return { color: theme.palette.tertiary.main, text: 'paid' };
      case 1050:
        // Payout
        return { color: theme.palette.quaternary.main, text: 'payout' };
      case 300:
        // Error paiement
        return { color: theme.palette.secondary.main, text: 'error-payment' };
      case 0:
      case 100:
      case 200:
        // Waiting
        return { color: theme.palette.quinary.main, text: 'pending' };
      default:
        return { color: theme.palette.error.main, text: 'error' };
    }
  }, [theme, order]);

  const renderBadge = (text: string, color: string) => (
    <Typography
      style={{
        backgroundColor: color,
        paddingTop: 4,
        borderRadius: 15,
        paddingBottom: 4,
        paddingLeft: 10,
        paddingRight: 10,
      }}
      color="textPrimary"
    >
      {text}
    </Typography>
  );
  console.log(OrderStatusEnum.PAYED);

  return (
    <Grid
      container
      className={clsx(styles.globalItemStyle, styles.itemContainer)}
      spacing={1}
      alignItems="center"
    >
      {columns.ref.enabled && (
        <Grid item md={columns.ref.size} xs={12}>
          <Box display="flex" alignItems="center">
            <Typography>{order.orderNumber}</Typography>
          </Box>
        </Grid>
      )}
      {columns.label.enabled && (
        <Grid item md={columns.label.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('orders-list.label')}
              </Typography>
            )}
            {order.orderedItems[0].product.name}
          </Box>
        </Grid>
      )}
      {columns.provider.enabled && (
        <Grid item md={columns.provider.size} xs={12}>
          <Box display="flex" alignItems="center">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('common.provider')}
              </Typography>
            )}
            {order.organization.logo &&
              order.organization.logo.caption?.contentUrl && (
                <ListItemAvatar>
                  <Avatar
                    sizes="small"
                    src={order.organization.logo.caption.contentUrl}
                    className={styles.avatar}
                  />
                </ListItemAvatar>
              )}
            <Typography className={styles.lastName}>
              &nbsp;
              {order.organization.legalName ? order.organization.legalName : ''}
            </Typography>
          </Box>
        </Grid>
      )}
      {columns.client.enabled && (
        <Grid item md={columns.client.size} xs={12}>
          <Box display="flex" alignItems="center">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('orders-list.client')}
              </Typography>
            )}
            {order.customer.picture &&
              order.customer.picture.caption?.contentUrl && (
                <ListItemAvatar>
                  <Avatar
                    sizes="small"
                    src={order.customer.picture.caption.contentUrl}
                    className={styles.avatar}
                  />
                </ListItemAvatar>
              )}
            <Typography>
              <span>{order.customer.firstName}</span>
              <span className={styles.lastName}>
                &nbsp; {order.customer.lastName}
              </span>
            </Typography>
          </Box>
        </Grid>
      )}

      {columns.date.enabled && (
        <Grid item md={columns.date.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('orders-list.date')}
              </Typography>
            )}
            {format(new Date(order.createdAt), 'EEE dd LLL yyyy - HH:mm')}
          </Box>
        </Grid>
      )}
      {columns.amount.enabled && (
        <Grid item md={columns.amount.size} xs={12}>
          <Box display="flex" alignItems="baseline">
            {isMobile && (
              <Typography className={styles.mobileItemLabel}>
                {t('orders-list.amount')}
              </Typography>
            )}
            {`${order.priceTaxIncluded}${t(
              'common-components.price.currency',
            )}`}
          </Box>
        </Grid>
      )}
      {columns.statusUpdate.enabled && (
        <RequireAccountType
          enabled={
            !!routesConfig['screens-options'].orders.columns.statusUpdate
              ?.accountTypeFiltered
          }
          requiredAccountTypes={
            routesConfig['screens-options'].orders.columns.statusUpdate
              .accountType
          }
        >
          <Grid item md={columns.status.size} xs={12}>
            <Box display="flex" alignItems="center">
              <Select
                labelId="demo-customized-select-label"
                id="demo-customized-select"
                value={order.orderStatus}
                renderValue={() =>
                  renderBadge(
                    t(`orders-list.${badgeStatusMap.text}`),
                    badgeStatusMap.color,
                  )
                }
                onChange={({ target }) => {
                  updateStatus(target.value as number);
                }}
              >
                {order.orderStatus <= 200 && (
                  <MenuItem value={200}>
                    {renderBadge(
                      t(`orders-list.pending`),
                      theme.palette.quinary.main,
                    )}
                  </MenuItem>
                )}

                <MenuItem value={400}>
                  {renderBadge(
                    t(`orders-list.paid`),
                    theme.palette.tertiary.main,
                  )}
                </MenuItem>
                <MenuItem value={300}>
                  {renderBadge(
                    t(`orders-list.error-payment`),
                    theme.palette.secondary.main,
                  )}
                </MenuItem>
              </Select>
            </Box>
          </Grid>
        </RequireAccountType>
      )}
      {columns.status.enabled && (
        <RequireAccountType
          enabled={
            !!routesConfig['screens-options'].orders.columns.status
              ?.accountTypeFiltered
          }
          requiredAccountTypes={
            routesConfig['screens-options'].orders.columns.status.accountType
          }
        >
          <Grid item md={columns.status.size} xs={12}>
            <Box display="flex" alignItems="center">
              {renderBadge(
                t(`orders-list.${badgeStatusMap.text}`),
                badgeStatusMap.color,
              )}
            </Box>
          </Grid>
        </RequireAccountType>
      )}
      {columns['means-of-payment'].enabled && (
        <Grid item md={columns['means-of-payment'].size} xs={12}>
          {order.meansOfPayment
            ? t(`orders-list.${order.meansOfPayment}`)
            : '-'}
        </Grid>
      )}

      <Grid item md={actions.size} xs={12}>
        {actions.sendBill.enabled && (
          <RequireAccountType
            enabled={!!actions.sendBill?.accountTypeFiltered}
            requiredAccountTypes={actions.sendBill?.accountType || []}
          >
            <Button
              variant="text"
              color="primary"
              startIcon={<Mail />}
              disabled={order.orderStatus >= OrderStatusEnum.PAYED}
              onClick={() => handleClickOpen(order.id)}
            >
              {t(`orders-list.sendBill`)}
            </Button>
          </RequireAccountType>
        )}
        <RequireAccountType enabled={true} requiredAccountTypes={['ADMIN']}>
          <>
            {order.orderStatus >= OrderStatusEnum.PAYED && (
              <PayoutWithAmountDialog id={order.id} />
            )}
          </>
        </RequireAccountType>
      </Grid>

      <ConfirmDialog
        title={t('orders-list.send-bill-confirm')}
        message={''}
        isOpen={!!orderId}
        onClose={handleClose}
        onConfirm={handleConfirm}
      />
    </Grid>
  );
};
