import Config from 'config/routes.config';

type GridConfigurationEnum = keyof typeof Config['screens-options'];

export const useGridConfiguration = <T extends GridConfigurationEnum>(
  key: GridConfigurationEnum,
) => {
  const columns: typeof Config['screens-options'][T]['columns'] =
    Config['screens-options'][key].columns;

  const actions: typeof Config['screens-options'][T]['actions'] =
    Config['screens-options'][key].actions;

  return {
    columns,
    actions,
  };
};
