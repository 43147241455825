import { validateEmail } from 'common/helpers';
import { SubmissionErrors } from 'final-form';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Person } from 'sherl-react-sdk';
import { IFormData } from '../components/administrator.form.component';
import { AdministratorsComponent } from '../components/administrators.component';

export const Administrators: React.FC = () => {
  const { t } = useTranslation();
  const {
    getPersons,
    loading,
    total,
    error,
    personsByType,
  } = Person.usePersons();
  const { addSuperAdmin, deletePerson } = Person.usePerson();
  const [isOpenAddAdmin, setIsOpenAddAdmin] = useState(false);
  const [page, setPage] = useState<number>(1);

  useEffect(() => {
    getPersons(page, 1, { type: 'ADMIN' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const handleNextPage = () => {
    setPage(page + 1);
  };

  const handleAddAdminDialog = (open?: boolean) => {
    if (open === true || false) {
      setIsOpenAddAdmin(open);
    } else {
      setIsOpenAddAdmin(!isOpenAddAdmin);
    }
  };

  const onSubmit = async (values: IFormData): Promise<any> => {
    const { lastName, firstName, email, enabled = true } = values;
    let response: any;
    try {
      response = await addSuperAdmin({
        lastName,
        firstName,
        email,
        enabled,
      });

      if (response.email === undefined) {
        throw new Error();
      }
      toast.success(t('common.notification.add-success'));
    } catch (err) {
      toast.error(t('common.add-failed', { message: err }));
    }

    setIsOpenAddAdmin(false);
    return {};
  };

  const deleteAdminAccount = async (adminId: string) => {
    try {
      await deletePerson(adminId);
    } catch (err) {
      toast.error(t('common.notification.delete-failed', { message: err }));
    }

    return {};
  };

  const validate = async (values: IFormData) => {
    const errors: SubmissionErrors = {};

    if (!values.firstName) {
      errors.firstName = t('common.form.errors.field-required', {});
    }
    if (!values.lastName) {
      errors.lastName = t('common.form.errors.field-required', {});
    }
    if (!validateEmail(values.email)) {
      errors.email = t('common.form.errors.formatEmailError');
    }
    return errors;
  };

  return (
    <AdministratorsComponent
      isLoading={loading['list']}
      error={error['list']}
      loadingMore={personsByType('ADMIN').length !== total}
      administrators={personsByType('ADMIN')}
      deleteAdminAccount={deleteAdminAccount}
      validate={validate}
      onSubmit={onSubmit}
      handleAddAdminDialog={handleAddAdminDialog}
      isOpenAddAdmin={isOpenAddAdmin}
      handleNextPage={handleNextPage}
    />
  );
};
