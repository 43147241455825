import { Button, Grid, Typography, useTheme } from '@material-ui/core';
import ExitToApp from '@material-ui/icons/ExitToApp';
import { useImpersonateToken } from 'common/hooks';
import Config from 'config/routes.config';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const Impersonate: React.FC = () => {
  const { t } = useTranslation();
  const [isMount, setMount] = useState(false);
  const [isVisible, setVisible] = useState(false);
  const theme = useTheme();
  const { isImpersonate } = useImpersonateToken();

  const displayImpersonateLogout = () => {
    if (isImpersonate) {
      setVisible(true);
    } else {
      setVisible(false);
    }
  };

  useEffect(() => {
    setMount(true);
    displayImpersonateLogout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isImpersonate]);

  const logoutImpersonate = () => {
    window.localStorage.removeItem('impersonateToken');
    window.location.href = `${Config.baseRoutePrefix}${Config.initialPath}`;
  };

  return (
    <>
      {isMount && isVisible && (
        <Grid item>
          <Grid container>
            <Grid>
              <Button
                startIcon={<ExitToApp />}
                size="large"
                variant="contained"
                style={{
                  backgroundColor: theme.palette.error.main,
                  color: theme.palette.common.white,
                }}
                onClick={logoutImpersonate}
              >
                <Typography
                  style={{ color: theme.palette.common.white }}
                  noWrap
                >
                  {t('impersonate.logout-button')}
                </Typography>
              </Button>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};
