import { Box, Button, Grid, Typography } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { format } from 'common/helpers';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { IBugReport } from 'sherl-react-sdk';
import { useStyles } from '../styles/reports.style';

interface IProps {
  report: IBugReport;
  isMobile: boolean;
  handleReportDetailsDialog: (report: IBugReport) => void;
}

export const ReportsItem: React.FC<IProps> = (props) => {
  const { report, isMobile, handleReportDetailsDialog } = props;
  const { t } = useTranslation();
  const styles = useStyles();

  return (
    <Grid
      container
      className={clsx(styles.globalItemStyle, styles.itemContainer)}
      alignItems="center"
    >
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="center">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.system')}
            </Typography>
          )}
          <Typography className={styles.globalItemStyle}>
            {report.osName ? report.osName : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.browser')}
            </Typography>
          )}
          <Typography className={styles.globalItemStyle}>
            {report.browserName ? report.browserName : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={1} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.screen-resolution')}
            </Typography>
          )}
          <Typography className={styles.globalItemStyle}>
            {report.windowWidth && report.windowHeight
              ? `${report.windowWidth} * ${report.windowHeight}`
              : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item md={2} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.date')}
            </Typography>
          )}
          {format(new Date(report.createdAt), 'EEE dd LLL yyyy')}
        </Box>
      </Grid>
      <Grid item md={1} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.hour')}
            </Typography>
          )}
          {format(new Date(report.createdAt), 'HH:mm')}
        </Box>
      </Grid>
      <Grid item md={3} xs={12}>
        <Box display="flex" alignItems="baseline">
          {isMobile && (
            <Typography className={styles.mobileItemLabel}>
              {t('report-list.contact')}
            </Typography>
          )}
          <Typography className={styles.globalItemStyle}>
            {report.contactEmail ? report.contactEmail : ''}
          </Typography>
        </Box>
      </Grid>
      <Grid item>
        <Button onClick={() => handleReportDetailsDialog(report)}>
          <VisibilityIcon fontSize="small" color="primary" />
        </Button>
      </Grid>
    </Grid>
  );
};
