import { Box, Card, CardContent } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardHeader } from 'common/components';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useStyles } from '../styles/payment.style';
import { Layout } from './layout.component';

export const PaymentCancel: React.FC = () => {
  const styles = useStyles();
  const { t } = useTranslation();
  return (
    <Layout>
      <Card>
        <CardHeader title={t('anonymous-payment.payment-cancel.title')} />
        <CardContent>
          <Box display="flex" width="100%" justifyContent="center">
            <Alert severity="error">
              {t('anonymous-payment.payment-cancel.text')}
            </Alert>
          </Box>
        </CardContent>
      </Card>
    </Layout>
  );
};
