import { library } from '@fortawesome/fontawesome-svg-core';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Typography,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CardHeader } from 'common/components';
import { BirthdateDatePicker } from 'common/components/birthdate-datepicker';
import { getErrors } from 'common/helpers';
import { format } from 'date-fns';
import { FORM_ERROR } from 'final-form';
import { TextField } from 'mui-rff';
import React, { useEffect, useState } from 'react';
import { Form } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { IPerson } from 'sherl-react-sdk';
import { IFormData } from '../entities/profile-form.entity';
import { useValidate } from '../hooks/profile-form.hook';

library.add(faUser);

interface IProps {
  title?: string;
  subtitle?: string;
  user: IPerson;
  update: (id: string, personData: Partial<IPerson>) => Promise<unknown>;
  loading: boolean;
}

const getDateInstance = (date: string | Date | undefined) => {
  if (typeof date === 'string') {
    return format(new Date(date), 'dd/MM/yyyy');
  } else if (date) {
    format(date, 'dd/MM/yyyy');
  }

  return ';';
};

export const ProfileForm: React.FC<IProps> = ({
  user,
  update,
  loading,
  title,
  subtitle,
}) => {
  const { t } = useTranslation();
  const [initialValues, setInitialValues] = useState<IFormData>();

  const handleSubmit = async (values: IFormData) => {
    try {
      await update(user?.id, {
        ...values,
        id: user?.id,
        birthDate: getDateInstance(values.birthDate),
      });
    } catch (err) {
      return {
        [FORM_ERROR]: t(`profile-form.error.${err?.response?.status}`),
      };
    }
  };

  const { validate } = useValidate();

  useEffect(() => {
    if (user) {
      setInitialValues({
        firstName: user?.firstName,
        lastName: user?.lastName,
        email: user?.email,
        birthDate: user?.birthDate ? new Date(user.birthDate) : new Date(),
      });
    }
  }, [user]);

  return (
    <Form
      onSubmit={handleSubmit}
      validate={validate as any}
      initialValues={initialValues}
      subscription={{
        submitting: true,
        errors: true,
        submitErrors: true,
        submitFailed: true,
        dirtySinceLastSubmit: true,
        submitSucceeded: true,
      }}
      render={({
        handleSubmit,
        submitting,
        errors,
        submitErrors,
        submitFailed,
        dirtySinceLastSubmit,
        submitSucceeded,
      }) => {
        const errorToShow = getErrors(
          errors,
          submitFailed,
          submitErrors,
          dirtySinceLastSubmit,
        );
        return (
          <form onSubmit={handleSubmit}>
            <Card>
              <CardHeader
                title={title ?? t('profile-form.title')}
                icon={<FontAwesomeIcon icon={faUser} />}
                action={
                  <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    size="small"
                    disabled={submitting}
                  >
                    {t('common.save')}
                  </Button>
                }
              />
              <CardContent>
                {subtitle && (
                  <Typography variant="caption" paragraph>
                    {subtitle}
                  </Typography>
                )}
                {loading && (
                  <Box display="flex" justifyContent="center">
                    <CircularProgress />
                  </Box>
                )}
                {!loading && (
                  <Grid container direction="column" spacing={2}>
                    {errorToShow[FORM_ERROR] && (
                      <Grid item>
                        <Alert severity="error">
                          {errorToShow[FORM_ERROR]}
                        </Alert>
                      </Grid>
                    )}
                    {submitSucceeded && (
                      <Grid item>
                        <Alert severity="success">
                          {t('common.form.updateSucceeded')}
                        </Alert>
                      </Grid>
                    )}
                    <Grid item>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="lastName"
                        label={t('profile-form.lastname')}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        variant="outlined"
                        size="small"
                        name="firstName"
                        label={t('profile-form.firstname')}
                        required
                      />
                    </Grid>
                    <Grid item>
                      <BirthdateDatePicker name="birthDate" />
                    </Grid>
                    <Grid item>
                      <TextField
                        name="email"
                        type="email"
                        variant="outlined"
                        size="small"
                        label={t('profile-form.email')}
                        required
                      />
                    </Grid>
                  </Grid>
                )}
              </CardContent>
            </Card>
          </form>
        );
      }}
    />
  );
};
