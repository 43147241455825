import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  title: {
    // textTransform: 'uppercase',
    // fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.aux.spacing.s,
  },
  lastName: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
    fontSize: theme.typography.small.fontSize,
  },
  itemContainer: {
    paddingTop: theme.aux.spacing.xs,
    paddingBottom: theme.aux.spacing.xs,
  },
  globalItemStyle: {
    fontSize: theme.typography.small.fontSize,
    fontWeight: theme.typography.fontWeightBold,
  },
  avatar: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  mobileItemLabel: {
    paddingRight: theme.aux.spacing.xs,
  },
  fullWidth: {
    width: '100%',
  },
}));
