import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  container: {
    minHeight: '100vh',
    backgroundColor: '#fff',
  },
  body: {
    height: '100%',
    justifyContent: 'center',
    textAlign: 'center',
    justifyItems: 'center',
  },
  logo: {
    paddingTop: '3rem',
    marginBottom: '3rem',
  },
  title: {
    letterSpacing: 2,
  },
  formGroup: {
    paddingBottom: theme.spacing(2),
  },
  input: {
    letterSpacing: 1.1,
    borderRadius: 4,
    opacity: 1,
    width: '100%',
  },
  inputLogin: {
    letterSpacing: 1.1,
    borderRadius: 4,
    opacity: 1,
  },
  inputText: {},
  btn: {
    color: theme.palette.common.white,
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: 1.2,
    width: '100%',
  },
  connectionBtn: {
    backgroundColor: theme.palette.primary.main,
  },
  forgotPasswordContainer: {
    alignSelf: 'center',
  },
  forgotPassword: {
    textTransform: 'uppercase',
    borderColor: theme.palette.primary.main,
    borderBottomStyle: 'solid',
    borderWidth: '0 0 2px',
    fontSize: 12,
  },
}));
