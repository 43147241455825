import { CircularProgress, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import debounce from 'lodash.debounce';
import React, { useCallback, useEffect } from 'react';
import { Field, FieldProps } from 'react-final-form';
import { useTranslation } from 'react-i18next';
import { IOrganization, Organization } from 'sherl-react-sdk';

const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const ProAutocomplete = (props: FieldProps<IOrganization, any>) => {
  const { value } = props;
  const classes = useStyles();
  const {
    getSearchOrganizations,
    organizationsLoader,
    organizations,
  } = Organization.useOrganizations();

  const searchProfessional = useCallback(
    debounce((value: string) => {
      getSearchOrganizations(undefined, undefined, {
        q: value,
      });
    }, 300),
    [],
  );

  useEffect(() => {
    searchProfessional('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    searchProfessional(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const { t } = useTranslation();
  return (
    <Field
      {...props}
      render={(fieldProps) => (
        <Autocomplete
          {...fieldProps}
          options={organizations as IOrganization[]}
          classes={{
            option: classes.option,
          }}
          fullWidth
          getOptionSelected={(option: IOrganization, value: IOrganization) =>
            option?.id === value?.id
          }
          loading={organizationsLoader}
          loadingText={
            <Typography>{t('user-autocomplete.loading')}</Typography>
          }
          noOptionsText={
            <Typography>{t('user-autocomplete.no-options')}</Typography>
          }
          onInputChange={(event, newInputValue) => {
            searchProfessional(newInputValue);
          }}
          onChange={(event, newValue) => {
            fieldProps.input.onChange(newValue);
          }}
          getOptionLabel={(option: IOrganization) => option.legalName}
          renderOption={(option: IOrganization) => (
            <React.Fragment>{option.legalName}</React.Fragment>
          )}
          filterOptions={(x) => x}
          renderInput={(params) => (
            <TextField
              {...params}
              label={t('common.form.company')}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {organizationsLoader ? (
                      <CircularProgress color="inherit" size={20} />
                    ) : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      )}
    />
  );
};
