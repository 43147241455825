import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';
import { CardHeader } from 'common/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { CMS } from 'sherl-react-sdk';

interface IProps {
  selectedPage: string;
  handleClick: (
    _event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    id: string,
  ) => void;
}

export const CmsPageList: React.FC<IProps> = ({
  selectedPage,
  handleClick,
}) => {
  const { t } = useTranslation();
  const { getPosts, postsByType, loading } = CMS.usePosts();

  useEffect(() => {
    getPosts({ type: 'page', itemsPerPage: 1000 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pages = postsByType('page');

  return (
    <Card>
      <CardHeader title={t('cms-page-list.title')} />
      <CardContent>
        {loading && (
          <Box display="flex" justifyContent="center">
            <CircularProgress color="primary" />
          </Box>
        )}
        {!loading && (
          <List component="nav" aria-label="main mailbox folders">
            {pages &&
              pages.map((page) => (
                <ListItem
                  key={page.id}
                  button
                  selected={page.id === selectedPage}
                  onClick={(event) => handleClick(event, page.id)}
                >
                  <ListItemText>{page.title}</ListItemText>
                </ListItem>
              ))}
          </List>
        )}
      </CardContent>
    </Card>
  );
};
