import { Grid } from '@material-ui/core';
import { ProductForm } from 'common/components';
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Shop } from 'sherl-react-sdk';

export const ProductDetails: React.FC = () => {
  const { t } = useTranslation();
  const { id } = useParams<any>();
  const {
    getProduct,
    productLoading,
    product,
    updateProduct,
    addProduct,
  } = Shop.useProducts();

  useEffect(() => {
    if (id) {
      getProduct(id);
    }
  }, [id]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <ProductForm
          title={t('product-details.product-form.title')}
          subtitle={t('product-details.product-form.subtitle')}
          product={id && product}
          update={updateProduct}
          create={addProduct}
          loading={productLoading}
        />
      </Grid>
    </Grid>
  );
};
