import React, { useEffect, useState } from 'react';
import { Order } from 'sherl-react-sdk';
import { OrdersListComponent } from '../components/order.list.component';

const ITEM_PER_PAGE = 15;

export const OrdersContainer: React.FC = () => {
  const [page, setPage] = useState(1);

  const {
    getOrders,
    ordersSortedSelector,
    ordersLoading,
    total,
  } = Order.useOrders();

  useEffect(() => {
    getOrders(page, ITEM_PER_PAGE, { field: 'createdAt', order: 'desc' });
    // eslint-disable-next-line
  }, [page]);

  const getNextPage = () => {
    setPage(page + 1);
  };

  return (
    <OrdersListComponent
      getNextPage={getNextPage}
      ordersList={ordersSortedSelector('createdAt', 'desc')}
      loading={ordersLoading}
      total={total}
    />
  );
};
