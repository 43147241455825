import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { Alert } from '@material-ui/lab';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BugReport, IBugReport, IPayloadError } from 'sherl-react-sdk';
import { useStyles } from '../styles/reports.style';
import { ReportDetailsDialog } from './report.details.component';
import { ReportsItem } from './reports.item.component';

interface IProps {
  reportsList: { [key: string]: IBugReport };
  getNextPage: () => void;
  isLoading: boolean;
  error: IPayloadError;
  total: number;
  page: number;
}

export const ReportsComponent: React.FC<IProps> = ({
  reportsList,
  getNextPage,
  isLoading,
  error,
  total,
  page,
}) => {
  const { t } = useTranslation();
  const styles = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [isDetailDialogOpen, setIsDetailDialogOpen] = useState(false);
  const [reportForDialog, setReportForDialog] = useState<any>(undefined);

  const handleReportDetailsDialog = (report: any) => {
    if (report && report.createdAt) {
      setReportForDialog(report);
    }
    setIsDetailDialogOpen(!isDetailDialogOpen);
  };

  return (
    <>
      <Card>
        <CardContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Grid container justify="space-between">
                <Grid item xs={12} md={10}>
                  <Grid container alignItems="flex-start">
                    <Grid item xs={12} md>
                      <Box display="flex">
                        <ReportProblemIcon color="primary" />
                        <Typography className={styles.title}>
                          {t('report-list.reports')}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            {!isMobile && (
              <Grid item xs={12}>
                <Grid container className={styles.header}>
                  <Grid item md={2}>
                    {t('report-list.system')}
                  </Grid>
                  <Grid item md={2}>
                    {t('report-list.browser')}
                  </Grid>
                  <Grid item md={1}>
                    {t('report-list.screen-resolution')}
                  </Grid>
                  <Grid item md={2}>
                    {t('report-list.date')}
                  </Grid>
                  <Grid item md={1}>
                    {t('report-list.hour')}
                  </Grid>
                  <Grid item md={3}>
                    {t('report-list.contact')}
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              {Object.values(reportsList) &&
                Object.values(reportsList).length > 0 &&
                Object.values(reportsList).map((item) => (
                  <React.Fragment key={item.id}>
                    <ReportsItem
                      report={item}
                      isMobile={isMobile}
                      handleReportDetailsDialog={handleReportDetailsDialog}
                    />
                    {isMobile && <Divider light className={styles.fullWidth} />}
                  </React.Fragment>
                ))}
            </Grid>
            {!isLoading && Object.values(reportsList).length !== total && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Button
                    size="small"
                    fullWidth={isMobile}
                    color="primary"
                    variant="outlined"
                    onClick={getNextPage}
                  >
                    {t('orders-list.load-more')}
                  </Button>
                </Box>
              </Grid>
            )}

            {!Object.values(reportsList) ||
              (Object.values(reportsList).length === 0 &&
                !isLoading &&
                !error && (
                  <Grid item xs={12}>
                    <Box display="flex" justifyContent="center">
                      <Alert severity="info">{t('common.no-data-found')}</Alert>
                    </Box>
                  </Grid>
                ))}
            {isLoading && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              </Grid>
            )}
            {error && (
              <Grid item xs={12}>
                <Box display="flex" justifyContent="center">
                  <Alert severity="error">{error.message}</Alert>
                </Box>
              </Grid>
            )}
          </Grid>
        </CardContent>
      </Card>
      {reportForDialog && (
        <ReportDetailsDialog
          open={isDetailDialogOpen}
          report={reportForDialog}
          toggleDialog={() => handleReportDetailsDialog(reportForDialog)}
        />
      )}
    </>
  );
};
