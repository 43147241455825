import { makeStyles } from '@material-ui/core/styles';

const useStyle = makeStyles((theme) => ({
  container: {
    padding: '1rem',
    backgroundColor: 'none',
  },
  title: {
    textTransform: 'uppercase',
    fontWeight: theme.typography.fontWeightBold,
  },
  screenTitle: {
    paddingLeft: '2rem',
  },
  tableContainer: {
    paddingLeft: theme.aux.spacing.s,
  },
  tableTitle: {
    width: '100%',
    textTransform: 'uppercase',
    fontWeight: 500,
    paddingTop: '1.5rem',
    marginBottom: '1rem',
    marginLeft: '1.5rem',
  },
  formInput: {
    '& > *': {
      margin: theme.spacing(1),
      width: 200,
      marginLeft: '2.5rem',
      marginBottom: '1rem',
    },
    '& #search-establishment-input': {
      backgroundColor: theme.palette.grey[400],
    },
  },
  textSearchInput: {},
  rowsContainer: {
    paddingLeft: '1.5rem',
    paddingBottom: '2rem',
  },
  tableHeader: {
    '& .MuiTableCell-root': {
      borderBottom: 'none',
      paddingBottom: '1rem',
    },
  },
  listHeaderTitles: {
    fontWeight: theme.typography.fontWeightBold,
  },
  header: {
    fontWeight: theme.typography.fontWeightBold,
    paddingBottom: theme.aux.spacing.s,
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
}));

export default useStyle;
