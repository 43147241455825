import { makeStyles, Theme, ThemeOptions } from '@material-ui/core';
import { useTheme } from '@material-ui/styles';
import { IMenuTheme } from '../entities/menu.entity';

export const useStyleTheme = (): any => {
  const theme = useTheme() as ThemeOptions & IMenuTheme;
  const useThemeStyle = makeStyles(theme.menu);
  return useThemeStyle();
};

export const useStyles = makeStyles((theme: Theme) => ({
  subCategoryItem: ({ length }: any) => {
    if (length >= 3) {
      return { width: '33.33%' };
    } else if (length === 2) {
      return { width: '50%' };
    } else {
      return { width: '100%' };
    }
  },
}));
