import React, { useEffect, useState } from 'react';
import { BugReport } from 'sherl-react-sdk';
import { ReportsComponent } from '../components/reports.component';

const ITEM_PER_PAGE = 10;

export const ReportsList: React.FC = () => {
  const [page, setPage] = useState(1);
  const {
    bugReports,
    getBugReports,
    total,
    error,
    loading,
  } = BugReport.useBugReport();

  useEffect(() => {
    getBugReports(page, ITEM_PER_PAGE);
    // eslint-disable-next-line
  }, [page]);

  const getNextPage = () => {
    setPage(page + 1);
  };

  return (
    <ReportsComponent
      getNextPage={getNextPage}
      reportsList={bugReports}
      isLoading={loading['list']}
      error={error['list']}
      total={total}
      page={page}
    />
  );
};
