import { useEnvironment } from 'common/components';
import React, { useEffect, useRef, useState } from 'react';
import { MyCustomUploadAdapterPlugin } from './upload-plugin.helper';

interface IProps {
  onChange?: (value: string) => void;
  value?: string;
}

export const EditorComponent: React.FC<IProps> = ({ onChange, value }) => {
  const { api } = useEnvironment();
  const editorRef = useRef<any>();
  const [editorLoaded, setEditorLoaded] = useState(true);
  const { CKEditor, ClassicEditor } = editorRef.current || {};

  useEffect(() => {
    editorRef.current = {
      CKEditor: require('@ckeditor/ckeditor5-react'),
      ClassicEditor: require('@ckeditor/ckeditor5-build-classic'),
      CKEditorLand: require('@ckeditor/ckeditor5-build-classic/build/translations/fr'),
    };
    setEditorLoaded(true);
  }, []);
  return (
    <>
      {editorLoaded && (
        <CKEditor
          editor={ClassicEditor}
          data={value}
          config={{
            extraPlugins: [MyCustomUploadAdapterPlugin],
            language: 'fr',
            heading: {
              options: [
                {
                  model: 'paragraph',
                  title: 'Paragraphe',
                  class: 'ck-heading_paragraph',
                },
                {
                  model: 'heading1',
                  view: 'h2',
                  title: 'Titre 1',
                  class: 'ck-heading_heading1',
                },
                {
                  model: 'heading2',
                  view: 'h3',
                  title: 'Titre 2',
                  class: 'ck-heading_heading2',
                },
                {
                  model: 'heading3',
                  view: 'h4',
                  title: 'Titre 3',
                  class: 'ck-heading_heading3',
                },
              ],
            },
            simpleUpload: {
              // The URL that the images are uploaded to.
              uploadUrl: api?.baseURL,

              // Enable the XMLHttpRequest.withCredentials property.
              withCredentials: true,

              // Headers sent along with the XMLHttpRequest to the upload server.
              headers: {
                'X-WZ-API-KEY': api?.apiKey,
                'X-WZ-API-SECRET': api?.apiSecret,
                Authorization: localStorage.getItem('token'),
              },
            },
          }}
          onChange={(_event, editor) => {
            const data = editor.getData();
            if (onChange) {
              onChange(data);
            }
          }}
        />
      )}
    </>
  );
};
