/* eslint-disable no-undef */
import {
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

/**
 * How to use :
 * ```
  const { loadingMore } = useInfiniteScroll(async () => {
    await getData();
  });
 * ```
 * Each time the bottom list is reached, the callback will be triggered
 *
 * @param promise Promise to execute
 * @return boolean loadingMore - indicate whether the promise is pending
 */
export const useInfiniteScroll = (
  promise: () => Promise<any>,
  domElement?: MutableRefObject<HTMLDivElement>,
) => {
  let el: Window | HTMLElement = window;
  if (domElement?.current) {
    el = domElement.current;
  }
  // Use two distinct variable to prevent from loading hiding re-triggering scroll event
  const loadingMore = useRef({ value: false });
  const previousScrollY = useRef({ value: 0 });
  const [showLoader, setShowLoader] = useState(false);

  const onEndReached = useCallback(
    async (e) => {
      if (el instanceof Window) {
        console.log({
          'previousScrollY.current.value === window.scrollY':
            previousScrollY.current.value === window.scrollY,
          'window.innerHeight + document.documentElement.scrollTop < document.documentElement.offsetHeight':
            window.innerHeight + document.documentElement.scrollTop <
            document.documentElement.offsetHeight,
        });
        if (
          previousScrollY.current.value === window.scrollY ||
          window.innerHeight + document.documentElement.scrollTop <
            document.documentElement.offsetHeight ||
          loadingMore.current.value
        ) {
          return;
        }
      } else if (
        el.scrollTop + el.clientHeight < el.scrollHeight ||
        loadingMore.current.value
      ) {
        return;
      }

      previousScrollY.current.value = window.scrollY;
      loadingMore.current.value = true;
      setShowLoader(() => true);
      try {
        await promise();
        setShowLoader(() => false);
        setTimeout(() => {
          loadingMore.current.value = false;
        }, 100);
      } catch (err) {
        setShowLoader(() => false);
        setTimeout(() => {
          loadingMore.current.value = false;
        }, 100);
      }
    },
    [loadingMore, promise, el],
  );

  useEffect(() => {
    el.addEventListener('scroll', onEndReached);
    return () => el.removeEventListener('scroll', onEndReached);
  }, [loadingMore, onEndReached, el]);

  return { onEndReached, loadingMore: showLoader };
};
